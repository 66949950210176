import moment from 'moment';



export const events = [
    {
        title: 'Planning how to relocate the server to the new location and how to protect files',
        start: moment().subtract(1, "hours").toISOString(),
        end: moment().add(1, "hours").toISOString(),        
        description: 'Lecture'
    },
    {
        title: 'Group call to discuss about the progress ofUX',
        start: moment().subtract(2, "days").toISOString(),
        end: moment().subtract(5, "days").toISOString(),       
        description: 'Lecture'

    }
]