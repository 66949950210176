import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import Select from "react-select"
import axios_config from "../../helpers/axios_config"
import { Editor } from "react-draft-wysiwyg"
import Loader from "react-loader-spinner";


import {
  Card,
  Table,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Nav,
  Button,
  NavItem,
  NavLink,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Progress,
  TabContent,
  TabPane,
  FormGroup,

} from "reactstrap"

import { Link, withRouter, Redirect } from "react-router-dom"

import classnames from "classnames"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

const option_project_priority_group = [
  {
    options: [
      { label: "Low", value: 0 },
      { label: "Medium", value: 1 },
      { label: "High", value: 2 }
    ]
  },

]

const option_task_type = [
  {
    options: [
      { label: "Parent", value: 0 },
      { label: "Subtask", value: 1 },

    ]
  },

]
class Projectlist extends Component {
  constructor(props) {
    let create_task = false
    let create_project = false
    let new_create_project = false
    super(props)
    this.state = {
      all_team_projects: '',
      activeTab: "1",
      activeTab1: "5",
      activeTab2: "9",
      activeTab3: "13",
      verticalActiveTab: "1",
      verticalActiveTabWithIcon: "1",
      customActiveTab: "1",
      customIconActiveTab: "1",
      activeTabJustify: "5",
      col1: true,
      col2: false,
      col3: false,
      col11: true,
      col22: false,
      col33: false,

      // Create Task
      task_milestone_name: "",
      task_name: "",
      task_type: "",
      task_parent: "",
      task_desc: "",
      task_priority: "",
      task_members: [],
      task_duedate: "",
      task_startDate: "",
      selectedFiles: '',
      projects: [],
      image: null,
      task_progress: '',
      create_task,

      //Create Project
      modal_standard_project: false,
      single_team: '',
      project_team_id: '',
      project_members_add: [],
      project_name: '',
      project_desc: '',
      project_task_priority: '',
      project_startDate: '',
      project_dueDate: '',
      project_team_name: "",
      project_team_owner: "",
      status: '',
      project_progress: '',
      create_project,

      //Create New Project
      new_modal_standard: false,
      new_project_members_add: [],
      new_project_name: '',
      new_project_desc: '',
      new_project_task_priority: '',
      new_project_startDate: '',
      new_project_dueDate: '',
      new_project_team_name: "",
      new_project_team_owner: "",
      new_status: '',
      selectTeamOwnerOptions: '',
      selectTeamNameOptions: '',
      new_project_progress: '',
      new_create_project,

      selectProjectNameOptions: [],
    }
    this.toggle = this.toggle.bind(this)
    this.t_col1 = this.t_col1.bind(this)
    this.t_col2 = this.t_col2.bind(this)
    this.t_col3 = this.t_col3.bind(this)
    this.t_col11 = this.t_col11.bind(this)
    this.t_col22 = this.t_col22.bind(this)
    this.t_col33 = this.t_col33.bind(this)

    //Task
    this.task_tog_task = this.task_tog_task.bind(this)
    this.handleTaskMilestoneNameChange = this.handleTaskMilestoneNameChange.bind(this)
    this.handleTaskMemberChange = this.handleTaskMemberChange.bind(this);
    this.onFileChange = this.onFileChange.bind(this)
    this.handleTeamDescChange = this.handleTeamDescChange.bind(this);
    this.handleTaskStartDateChange = this.handleTaskStartDateChange.bind(this);
    this.handleTaskDueDateChange = this.handleTaskDueDateChange.bind(this);
    this.handleTaskSelectGroup = this.handleTaskSelectGroup.bind(this)
    this.handleTaskNameChange = this.handleTaskNameChange.bind(this)
    this.taskFormSubmit = this.taskFormSubmit.bind(this)
    this.handleTaskTypeSelectGroup = this.handleTaskTypeSelectGroup.bind(this)

    //Project
    this.project_modal_standard = this.project_modal_standard.bind(this)
    this.projectFormSubmit = this.projectFormSubmit.bind(this)
    this.handleProjectPriorityGroup = this.handleProjectPriorityGroup.bind(this)
    this.handleTeamNameChange = this.handleTeamNameChange.bind(this);
    this.handleTeamOwnerChange = this.handleTeamOwnerChange.bind(this);
    this.handleMemberChange = this.handleMemberChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleProjectDescChange = this.handleProjectDescChange.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleDueDateChange = this.handleDueDateChange.bind(this);

    //Create New Project Handling

    this.new_project_standard = this.new_project_standard.bind(this)
    this.newProjectFormSubmit = this.newProjectFormSubmit.bind(this)
    this.handleNewProjectPriorityGroup = this.handleNewProjectPriorityGroup.bind(this)
    this.handleNewTeamNameChange = this.handleNewTeamNameChange.bind(this);
    this.handleNewTeamOwnerChange = this.handleNewTeamOwnerChange.bind(this);
    this.handleNewMemberChange = this.handleNewMemberChange.bind(this);
    this.handleNewNameChange = this.handleNewNameChange.bind(this);
    this.handleNewProjectDescChange = this.handleNewProjectDescChange.bind(this);
    this.handleNewStartDateChange = this.handleNewStartDateChange.bind(this);
    this.handleNewDueDateChange = this.handleNewDueDateChange.bind(this)

  }

  //Start Delete Project
  deleteProject(project_id){

    var proj_id = [project_id]
     if(confirm("Are You Sure, you want to delete this project")){
      
      axios_config({
        method: "delete",
        url: "/projects-delete/",
        data: { projectsid: proj_id },
      }) .then(response => {
        console.log('res',response)
        if (response.data.success == "true") {
          console.log("Delete Project Successfully");
          alert("Delete Project Successfully")
          this.allTeamProjects();
          this.allGetProjects();
        }else{
          let msg = response.data.msg
          console.log(msg)
          alert(msg)
        }
      }).catch(error => {
        console.log("Delete Project error", error);
      });
     }

  }
  //End Delete Project

  t_col1() {
    this.setState({ col1: !this.state.col1 })
  }

  t_col2() {
    this.setState({ col2: !this.state.col2 })
  }

  t_col3() {
    this.setState({ col3: !this.state.col3 })
  }

  t_col11() {
    this.setState({ col11: !this.state.col11 })
  }

  t_col22() {
    this.setState({ col22: !this.state.col22 })
  }

  t_col33() {
    this.setState({ col33: !this.state.col33 })
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }


  //Create Task Handling
  removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  task_tog_task() {
    this.setState(prevState => ({
      task_modal_task: !prevState.task_modal_task,
    }))
    this.removeBodyCss()
  }


  onFileChange = event => {

    this.setState({ selectedFiles: event.target.files[0] });

  };

  handleTeamDescChange = (event) => {
    this.setState({ task_desc: event.blocks[0].text })

  };

  handleTaskStartDateChange = (event) => {
    this.setState({ task_startDate: event[0] })

  };

  handleTaskDueDateChange = (event) => {
    this.setState({ task_duedate: event[0] })

  };

  handleTaskSelectGroup = priority_sel_event => {
    this.setState({ task_priority: priority_sel_event.value })
  }
  handleTaskNameChange(event) {

    this.setState({ [event.target.name]: event.target.value })
  }

  handleTaskTypeSelectGroup = task_type_event => {
    this.setState({ task_type: task_type_event.value })
  }

  async getMilestoneOptions() {
    const res = await axios_config.post('/all-milestone/')
    const data = res.data.data

    const options = data ? data.map(item => ({
      "value": item["milestone id"],
      "label": item["name"]
    })) : "";
    this.setState({ selectTeamMilestoneOptions: options })
  }

  handleTaskMilestoneNameChange(e) {
    this.setState({ task_milestone_name: e.value })

  }

  async getTaskMembersOptions() {

    const response = await axios_config.post('/all-users/', { usertype: 3 })
    const data = response.data.data
    const options = data.map(d => ({
      "value": d["user id"],
      "label": d["user firstname"]
    }))
    this.setState({ selectTaskMemberNameOptions: options })
  }

  handleTaskMemberChange(event) {
    this.setState({ task_members: event })

  }

  taskFormSubmit(event) {

    this.setState({
      task_milestone_name: "",
      task_name: "",
      task_type: "",
      task_parent: "",
      task_desc: "",
      task_priority: "",
      task_members: [],
      task_duedate: "",
      task_startDate: "",
      selectedFiles: '',
      projects: [],
      image: null,
      loading: true,
      task_modal_task: false

    })
    event.preventDefault();


    const { task_milestone_name, task_name, task_type, task_parent, task_progress, task_desc, task_priority, task_members, task_startDate,
      task_duedate, selectedFiles } = this.state;

    function convert(str) {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day,].join("-");
    }

    let start_date = convert(task_startDate)
    let due_date = convert(task_duedate)
    let update_metakey = selectedFiles.name
    let metavalue = selectedFiles.type
    var arr_members_value = [];
    this.state.task_members.map((postData) => {
      arr_members_value.push(postData.value)
    })

    console.log(task_milestone_name)
    console.log(task_name)
    console.log(task_type)
    console.log(task_progress)
    console.log(task_parent)
    console.log(task_desc)
    console.log(task_priority)
    console.log(arr_members_value)
    console.log(start_date)
    console.log(due_date)
    console.log(update_metakey)
    console.log(metavalue)
    console.log(selectedFiles)

    let bodyFormData = new FormData();

    bodyFormData.append("Milestoneid", task_milestone_name);
    bodyFormData.append("task", task_name);
    bodyFormData.append("tasktype", 0);
    bodyFormData.append("taskparent", 0);
    bodyFormData.append("taskdescription", task_desc);
    bodyFormData.append("progress", 0);
    bodyFormData.append("priority", task_priority);
    bodyFormData.append("membersid", arr_members_value);
    bodyFormData.append("metakey", null);
    bodyFormData.append("metavalue", null);
    bodyFormData.append("start_date", start_date);
    bodyFormData.append("due_date", due_date);
    bodyFormData.append("status", 2);


    axios_config({
      method: "post",
      url: "/task/",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then(response => {
      console.log("resp create task", response);
      if (response.data.success == "true") {
        console.log("Add Task Successfully");
        alert("Added Task Successfully")
        this.allTeamProjects()
        this.setState({
          create_task: true,
          task_modal_task: false,
          loading: false

        })

      } else {
        this.allTeamProjects()
        let msg = response.data.msg
        this.setState({
          create_task: true,
          task_modal_task: false,
          loading: false

        })
        console.log(msg)
        alert(msg)
      }
    })
      .catch(error => {
        console.log("login error", error);
      });
  }


  //End Create Task Handling


  //Create Project Handling
  project_modal_standard() {
    this.setState(prevState => ({
      modal_standard_project: !prevState.modal_standard_project,
    }))
    this.removeBodyCss()
  }

  handleProjectDescChange = (event) => {
    this.setState({ project_desc: event.blocks[0].text })

  };

  handleStartDateChange = (event) => {
    this.setState({ project_startDate: event[0] })

  };

  handleDueDateChange = (event) => {
    this.setState({ project_dueDate: event[0] })

  };

  handleProjectPriorityGroup = priority_sel_event => {
    this.setState({ project_task_priority: priority_sel_event.value })
  }

  handleNameChange(event) {

    this.setState({ [event.target.name]: event.target.value })

  }

  async getMemberOptions() {

    const response = await axios_config.post('/all-users/', { usertype: 3 })
    const data = response.data.data
    const options = data.map(item => ({
      "value": item["user id"],
      "label": item["user firstname"]
    }))
    this.setState({ selectOptions: options })
  }
  handleMemberChange(e) {
    this.setState({ project_members_add: e })

  }

  async getTeamOwnerOptions() {
    const response = await axios_config.post('/all-users/', { usertype: 3 })
    const data = response.data.data
    const options = data.map(d => ({
      "value": d["user id"],
      "label": d["user firstname"]
    }))
    this.setState({ selectTeamOwnerOptions: options })
  }
  handleTeamOwnerChange(e) {
    this.setState({ project_team_owner: e.value })

  }


  async getTeamNameOptions() {
    const res = await axios_config.post('/all-teams/')
    const data = res.data.data

    const options = data.map(item => ({
      "value": item["Team id"],
      "label": item["Team Name"]
    }))
    this.setState({ selectTeamNameOptions: options })
  }

  handleTeamNameChange(e) {
    this.setState({ project_team_name: e.value })

  }

  projectFormSubmit(event, id) {
    event.preventDefault();
    this.setState({
      single_team: '',
      project_team_id: '',
      project_members_add: [],
      project_name: '',
      project_desc: '',
      project_task_priority: '',
      project_startDate: '',
      project_dueDate: '',
      project_team_name: "",
      project_team_owner: "",
      status: '',
      project_progress: '',
      modal_standard_project: false,
      loading: true,
    });
    let para_id = this.props.match.params.id
    console.log('submit project form')
    const { project_name, project_team_id, project_desc, project_startDate, project_dueDate, project_team_name, project_team_owner, project_members_add, project_task_priority, project_progress, status } = this.state;

    function convert(str) {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
    }
    let statuss = 1
    console.log(project_name)
    console.log(project_desc)
    let start_date = convert(project_startDate)
    let due_date = convert(project_dueDate)
    console.log(due_date)
    console.log(start_date)
    console.log(project_team_name)
    console.log(project_team_id)
    console.log(project_team_owner)
    console.log(project_members_add)
    console.log(project_task_priority)
    console.log(project_progress)
    console.log(1)

    var arr_members_value = [];
    this.state.project_members_add.map((postData) => {
      arr_members_value.push(postData.value)
    })


    axios_config.post("/project/", {

      ProjectName: project_name,
      Projectdescription: project_desc,
      startdate: start_date,
      duedate: due_date,
      Teamid: project_team_id,
      Teamowner: project_team_owner,
      MembersId: arr_members_value,
      priority: project_task_priority,
      progress: 0,
      status: statuss

    }
    ).then(response => {
      console.log("resp create project", response);
      if (response.data.success == "true") {
        console.log("Add project Successfully");
        alert("Add Project Successfully")
        this.allTeamProjects()
        this.allGetProjects();
        this.setState({
          loading: false,
          create_project: true

        })

      } else {
        let msg = response.data.msg
        alert(msg)
        this.allTeamProjects()
        this.allGetProjects();
        this.setState({
          loading: false,
          create_project: true

        })

      }
    })
      .catch(error => {
        console.log("login error", error);
      });

  }
  async getProjectOptions(id) {

    let team_id = id
    this.setState({ project_team_id: team_id })
    const response = await axios_config.post('/single-team/', { teamid: team_id })
    const data = response.data?.data != null ? response.data?.data['Team Name'] : '';
    this.setState({ single_team: data })

  }
  //End Create Project Handling
  // Add New Project Handling

  //End New Project Handling
  new_project_standard() {
    this.setState(prevState => ({
      new_modal_standard: !prevState.new_modal_standard,
    }))
    this.removeBodyCss()
  }

  handleNewProjectDescChange = (event) => {
    this.setState({ new_project_desc: event.blocks[0].text })

  };

  handleNewStartDateChange = (event) => {
    this.setState({ new_project_startDate: event[0] })

  };

  handleNewDueDateChange = (event) => {
    this.setState({ new_project_dueDate: event[0] })

  };

  handleNewProjectPriorityGroup = priority_sel_event => {
    this.setState({ new_project_task_priority: priority_sel_event.value })
  }

  handleNewNameChange(event) {

    this.setState({ [event.target.name]: event.target.value })

  }

  async getMemberOptions() {

    const response = await axios_config.post('/all-users/', { usertype: 3 })
    const data = response.data.data
    const options = data.map(item => ({
      "value": item["user id"],
      "label": item["user firstname"]
    }))
    this.setState({ selectOptions: options })
  }
  handleNewMemberChange(e) {
    this.setState({ new_project_members_add: e })

  }

  async getTeamOwnerOptions() {
    const response = await axios_config.post('/all-users/', { usertype: 3 })
    const data = response.data.data
    const options = data.map(d => ({
      "value": d["user id"],
      "label": d["user firstname"]
    }))
    this.setState({ selectTeamOwnerOptions: options })
  }
  handleNewTeamOwnerChange(e) {
    this.setState({ new_project_team_owner: e.value })

  }


  async getTeamNameOptions() {
    const res = await axios_config.post('/all-teams/')
    const data = res.data.data

    const options = data.map(item => ({
      "value": item["Team id"],
      "label": item["Team Name"]
    }))
    this.setState({ selectTeamNameOptions: options })
  }

  handleNewTeamNameChange(e) {
    this.setState({ new_project_team_name: e.value })

  }

  newProjectFormSubmit(event) {
    event.preventDefault();
    this.setState({

      new_modal_standard: false,
      loading: true
    })
    let para_id = this.props.match.params.id
    console.log('submit project form')
    const { new_project_name, new_project_desc, new_project_startDate, new_project_dueDate, new_project_team_name, new_project_team_owner, new_project_members_add, new_project_task_priority, new_project_progress, new_status } = this.state;

    function convert(str) {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
    }
    let statuss = 1
    console.log(new_project_name)
    console.log(new_project_team_name)
    console.log(new_project_desc)
    let start_date = convert(new_project_startDate)
    let due_date = convert(new_project_dueDate)
    console.log(start_date)
    console.log(due_date)
    console.log(new_project_team_owner)
    console.log(new_project_members_add)
    console.log(new_project_task_priority)
    console.log(new_project_progress)
    console.log(1)


    var arr_members_value = [];
    this.state.new_project_members_add.map((postData) => {
      arr_members_value.push(postData.value)
    })

    axios_config.post("/project/", {

      ProjectName: new_project_name,
      Projectdescription: new_project_desc,
      startdate: start_date,
      duedate: due_date,
      Teamid: new_project_team_name,
      Teamowner: new_project_team_owner,
      MembersId: arr_members_value,
      priority: new_project_task_priority,
      progress: 0,
      status: statuss

    }
    ).then(response => {
      console.log("resp create project", response);
      if (response.data.success == "true") {
        this.allTeamProjects()
        this.allGetProjects()
        console.log("Add project Successfully");
        alert("Add Project Successfully")
        this.setState({
          new_modal_standard: false,
          loading: false,
          new_create_project: true

        })


      } else {
        let msg = response.data.msg
        console.log(msg);
        alert(msg)
        this.allTeamProjects()
        this.allGetProjects()
        this.setState({
          new_modal_standard: false,
          loading: false,
          new_create_project: true

        })

      }
    })
      .catch(error => {
        console.log("login error", error);
      });

  }
  //Get All Team Projects
  async allTeamProjects() {

    const res = await axios_config.post('/all-team_projects/')

    var get_all_result = res.data.data
    this.setState({ all_team_projects: get_all_result })
    var project_id = this.props.match.params.id



    var url = this.props.location.pathname;
    url = url.toString().split( '/' )[2];
     if(url){

         var result = get_all_result.filter((item)=>{
            
        return project_id == item['Team id']??item
    });
    this.setState({ all_team_projects: result })

    }
    
  }
  async allGetProjects() {

// Get Projects With Team ID
var project_id = this.props.match.params.id
const projectres = await axios_config.post('/all-projects/', { teamid: project_id, })
const project_data = projectres.data.data

const options = project_data ? project_data.map(item => (

  {
    "project_id": item["project id"],
    "project_team_id": item["Team id"],
    "project_name": item["Project Name"],
    "project_desc": item["Project description"],
    "project_startdate": item["duedate"],
    "project_duedate": item["duedate"],
    "project_priority": item["priority"],
    "project_uname": item["Project Members"] ? item["Project Members"].map(function (el) { return el['member username']; }).join(", ") : "",
    "project_members": item["Project Members"],
    "project_status": item["status"],
    "project_progress": item["progress"],
  })) : "";
this.setState({ selectProjectNameOptions: options })
  }


  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.allTeamProjects();
      this.allGetProjects();

    }
  }

  //End All Team Projects

  componentDidMount() {

    //All Team Projects
    this.allTeamProjects()
    this.getMemberOptions()
    this.getTeamOwnerOptions()

    //Task
    this.getMilestoneOptions()
    this.getTaskMembersOptions()

    //Project
    this.getProjectOptions()
    this.getTeamNameOptions()
    this.allGetProjects()

  }


  render() {

    // Team Projects
    var get_team_projects = this.state.all_team_projects

    //Single Team Name
    const single_tname = this.state.single_team;

    if (this.state.loading) {
      return <Loader
        style={{ position: "fixed", top: "50%", left: "60%", transform: "translate(-50%, -50%)" }}
        type="Circles"
        color="#00BFFF"
        height={100}
        width={100}
        timeout={9000} //3 secs
      />
    }

    // if (this.state.create_task || this.state.create_project ||this.state.new_create_project ) {
    //   return <Redirect to="/Projectlist"> </Redirect>
    //   return location.reload
    // }
    return (
      <React.Fragment>
        <div className="page-content">

          <Container fluid={true} className="projectpage">
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <div className="project-head">
                      <h2><span className="prjt-ltr">P</span> Projects</h2>
                    </div>


                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "1",
                          })}
                          onClick={() => {
                            this.toggle("1")
                          }}
                        >
                          <i class="bx bx-location-plus"></i>List
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "2",
                          })}
                          onClick={() => {
                            this.toggle("2")
                          }}
                        >
                          <i class="mdi mdi-apps"></i>Grid
                        </NavLink>
                      </NavItem>

                    </Nav>
                    <div className="new-prjt-btn">
                      <Button
                        color="primary"
                        className="font-16 btn-block btn btn-primary"
                        onClick={this.new_project_standard}
                        data-toggle="modal"
                        data-target="#myModal"
                      > New project <i class="bx bx-plus"></i>
                      </Button>
                    </div>
                    <Modal
                      isOpen={this.state.new_modal_standard}
                      toggle={this.new_project_standard}
                      centered={true}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                          <span class="popup-head"><i class="bx bx-plus"></i> Create a new Project</span>
                        </h5>
                        <button
                          type="submit"
                          className="btn btn-primary right-align"
                          onClick={this.newProjectFormSubmit}
                        >
                          Done

                        </button>
                        <button
                          type="button"
                          onClick={() =>
                            this.setState({ new_modal_standard: false })
                          }
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <Row>
                          <Col lg={12}>
                            <div className="mb-3">
                              <input
                                name="new_project_name"
                                type="text"
                                className="form-control"
                                placeholder="Enter Project Name..."
                                value={this.state.new_project_name}
                                onChange={this.handleNewNameChange}
                              />
                            </div>
                            <br />
                          </Col>
                          <Col lg={12}>
                            <Select
                              options={this.state.selectTeamNameOptions}
                              onChange={this.handleNewTeamNameChange}
                              classNamePrefix="select2-selection"
                              validate={{
                                required: { value: true },

                              }}
                              placeholder='Select Team'
                            />
                          </Col>
                        </Row>
                        <FormGroup row>
                          <Col sm={12}>
                            <Editor
                              placeholder="Schedule description"
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              toolbar={{
                                options: ['inline', 'history', "emoji"],
                                inline: { inDropdown: false },
                                history: { inDropdown: true },
                                emoji: { inDropdown: true }
                              }}
                              name='new_project_desc'
                              value={this.state.new_project_desc}
                              onChange={this.handleNewProjectDescChange}
                            />
                          </Col>
                        </FormGroup>
                        <Row>
                          <Col lg={6}>
                            <div className="input-group date-pick">
                              <Flatpickr
                                name="new_project_startDate"
                                className="form-control d-block"
                                placeholder="Starting date"
                                value={this.state.new_project_startDate}
                                onChange={this.handleNewStartDateChange}
                                options={{
                                  altInput: true,
                                  altFormat: "F j, Y",
                                  dateFormat: "Y-m-d"
                                }}
                              />
                              <span className="date-icon"><i class="bx bx-calendar"></i></span>
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="input-group date-pick">
                              <Flatpickr
                                name="new_project_dueDate"
                                className="form-control d-block"
                                placeholder="Due date"
                                value={this.state.new_project_dueDate}
                                onChange={this.handleNewDueDateChange}
                                options={{
                                  altInput: true,
                                  altFormat: "F j, Y",
                                  dateFormat: "Y-m-d"
                                }}

                              />
                              <span className="date-icon"><i class="bx bx-calendar"></i></span>
                            </div>
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col lg={4}>
                            <Select
                              options={this.state.selectTeamOwnerOptions}
                              onChange={this.handleNewTeamOwnerChange}
                              classNamePrefix="select2-selection"
                              validate={{
                                required: { value: true },
                              }}
                              placeholder='Add Team Owner'
                            />
                          </Col>
                          <Col lg={4}>
                            <Select
                              options={this.state.selectOptions}
                              isMulti
                              onChange={this.handleNewMemberChange}
                              classNamePrefix="select2-selection"
                              validate={{
                                required: { value: true },
                              }}
                              placeholder='Add Members'
                            />
                          </Col>
                          <Col lg={4}>
                            <Select
                              onChange={this.handleNewProjectPriorityGroup}
                              options={option_project_priority_group}
                              classNamePrefix="select2-selection"
                              validate={{
                                required: { value: true },

                              }}
                              placeholder='Select Priority'
                            />
                            <br />
                          </Col>
                          {/* <Col lg={12}>
                                <div className="mb-3">
                                  <input
                                    name="new_project_progress"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Progress Value..."
                                    value={this.state.new_project_progress}
                                    onChange={this.handleNewNameChange}
                                  />
                                </div>
                              </Col> */}
                        </Row>
                      </div>
                      <div className="modal-footer ftr-none">
                      </div>
                    </Modal>
                    <TabContent activeTab={this.state.activeTab} className="p-3 text-muted">
                      <TabPane tabId="1">
                        <Row>
                          <Col lg={12}>

                            <div className="accordion" id="accordion">

                              <div className="accordion-item">
                                {get_team_projects ? get_team_projects.map((item) => (<>
                                  <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button fw-medium" type="button" onClick={this.t_col1} style={{ cursor: "pointer" }}>
                                      Project of Team {item['Team Name']}
                                      <span className="accordion-sub">
                                        <span
                                          onClick={this.task_tog_task}
                                          data-toggle="modal"
                                          data-target="#myModal"
                                        > <i class="bx bx-plus"></i>Task</span>
                                        <span
                                          onClick={() => {
                                            this.project_modal_standard();
                                            this.getProjectOptions(item['Team id']);
                                          }}
                                          data-toggle="modal"
                                          data-target="#myModal"
                                        > <i class="bx bx-plus"></i>Project</span></span>
                                    </button>
                                  </h2>
                                  <Table className="table mb-0 ">
                                    <thead>
                                      <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Asignees</th>
                                        <th>Due Date</th>
                                        <th>Priority</th>
                                        <th>Progress</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    {this.state.selectProjectNameOptions ? this.state.selectProjectNameOptions.map((item, indexx) =>
                                      <tbody>
                                        <tr key={indexx}>
                                          <th scope="row">{indexx + 1}</th>
                                          <td><Link to={"/Projecttask/" + item.project_id}>{item.project_name}</Link></td>
                                          <td><div class="avatar-group">
                                            <div class="avatar-group-item">
                                              <a class="d-inline-block" href="/tasks-list"><div class="avatar-xs"><span class="avatar-title rounded-circle bg-success text-white font-size-16">

                                                {item.project_uname}

                                              </span></div></a>
                                            </div>
                                          </div>
                                          </td>
                                          <td><span class="date-text"><i className="fas fa-calendar-alt"></i> {item.project_duedate}</span></td>
                                          <td><span class={item.project_priority == 0 ? 'Low-text' : (item.project_priority == 1 ? "Medium-text" : "High-text")}><i class={item.project_priority == 0 ? 'dripicons-arrow-thin-down' : (item.project_priority == 1 ? "dripicons-arrow-thin-right" : "dripicons-arrow-thin-up")}></i>{item.project_priority == 0 ? 'Low' : (item.project_priority == 1 ? "Medium" : "High")}</span></td>

                                          <div className="progress-bar">
                                            <Progress color="primary" value={50}>
                                              <span class="percentage-txt">{item.project_progress}%</span>
                                            </Progress>
                                          </div>
                                          <td><span class={item.status == 0 ? "font-size-12 badge-soft-success badge badge-success badge-pill done-color" : (item.status == 1 ? "font-size-12 badge-soft-success badge badge-success badge-pill progress-color" : "font-size-12 badge-soft-success badge badge-success badge-pill todo-color")}>{item.status == 0 ? 'Done' : (item.status == 1 ? 'Progrress' : 'To do')}</span></td>
                                          <div className="d-flex gap-3 options">
                                            <Link className="text-success" to="#"><i className="bx bx-check" id="edittooltip" onClick={() => this.handleUserClick(user)}></i></Link>
                                            <Link className="text-danger" to="#"><i className="bx bx-time-five" id="deletetooltip" onClick={() => this.handleDeleteUser(user)}></i></Link>
                                            <Link className="account-add" to="#"><i className="mdi mdi-account-plus-outline" id="deletetooltip" onClick={() => this.handleDeleteUser(user)}></i></Link>
                                            <Link className="account-delete" to="#"><i className="bx bx-trash" id="deletetooltip" onClick={() => this.deleteProject(item.project_id)}></i></Link>
                                          </div>
                                        </tr>
                                      </tbody>
                                    ) : item['project details'] ? item['project details'].map((data, index) => (

                                      <>

                                        <tbody>

                                          <tr key={index}>
                                            <th scope="row">{index + 1}</th>
                                            <td><Link to={"/Projecttask/" + data['project id']}>{data['Project Name']}</Link></td>
                                            <td>
                                              <div class="avatar-group">
                                                <div class="avatar-group-item">
                                                  <a class="d-inline-block" href="/tasks-list">
                                                    <div class="avatar-xs">
                                                      <span class="avatar-title rounded-circle bg-success text-white font-size-16">
                                                        {data['Project Members'].map((item_member) => (<>{item_member['member username']}</>))}
                                                      </span>
                                                    </div>
                                                  </a>
                                                </div>
                                              </div>
                                            </td>
                                            <td><span class="date-text"><i className="fas fa-calendar-alt"></i> {data['duedate']}</span></td>

                                            <td><span class={data['priority'] == 0 ? 'Low-text' : (data['priority'] == 1 ? "Medium-text" : "High-text")}><i class={data['priority'] == 0 ? 'dripicons-arrow-thin-down' : (data['priority'] == 1 ? "dripicons-arrow-thin-right" : "dripicons-arrow-thin-up")}></i>{data['priority'] == 0 ? 'Low' : (data['priority'] == 1 ? "Medium" : "High")}</span></td>

                                            <div className="progress-bar">
                                              <Progress color="primary" value={50}>
                                                <span class="percentage-txt">{data['progress']}%</span>
                                              </Progress>
                                            </div>

                                            <td><span class={data['status'] == 0 ? "font-size-12 badge-soft-success badge badge-success badge-pill done-color" : (data['status'] == 1 ? "font-size-12 badge-soft-success badge badge-success badge-pill progress-color" : "font-size-12 badge-soft-success badge badge-success badge-pill todo-color")}>{data['status'] == 0 ? 'Done' : (data['status'] == 1 ? 'Progrress' : 'To do')}</span></td>
                                            <div className="d-flex gap-3 options">
                                              <Link className="text-success" to="#"><i className="bx bx-check" id="edittooltip" onClick={() => this.handleUserClick(user)}></i></Link>
                                              <Link className="text-danger" to="#"><i className="bx bx-time-five" id="deletetooltip" onClick={() => this.handleDeleteUser(user)}></i></Link>
                                              <Link className="account-add" to="#"><i className="mdi mdi-account-plus-outline" id="deletetooltip" onClick={() => this.handleDeleteUser(user)}></i></Link>
                                              <Link className="account-delete" to="#"><i className="bx bx-trash" id="deletetooltip" onClick={() => this.deleteProject(data['project id'])}></i></Link>
                                            </div>
                                          </tr>
                                        </tbody>


                                      </>)) : 'null'
                                    }


                                  </Table>
                                </>)) : 'No Record Found'}
                              </div>
                              <Modal
                                isOpen={this.state.modal_standard_project}
                                toggle={this.project_modal_standard}
                                centered={true}
                              >
                                <div className="modal-header">
                                  <h5 className="modal-title mt-0" id="myModalLabel">
                                    <span class="popup-head"><i class="bx bx-plus"></i> Create a new Project</span>
                                  </h5>
                                  <button
                                    type="submit"
                                    className="btn btn-primary right-align"
                                    onClick={this.projectFormSubmit}
                                  >
                                    Done

                                  </button>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      this.setState({ modal_standard_project: false })
                                    }
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <div className="modal-body">
                                  <Row>
                                    <Col lg={9}>
                                      <div className="mb-3">
                                        <input
                                          name="project_name"
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Project Name..."
                                          value={this.state.project_name}
                                          onChange={this.handleNameChange}
                                        />
                                      </div>
                                    </Col>
                                    <Col lg={3}>
                                      <div className="mb-3">
                                        <button
                                          type="submit"
                                          className="btn btn-primary team-btn"
                                        >
                                          <span>A</span>{single_tname ? single_tname : 'No Team'}
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                  <FormGroup row>
                                    <Col sm={12}>
                                      <Editor
                                        placeholder="Schedule description"
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        toolbar={{
                                          options: ['inline', 'history', "emoji"],
                                          inline: { inDropdown: false },
                                          history: { inDropdown: true },
                                          emoji: { inDropdown: true }
                                        }}
                                        name='project_desc'
                                        value={this.state.project_desc}
                                        onChange={this.handleProjectDescChange}
                                      />
                                    </Col>
                                  </FormGroup>
                                  <Row>
                                    <Col lg={6}>
                                      <div className="input-group date-pick">
                                        <Flatpickr
                                          name="project_startDate"
                                          className="form-control d-block"
                                          placeholder="Starting date"
                                          value={this.state.project_startDate}
                                          onChange={this.handleStartDateChange}
                                          options={{
                                            altInput: true,
                                            altFormat: "F j, Y",
                                            dateFormat: "Y-m-d"
                                          }}

                                        />
                                        <span className="date-icon"><i class="bx bx-calendar"></i></span>
                                      </div>
                                    </Col>
                                    <Col lg={6}>
                                      <div className="input-group date-pick">
                                        <Flatpickr
                                          name="project_dueDate"
                                          className="form-control d-block"
                                          placeholder="Due date"
                                          value={this.state.project_dueDate}
                                          onChange={this.handleDueDateChange}
                                          options={{
                                            altInput: true,
                                            altFormat: "F j, Y",
                                            dateFormat: "Y-m-d"
                                          }}

                                        />
                                        <span className="date-icon"><i class="bx bx-calendar"></i></span>
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col lg={4}>
                                      <Select
                                        options={this.state.selectTeamOwnerOptions}
                                        onChange={this.handleTeamOwnerChange}
                                        classNamePrefix="select2-selection"
                                        validate={{
                                          required: { value: true },
                                        }}
                                        placeholder='Add Team Owner'
                                      />
                                    </Col>
                                    <Col lg={4}>
                                      <Select
                                        options={this.state.selectOptions}
                                        isMulti
                                        onChange={this.handleMemberChange}
                                        classNamePrefix="select2-selection"
                                        validate={{
                                          required: { value: true },
                                        }}
                                        placeholder='Add Members'
                                      />
                                    </Col>

                                    <Col lg={4}>
                                      <Select
                                        onChange={this.handleProjectPriorityGroup}
                                        options={option_project_priority_group}
                                        classNamePrefix="select2-selection"
                                        validate={{
                                          required: { value: true },

                                        }}
                                        placeholder='Select Priority'
                                      />
                                    </Col>
                                    {/* <Col lg={9}>
                                <div className="mb-3">
                                  <input
                                    name="project_progress"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Progress Value..."
                                    value={this.state.project_progress}
                                    onChange={this.handleNameChange}
                                  />
                                </div>
                              </Col> */}
                                  </Row>
                                </div>
                                <div className="modal-footer ftr-none">

                                </div>
                              </Modal>
                              < Modal
                                isOpen={this.state.task_modal_task}
                                toggle={this.task_tog_task}
                                centered={true}
                              >
                                <div className="modal-header">
                                  <h5 className="modal-title mt-0" id="myModalLabel">
                                    <span class="popup-head"><i class="bx bx-plus"></i> Create a new Task</span>
                                  </h5>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      this.setState({ task_modal_task: false })
                                    }
                                    className="close task-close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <div className="modal-body">
                                  <Row>
                                    <Col lg={12}>
                                      <div className="mb-3">
                                        <Select
                                          options={this.state.selectTeamMilestoneOptions}
                                          onChange={this.handleTaskMilestoneNameChange}
                                          classNamePrefix="select2-selection"
                                          placeholder=" Milestone Name..."
                                        />
                                      </div>
                                    </Col>
                                    <FormGroup row>
                                      <Col lg={12}>
                                        <div className="mb-3">
                                          <input
                                            name="task_name"
                                            type="text"
                                            errorMessage="Invalid name"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            className="form-control"
                                            placeholder="Enter Task Name..."
                                            value={this.state.task_name}
                                            onChange={this.handleTaskNameChange}
                                          />
                                        </div>
                                      </Col>
                                    </FormGroup>
                                    {/* <FormGroup row>
                                        <Col lg={12}>
                                          <div className="mb-3">
                                            <Select
                                              onChange={this.handleTaskTypeSelectGroup}
                                              options={option_task_type}
                                              classNamePrefix="select2-selection"
                                              placeholder="Select Tasktype..."
                                            />
                                          </div>
                                        </Col>
                                      </FormGroup>
                                      <FormGroup row>
                                        <Col lg={12}>
                                          <div className="mb-3">
                                            <input
                                              name="task_parent"
                                              type="text"
                                              errorMessage="Invalid name"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              className="form-control"
                                              placeholder="Add Task Parent Name..."
                                              value={this.state.task_parent}
                                              onChange={this.handleTaskNameChange}
                                            />
                                          </div>
                                        </Col>
                                      </FormGroup> */}

                                  </Row>
                                  <FormGroup row>
                                    <Col sm={12}>
                                      <Editor
                                        placeholder="Write task description"
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        toolbar={{
                                          options: ['inline', 'history', "emoji"],
                                          inline: { inDropdown: false },
                                          history: { inDropdown: true },
                                          emoji: { inDropdown: true }
                                        }}
                                        name='task_desc'
                                        value={this.state.task_desc}
                                        onChange={this.handleTeamDescChange}
                                      />
                                    </Col>
                                  </FormGroup>
                                  <FormGroup row>
                                    <Col lg={12}>
                                      <div className="mb-3">

                                      </div>
                                    </Col>
                                  </FormGroup>

                                  <Row className="row-mt">
                                    {/* <Col lg={12}>
                                        <input
                                          name="selectedFiles"
                                          type="file"
                                          errorMessage="Invalid To File"

                                          className="form-control"
                                          onChange={this.onFileChange}
                                          placeholder='Attach Files'
                                          
                                        />
                                        <br/>
                                      </Col> */}
                                    <Col lg={12}>
                                      <Select
                                        options={this.state.selectTaskMemberNameOptions}
                                        onChange={this.handleTaskMemberChange}
                                        isMulti
                                        classNamePrefix="select2-selection"
                                        placeholder='Assign people to this task'
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg={4}>
                                      <div className="input-group date-pick">
                                        <Flatpickr
                                          className="form-control d-block"
                                          placeholder="Starting date"
                                          name="project_startDate"
                                          className="form-control d-block"
                                          value={this.state.task_startDate}
                                          onChange={this.handleTaskStartDateChange}
                                          options={{
                                            altInput: true,
                                            altFormat: "F j, Y",
                                            dateFormat: "Y-m-d"
                                          }}

                                        />
                                        <span className="date-icon"><i class="bx bx-calendar"></i></span>
                                      </div>
                                    </Col>
                                    <Col lg={4}>
                                      <div className="input-group date-pick">
                                        <Flatpickr
                                          name="task_duedate"
                                          className="form-control d-block"
                                          placeholder="Due date"
                                          value={this.state.task_duedate}
                                          onChange={this.handleTaskDueDateChange}
                                          options={{
                                            altInput: true,
                                            altFormat: "F j, Y",
                                            dateFormat: "Y-m-d"
                                          }}

                                        />
                                        <span className="date-icon"><i class="bx bx-calendar"></i></span>
                                      </div>
                                    </Col>

                                    <Col lg={4}>
                                      <Select
                                        onChange={this.handleTaskSelectGroup}
                                        options={option_project_priority_group}
                                        classNamePrefix="select2-selection"
                                        placeholder='Select a priority'
                                      />
                                    </Col>
                                    <FormGroup row>
                                      {/* <Col lg={12}>
                              <input
                                    name="task_progress"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Progress Value..."
                                    value={this.state.task_progress}
                                    onChange={this.handleTaskNameChange}
                                  />
                            </Col> */}
                                    </FormGroup>
                                  </Row>
                                </div>
                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={this.taskFormSubmit}
                                  >
                                    Done
                                  </button>
                                </div>
                              </Modal >
                              {/* <div className="accordion-item">
                          <h2 className="accordion-header" id="headingTwo">
                            <button className="accordion-button fw-medium collapsed" type="button" onClick={this.t_col2} style={{ cursor: "pointer" }}>
                             Project of Team B <span className="accordion-sub"><span> <i class="bx bx-plus"></i>Projects</span> <span> <i class="bx bx-plus"></i>Tasks</span></span>
                              </button>
                          </h2>

                          <Collapse isOpen={this.state.col2} className="accordion-collapse">
                            <div className="accordion-body">
                              <div className="text-muted">
                              </div>
                            </div>
                          </Collapse>
                        </div> */}
                              {/* <div className="accordion-item">
                          <h2 className="accordion-header" id="headingThree">
                            <button className="accordion-button fw-medium collapsed" type="button" onClick={this.t_col3} style={{ cursor: "pointer" }}>
                              Project of Team C <span className="accordion-sub"><span> <i class="bx bx-plus"></i>Projects</span> <span> <i class="bx bx-plus"></i>Tasks</span></span>
                              </button>
                          </h2>
                          <Collapse isOpen={this.state.col3} className="accordion-collapse">
                            <div className="accordion-body">
                              <div className="text-muted">
                              </div>
                            </div>
                          </Collapse>
                        </div> */}
                            </div>
                          </Col>
                        </Row>
                      </TabPane>





                      <TabPane tabId="2">

                        <Row>
                          <Col lg={12}>



                            <div className="accordion" id="accordion">
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                  <button className="accordion-button fw-medium" type="button" onClick={this.t_col11} style={{ cursor: "pointer" }}>
                                    Project of Team A
                                  </button>
                                </h2>


                                <Collapse isOpen={this.state.col11} className="accordion-collapse">
                                  <div className="accordion-body">
                                    <div className="text-muted">
                                    </div>
                                  </div>
                                </Collapse>
                              </div>
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwo">
                                  <button className="accordion-button fw-medium collapsed" type="button" onClick={this.t_col22} style={{ cursor: "pointer" }}>
                                    Project of Team B
                                  </button>
                                </h2>

                                <Collapse isOpen={this.state.col22} className="accordion-collapse">
                                  <div className="accordion-body">
                                    <div className="text-muted">
                                      Team B
                                    </div>
                                  </div>
                                </Collapse>
                              </div>
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThree">
                                  <button className="accordion-button fw-medium collapsed" type="button" onClick={this.t_col33} style={{ cursor: "pointer" }}>
                                    Project of Team C
                                  </button>
                                </h2>
                                <Collapse isOpen={this.state.col33} className="accordion-collapse">
                                  <div className="accordion-body">
                                    <div className="text-muted">
                                      Team C
                                    </div>
                                  </div>
                                </Collapse>
                              </div>
                            </div>
                          </Col>
                        </Row>

                      </TabPane>


                    </TabContent>
                  </CardBody>
                </Card>
              </Col>


            </Row>


            <Row>
              <Card>
                <CardBody>
                  <Row>


                  </Row>
                </CardBody>
              </Card>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default Projectlist
