import React, { Component , useState} from "react"
import moment from 'moment';
import {
  Col,
  DropdownMenu,
  DropdownToggle,
  Row,
  Button,
  ButtonDropdown,
  DropdownItem,    
} from "reactstrap"
import "./DashboardEventBlock.scss"
import { chunk, filter } from 'lodash';
import CreateNewEvent from './CreateNewEvent';
import axios_config from "../../../helpers/axios_config"


// below export is for testing purpose 
import { events } from './events';

export default class DashboardEventBlock extends Component {
  // const = [data, setData] = useSate()
  constructor(props) {
    super(props)  


  this.state = {
    monthOpen: false,
    selectedMonth: moment().format("MMMM"),
    selectedYear: moment().format("YYYY"),
    selectedDay: moment().format("DD"),
    currentView: 'day',
    viewDays: [],    
    get_today_event:'',  
    currenttDate:'',
    selectTodayEventOptions:'',

  }
  this.eventToday = this.eventToday.bind(this)

}



  async getTodayEvent() {

    let dash_auth_id = localStorage.getItem("authid")

    const res = await axios_config.post('todo-list/today/', { memberid: dash_auth_id, })
    const today_event_data = res.data.data?.eventdata

    const options = today_event_data ? today_event_data.map(item => (

      {
        "event_name": item["eventname"],
        "event_time": item["event_time"],
        "event_date": item["event_date"],
      })) : "";

      var event_date = options[0]?.event_date
      

      var today = new Date().getFullYear()+'-'+("0"+(new Date().getMonth()+1)).slice(-2)+'-'+("0"+new Date().getDate()).slice(-2)

      console.log('fd todat',today)
      console.log('fd todat',event_date)
      if(event_date == today){
      this.setState({currenttDate: event_date})
      }


      console.log('rrrr8uhn', event_date)
      
    console.log("event data options", options);
    this.setState({ get_today_event: options })
    
   }

     //Get Todo_List
  async getDashTodoList() {
    let auth_id = localStorage.getItem("authid")
    console.log('create_auth_id', auth_id)
    const res = await axios_config.post('todo-list/', { memberid: auth_id, })
    console.log('dashboard_todolist', res)

    //Display Event
    const event_todo_data = res.data.data != null ? res.data?.data.eventdata: '';

    const todo_event_options = event_todo_data ? event_todo_data.map(item => ({
      "todo_event_name": item["eventname"],
      "todo_event_time": item["event_time"],
      "todo_event_date": item["event_date"],

    })) : "";
    console.log("Dashboard event todo data event options", todo_event_options);
    this.setState({ selectTodoEventOptions: todo_event_options })
  }

 
  async eventToday(event){
    console.log()
  
    const{selectedMonth, selectedYear, selectedDay } = this.state
    var months = {
      'January'   : '01',
      'February'  : '02',
      'March'     : '03',
      'April'     : '04',
      'May'       : '05',
      'June'       : '06',
      'July'      : '07',
      'August'    : '08',
      'September' : '09',
      'October'   : '10',
      'November'  : '11',
      'December'  : '12'
  }
  var monthNum = months[selectedMonth];
  
  var monthNumber = months[selectedMonth];
  
  let get_date = selectedYear+'-'+monthNumber+'-'+selectedDay
  
  let dash_auth_id = localStorage.getItem("authid")
  if(get_date = get_date){
  
  const res = await axios_config.post('todo-list/current_date/', { memberid: dash_auth_id, current_date: get_date })
  
  //Display Event
  const event_today_data = res.data.data?.eventdata
  
       const today_event_options = event_today_data ? event_today_data.map(item => ({
  
          "today_event_name": item["eventname"],
          "today_event_date": item["event_date"],
          "today_event_time": item["event_time"],
          "today_event_end_time": item["event_end_time"],
          
    
        })) : "";
      console.log("Today Current Event options", today_event_options);
      // this.setState({ selectTodayEventOptions:today_event_options })
      //   setTimeout(
      //   () => this.setState({ selectTodayEventOptions:today_event_options }), 
      //   1000
      // );

    //   setInterval(() => { 
    //     this.setState({
    //       selectTodayEventOptions: today_event_options
    //     })
    // }, 2000);
      
        //this.setState({selectTodayEventOptions:today_event_options})
      }else{
        console.log( "No Date Found")
       
      }
  }
 
  componentDidMount() {
    this.getDashTodoList();
    this.getTodayEvent();
    this.eventToday();



  }


  componentDidUpdate(prevState, prevProps, snapSho){
     this.eventToday();
    // console.warn('componentDidUpdate', prevProps)
  }



  generateDays(){
    
    const { selectedDay, selectedMonth, selectedYear, currentView} = this.state;
    let currentDate = moment(`${selectedYear}/${selectedMonth}/${selectedDay}`, 'YYYY/MMMM/DD');
    let days = [], startDate =null, endDate = null;    
    switch(currentView){
      case 'week':
        startDate = currentDate.clone().startOf('week');
        endDate = currentDate.clone().endOf('week'); 
        days.push(startDate);              
      break;
      case 'month':
        startDate = currentDate.clone().startOf('month');
        endDate = currentDate.clone().endOf('month');        
        // alert(startDate.format("D") - 1)
        for(let i=0; i< startDate.day(); i++){
          days.push(null);
        }
        days.push(startDate);
      break;
    }
    var currDate = moment(startDate).startOf('day');
    var lastDate = moment(endDate).startOf('day');
    
    while(currDate.add(1, 'days').diff(lastDate) < 0) {        
        days.push(currDate.clone().toDate());
    }
    days.push(endDate);
    days = chunk(days, 7);
    
    this.setState({
      viewDays: days
    });
  }

  nextOrPrev(act='+'){
    
    const { selectedDay, selectedMonth, selectedYear, currentView} = this.state;
    let currentDate = moment(`${selectedYear}/${selectedMonth}/${selectedDay}`, 'YYYY/MMMM/DD');
    
    
    if(act == '+'){      
      currentDate = currentDate.add(1, `${currentView}s`);
    }else{
      currentDate = currentDate.subtract(1, `${currentView}s`);
    }    
    
    this.setState({
      
      selectedMonth: currentDate.format("MM"),
      selectedYear: currentDate.format("YYYY"),
      selectedDay: currentDate.format("DD"),
    }, ()=>{
      
      this.generateDays();
    });    
  }

  render() {
    const months = moment.months();        
    const { monthOpen, selectedMonth, currentView, viewDays, selectedYear, selectedDay } = this.state;
    console.log(currentView)
     const currentDate = moment(`${selectedYear}/${selectedMonth}/${selectedDay}`, 'YYYY/MMMM/DD');    
     console.log('MEraaaaaaa',currentDate._i)
     
     
    const bubleClass = ["text-primary", "text-warning", "text-danger", "text-success", "text-info"]
    let eventCount = 0;

    const current_today_date = this.state.selectTodayEventOptions
    console.log('current_today_dateeeeeee',current_today_date)
    
    // const dayEvent = filter(events, (evt) => moment(currentDate).isBetween(moment(evt.start), moment(evt.end), 'days', '[]') );
    // console.log('gfgfgf',dayEvent)
    const event_day = this.state.get_today_event
    const currDatee = this.state.currenttDate
    console.log('currDatee',currDatee)

    return (
      <React.Fragment>
        
          <div data-simplebar className="h-100">
            <div className="rightbar-title px-3 py-4">  
              <Row>
                <Col md="6">
                  <h5 className="m-0">My Events</h5>
                </Col>
                <Col md="6">
                  <Row>
                    <Col md="5">

                      <div className="btn-group mt-2 mt-xl-0" role="group" aria-label="Basic radio toggle button group">
                          <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off"
                          onChange={()=>{
                            console.log("shifted to day view")
                            this.setState({currentView: 'day'})
                          }}
                          checked={currentView==='day'} />
                          <label className={`btn btn-primary ${currentView==='day' ? `active`: ``}`} htmlFor="btnradio1">Day</label>

                          <input type="radio" className="btn-check" name="btnradio" id="btnradio2" autoComplete="off"
                          onChange={()=>{
                            this.setState({currentView: 'week'}, ()=>{
                              console.log("shifted to week view")
                              this.generateDays();
                            });
                            
                          }}
                          checked={currentView==='week'} />
                          <label className={`btn btn-primary ${currentView==='week' ? `active`: ``}`} htmlFor="btnradio2">Week</label>

                          <input type="radio" className="btn-check" name="btnradio" id="btnradio3" autoComplete="off" 
                          onChange={()=>{
                            this.setState({currentView: 'month'}, ()=>{
                              console.log("shifted to month view")
                              this.generateDays();
                            });
                           
                          }}
                          checked={currentView==='month'}/>
                          <label className={`btn btn-primary ${currentView==='month' ? `active`: ``}`} htmlFor="btnradio3">Month</label>
                      </div>
                      
                    </Col>
                    <Col md="4">
                      <ButtonDropdown
                          isOpen={monthOpen}
                          toggle={(evt) =>{                            
                            return this.setState({monthOpen: !monthOpen });
                            
                          }                                                        
                          }
                      >
                        <DropdownToggle caret color="primary">
                          {selectedMonth} <i className="mdi mdi-chevron-down"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {
                            months.map((month, idx)=> <DropdownItem key={idx} 
                            onClick={() => this.setState({selectedMonth: month}, ()=> {
                              
                              console.log(`shifted to month: ${month} `)
                              this.generateDays();
                            })} >{month}</DropdownItem>)
                          }                                                    
                        </DropdownMenu>
                      </ButtonDropdown>{" "}                                            
                    </Col>
                    <Col md="2">
                        <Button color="primary" size="sm" onClick={()=> {
                          console.log("shifted to previous")
                          this.nextOrPrev('-')
                        }}><i className="bx bx-chevron-left"></i></Button>
                        {' '}
                        <Button color="primary" size="sm" onClick={()=> {
                          console.log("shifted to next")
                          this.nextOrPrev('+')
                        }}><i className="bx bx-chevron-right"></i></Button>
                      </Col> 
                  </Row>
                 
                </Col>
              </Row>
              
            </div>

            <hr className="my-0" />

            <div className="p-4">
                      
                          {
                            currentView && currentView !== 'day' && 
                            <>
                            {
                              viewDays && viewDays.map((dayVal, didx)=><div key={didx} className="cust-cal">
                                { dayVal && dayVal.map((day, dayindex) =>{
                                  eventCount = 0;
                                  
                                return <div key={`d-${dayindex}`}> 
                                  {
                                    
                                    day && <>
                                    <a 
                                      onClick={() => { 
                                        this.eventToday()
                                        // console.log(`clicked on ${moment(day).format("dddd, DD MMMM, YYYY")}`)
                                        console.log(`clicked on ${moment(day).format("DD")}${moment(day).format("DD")}`)
                                        this.setState({selectedDay: moment(day).format("DD")})
                                      }}
                                      className={(selectedDay === moment(day).format("DD")) ? 'active' : ''}>
                                      {  moment(day).format("ddd") }    <br/>                                               
                                      { moment(day).format("DD") }  
                                      {this.eventToday}
                                    </a>
                                    {
                                      events.map((res, ridx)=>{
                                        
                                        
                                        let exists =  false;
                                        if(moment(day).isBetween(moment(res.start), moment(res.end), 'days', '[]')){
                                          ++eventCount;
                                          exists = true;
                                        }
                                        if(exists && eventCount < 5){
                                          const bubleClassName = bubleClass[Math.floor(Math.random() * bubleClass.length)];
                                          return <i className={`mdi mdi-circle me-1 ${bubleClassName}`} key={`icon-${ridx}`}></i>;                                        
                                        }
                                      })
                                        
                                    }   
                                    {
                                      eventCount> 0 && eventCount - 4 > 0  && `+${eventCount - 4}`
                                    }
                                    </>
                                  }                                                                                                                                         
                                </div> 
                                  } )                                      
                                }                        
                              </div>)
                            }
                            </>
                            
                          }
              


              {/* events */}
              <br/>
             
              
              {/* { console.log("cheking",currDatee) } */}
              
              {/* { currDatee ? currentDate.format("dddd")+', '+ currentDate.format('DD')+'th'+ currentDate.format('MMMM, YYYY'):'No event'} */}
             
              { currentDate.format('dddd') }, { currentDate.format('DD')}th  { currentDate.format('MMMM, YYYY')} 
              

              
              <br/>
                       {console.log('hh',this.state.today_event_options)}
              {
                
                current_today_date? current_today_date.map((dEvt, idx) =>{
                  return <Row key={idx}>
                            <Col className="mb-2">
                                <Row className="event-meet-out">
                                  <Col className="event-left">
                                    <div className="event-headtxt">
                                      {dEvt.today_event_name}
                                    </div>                  
                                  </Col>
                                  <Col className="event-right"> 
                                    <div className="event-time"><i class="mdi mdi-clock-outline"></i>{dEvt.today_event_time} - {dEvt.today_event_end_time}</div>
                                    <div className="event-tag">A</div>
                                  </Col>
                                </Row>                               
                            </Col>
                          </Row>
                }):'No Record Found'
              }
              

              
            </div>
            
            <CreateNewEvent para_id={this.props.data}/>
          </div>        
       
      </React.Fragment>
    )
  }
}



