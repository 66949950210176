import React, { Component } from "react"
import MetaTags from 'react-meta-tags';

import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Row,
} from "reactstrap"

// Form Editor
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import axios_config from "../../../helpers/axios_config"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
    

import Timeline, {     
  TimelineHeaders,
  SidebarHeader,
  DateHeader
} from 'react-calendar-timeline'
import 'react-calendar-timeline/lib/Timeline.css'
import moment from 'moment'

const groups = [{ id: 1, title: 'group 1' }, { id: 2, title: 'group 2' }]
 
const items = [
  {
    id: 1,
    group: 1,
    title: 'item1',
    start_time: moment(),
    end_time: moment().add(1, 'hour')
  },
  {
    id: 2,
    group: 2,
    title: 'item2',
    start_time: moment().add(-0.5, 'hour'),
    end_time: moment().add(0.5, 'hour')
  },
  {
    id: 3,
    group: 1,
    title: 'item3',
    start_time: moment().add(2, 'hour'),
    end_time: moment().add(3, 'hour')
  }
]

class Timelines extends Component {
  constructor(props) {
    super(props)
    this.state = {
      my_events:[]
    }

  }
  async allEventData(){

    const response = await axios_config.post('alltodo-list/')
    // console.log('hooks_cl', response)
    const data = response.data.data
    console.log(' event all data', data)
  
    const options = data ? data.map(item => ({
      "title": item["event_name"],
      "start": new Date(item["event_date"] + " "+ item["event_time"]).toISOString(),
      "end": new Date(item["event_end_date"]+ " "+ item["event_time"]).toISOString(),
      // "description":item['event_desc']
    })) : ''
    console.log(' event_all_option_data', options)
  
  
  this.setState({my_events:options})
  }

  componentDidMount() {

    // this.allEventData();



  }

  render() {
    return (
      <React.Fragment>

     <div>
 <Timeline
      groups={groups}
      items={items}
      defaultTimeStart={moment().add(-12, 'hour')}
      defaultTimeEnd={moment().add(12, 'hour')}
    />
  </div>,

        
      </React.Fragment>
    )
  }
}

export default Timelines
