import React, { Component } from "react"
import PropTypes from 'prop-types'

import { Alert, Card, CardBody, Col, Container, Row, CardTitle, CardText, } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { Link, Redirect, withRouter } from "react-router-dom"

//Social Media Imports
import { GoogleLogin } from "react-google-login"
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"

//Import config
import { facebook, google } from "../../config"

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"

// actions
import { apiError, loginUser, socialLogin } from "../../store/actions"

// import images
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logo.svg"
import lightlogo from "../../assets/images/logo-light.svg"
import logoLightSvg from "../../assets/images/logo-light.svg"
import loginrightbg from "../../assets/images/loginrightbg.png"
import axios_config from "../../helpers/axios_config"
import { messages } from "common/data"

class Login extends Component {
  constructor(props) {
    super(props)
    let loggedIn = false
    this.state = {
      username: '',
      password: '',
      loggedIn
    }

    // handleValidSubmit
    this.handleValidChange = this.handleValidChange.bind(this)
    this.handleValidSubmit = this.handleValidSubmit.bind(this)
  }

  // handleValidChange
  handleValidChange(event){
    this.setState({
      [event.target.name]: event.target.value
    });
  }

// handleValidSubmit
handleValidSubmit(event) {
  event.preventDefault();
  const { username, password } = this.state;
    // if(username === "A" && password === "A"){
    //   this.setState({
    //             loggedIn:true
    //           })  
    // }

    axios_config.post("/signin/",
      
        {
          username: username,
          password: password
        }
    )
    .then(response => {
      console.log("resp from login user", response);
      localStorage.setItem("authUser", JSON.parse(JSON.stringify(response.config.data)))
      localStorage.setItem("authid", JSON.parse(JSON.stringify(response.data.data != null ? response.data.data[0].userid : '')))
      if (response.data.success == "true") {
        console.log("success");
          this.setState({
                loggedIn:true
                
              })

      }else{
        let msg = response.data.msg
        alert(msg)
      }
    })
    .catch(error => {
      console.log("login error", error);
    });
  
}

  componentDidMount() {
    this.props.apiError("")
  }

  signIn = (res, type) => {
    const { socialLogin } = this.props
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      }
      socialLogin(postData, this.props.history, type)
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      }
      socialLogin(postData, this.props.history, type)
    }
  }

  //handleGoogleLoginResponse
  googleResponse = response => {
    this.signIn(response, "google")
  }

  //handleTwitterLoginResponse
  twitterResponse = () => { }

  //handleFacebookLoginResponse
  facebookResponse = response => {
    this.signIn(response, "facebook")
  }

  render() {
    if(this.state.loggedIn){
      return <Redirect to ="/teams"> </Redirect>
    }
    return (
      <React.Fragment>
        <div className="account-pages loginpage">
            <Row className="justify-content-center">
              <Col md={6} className="login-leftside">
                <Card className="overflow-hidden">
                  <CardBody className="pt-0 loginform">
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={this.handleValidSubmit}
                      >
                        {this.props.error && this.props.error ? (
                          <Alert color="danger">{this.props.error}</Alert>
                        ) : null}


                        <div className="form-head">
                         <Link to="/" className="logo-form  ">
                            <img src={logoLightSvg} alt="" height="22" />
                        </Link>
                        <CardTitle className="h2">Login</CardTitle>
                        <p>Login to your page</p>
                       

                         </div>
                          <div className="form-feilds">
                          <div className="mt-4 text-center gogle-sect">
                          
                              <GoogleLogin
                                // clientId={google.CLIENT_ID}
                                render={renderProps => (
                                  <Link
                                    to={""}
                                    className="googlelink"
                                  // onClick={renderProps.onClick}
                                  >Sign in with Google</Link>
                                )}
                                onSuccess={this.googleResponse}
                                onFailure={() => { }}
                              />
                           <div className="gogle-sect-txt">
                              <p className="mb-3"> or Sign in with Email </p>
                           </div>
                        </div>
                        <div className="mb-3">
                        <AvField
                            name="username"
                            label="Username"
                            className="form-control"
                            placeholder="Enter username"
                            type="text"
                            value={this.state.username}
                            onChange={this.handleValidChange}
                            required
                          />
                        </div>
                        <div className="mb-3">
                        <AvField
                            name="password"
                            label="Password"
                            type="password"
                            required
                            placeholder="Enter Password"
                            value={this.state.password}
                            onChange={this.handleValidChange}
                          />
                        </div>
                        <div className="mb-3 rem-fgt">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customControlInline"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customControlInline"
                          >
                            Remember me
                          </label>
                        </div>
                        <Link to="/forgot-password" className="text-muted">
                            Forgot password?
                          </Link>
                          </div>
                        <div className="mt-3 ">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            Login
                          </button>
                        </div>
                        </div>
                     </AvForm>
                  </CardBody>
                  <div className="signin-ftr">
                  <p>
                    © {new Date().getFullYear()} Astrac
                  </p>
                </div>  
                </Card>

              </Col>
               <Col md={6} className="login-rightside">
                  <div className="lg-sidebg">
                     <img src={loginrightbg} alt="" className="loginrightbg" />
                   </div>
                   <div className="lg-sidetxt">
                   <CardTitle className="h2">Centralize internal
                          Communication.</CardTitle>
                     
                   </div>
               </Col>
            </Row>
        </div>
      </React.Fragment>
    )
  }
}

Login.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin })(Login)
)
