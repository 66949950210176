import axios from 'axios';
// Next we make an 'instance' of it

// const API_URL = "http://159.65.0.23:8000/appmgt"
const API_URL = "http://127.0.0.1:8000/appmgt"
const instance = axios.create({

baseURL: API_URL 
});

// Where you would set stuff like your 'Authorization' header, etc ...
instance.defaults.headers.common['Authorization'] = 'AUTH TOKEN FROM INSTANCE';

// Also add/ configure interceptors && all the other cool stuff

instance.interceptors.request

export default instance;