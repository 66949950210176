import React, { Component } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  DropdownMenu,
  DropdownToggle,
  Form,
  Row,
  UncontrolledDropdown,
} from "reactstrap"
import Chat from './Chat/Chat';
export default class FileList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      myfiles: [
        {
          id: 1,
          name: "Design",
          file: "12",
          Gb: 6,
        },
        {
          id: 2,
          name: "Development",
          file: "20",
          Gb: 8,
        },
        {
          id: 3,
          name: "Project A",
          file: "06 ",
          Gb: 2,
        },
        {
          id: 4,
          name: "Admin",
          file: "08",
          Gb: 4,
        },
        {
          id: 5,
          name: "Sketch Design",
          file: "12",
          Gb: 6,
        },
        {
          id: 6,
          name: "Applications",
          file: "20",
          Gb: 8,
        },
      ],
    }
  }
  render() {
    return (
      <React.Fragment>
     
     <div className="teamchat-section">
         <Chat />
     </div>




      </React.Fragment>
    )
  }
}
