import PropTypes from "prop-types"
import React, { Component } from "react"
import ProjectsCreate from './ProjectsCreate';
//Simple bar
import SimpleBar from "simplebar-react"
import Dropzone from "react-dropzone"
// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link, Redirect } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
//Lightbox
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import ModalVideo from "react-modal-video"
import "react-modal-video/scss/modal-video.scss"
import {
  addNewProject,
  getProjects,
} from "../../store/projects/actions"
import "react-datepicker/dist/react-datepicker.css"
import Flatpickr from "react-flatpickr"
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Progress,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Collapse,
  Container,
  FormGroup,
  Nav,
  NavItem,
  NavLink,
  Input,
  TabContent,
  TabPane,
} from "reactstrap"

import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import axios_config from "../../helpers/axios_config"
import Select from "react-select"
import Loader from "react-loader-spinner";
import { ContactSupportOutlined } from "@material-ui/icons";

class SidebarContent extends Component {
  constructor(props) {
    super(props)
    let create_team = false
    this.state = {
      selectedFiles: [],
      modal_standard: false,
      modal_project: false,
      modal_task: false,
      activeTab: "1",
      activeTab1: "5",
      activeTab2: "9",
      activeTab3: "13",
      verticalActiveTab: "1",
      verticalActiveTabWithIcon: "1",
      customActiveTab: "1",
      customIconActiveTab: "1",
      activeTabJustify: "5",
      col1: true,
      col2: false,
      col3: false,
      col5: true,
      col6: true,
      col7: true,
      col8: true,
      col9: true,
      col10: false,
      col11: false,
      modal_standard: false,
      //Get Team Name

      //Create Team
      team_name: "",
      team_desc: "",
      team_owner: "",
      add_members: "",
      selectOptions: [],
      value: [],
      selectSingleOptions: [],
      selectedFiles: '',
      create_team,
      selectTeamNameOptions: [],
      loading: false,

      pathname: ''
    }
    this.tog_team = this.tog_team.bind(this)
    this.refDiv = React.createRef()
    this.handleSingleChange = this.handleSingleChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleTeamDescChange = this.handleTeamDescChange.bind(this);
    this.onFileChange = this.onFileChange.bind(this)
  }
  tog_team() {
    this.setState(prevState => ({
      modal_team: !prevState.modal_team,
    }))
    this.removeBodyCss()
  }

  removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  onFileChange = event => {

    this.setState({ selectedFiles: event.target.files[0] });

  };

  //Start Create Team

  handleTeamDescChange = (event) => {
    this.setState({ team_desc: event.blocks[0].text })

  };

  handleSingleChange(e) {
    this.setState({ team_owner: e.value })

  }

  handleNameChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });

  }

  handleChange(e) {
    this.setState({ value: e })

  }
  async getOptions() {


    const response = await axios_config.post('/all-users/',

      {
        usertype: 3
      }
    ).then(response => {

      const data = response.data.data
      //  console.log("multi resp data", data);

      const options = data.map(item => ({
        "value": item["user id"],
        "label": item["user firstname"]
      }))
      this.setState({ selectOptions: options })


    })

  }

  async getSingleOptions() {
    const response = await axios_config.post('/all-users/',

      {
        usertype: 3
      }
    ).then(response => {

      const data = response.data.data
      //  console.log("single resp data", data);

      const options = data.map(d => ({
        "value": d["user id"],
        "label": d["user firstname"]
      }))
      this.setState({ selectSingleOptions: options })


    })

  }

  teamFormSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
      team_name:'',
      team_owner:'',
      value:'',
      selectedFiles:'',
      modal_team: false,
    })

    const { team_name, team_desc, team_owner, value, selectedFiles } = this.state;
    console.log("update value")
    console.log(team_name)
    console.log(team_desc)
    console.log(team_owner)
    console.log(value)
    console.log(selectedFiles)
    var arr = [];
    this.state.value.map((postData) => {
      arr.push(postData.value)
    })
    console.log(arr)

    let bodyFormData = new FormData();

    bodyFormData.append("TeamName", team_name);
    bodyFormData.append("Teamdescription", team_desc);
    bodyFormData.append("Teamowner", team_owner);
    bodyFormData.append("MembersId", arr);
    bodyFormData.append("Teamavtar", selectedFiles);


    axios_config({
      method: "post",
      url: "/team/",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then(response => {
      console.log("resp from login user", response);

      if (response.data.success == "true") {
        console.log("Add Team Successfully");
        alert("Add Team Successfully")
        this.getTeamNameOptions();
        this.setState({
          create_team: true,
          modal_team: false,
          loading: false
        })

      }
      else {
        let msg = response.data.msg;
        alert(msg)
        this.setState({
          create_team: true,
          modal_team: false,
          loading: false
        })
         this.getTeamNameOptions();
      }
    })
      .catch(error => {
        console.log("login error", error);
      });

  }

  //End Create Team

  //All Teams
  async getTeamNameOptions() {
    const res = await axios_config.post('/all-teams/')
    const data = res.data.data
    // console.log(res)

    const options = data.map(item => ({
      "id": item["Team id"],
      "tname": item["Team Name"]
    }))
    // console.log("sidebar team name options", options);
    this.setState({ selectTeamNameOptions: options })
  }
  //End All Teams

  //Sidebar
  async getSidebar() {
    const path = this.props.location.pathname
    this.setState({ pathname: path })

  }

  //End Sidebar
  componentDidMount() {
    this.initMenu()
    this.getTeamNameOptions()
    this.getOptions()
    this.getSingleOptions()
    this.getSidebar()
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props.type !== prevProps.type) {
      this.initMenu()
    }
  }

  initMenu() {
    new MetisMenu("#side-menu")

    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem)
    }
  }

  scrollElement = item => {
    setTimeout(() => {
      if (this.refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop
          if (currentPosition > window.innerHeight) {
            if (this.refDiv.current)
              this.refDiv.current.getScrollElement().scrollTop =
                currentPosition - 300
          }
        }
      }
    }, 300)
  }

  activateParentDropdown = item => {
    item.classList.add("active")
    const parent = item.parentElement

    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      this.scrollElement(item)
      return false
    }
    this.scrollElement(item)
    return false
  }

  render() {
    if (this.state.loading) {
      return <Loader
        style={{ position: "fixed", top: "50%", left: "60%", transform: "translate(-50%, -50%)" }}
        type="Circles"
        color="#00BFFF"
        height={100}
        width={100}
        timeout={3000} //3 secs
      />
    } 
    // if (this.state.create_team) {
    //   return <Redirect to="/"> </Redirect>
    // }
    var count = 1
    var path = this.state.pathname

    var url = this.props.location.pathname;
    url = url.toString().split('/')[1];

    return (
      <React.Fragment>
        <SimpleBar style={{ maxHeight: "100%" }} ref={this.refDiv}>
          {/* <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">

              <li>
                <Link to="/index" className="">
                  <i className="bx bx-home" />
                  <span>{this.props.t("Home")}</span>
                </Link>
              </li>
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-envelope"></i>
                  <span>{this.props.t("Email")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/email-inbox">{this.props.t("Inbox")}</Link>
                  </li>
                  <li>
                    <Link to="/email-read">{this.props.t("Read Email")} </Link>
                  </li>
                  <li>
                    <Link to="/#">
                      <span
                        className="badge rounded-pill badge-soft-success float-end"
                        key="t-new"
                      >
                        {this.props.t("New")}
                      </span>
                      <span key="t-email-templates">
                        {this.props.t("Templates")}
                      </span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                      <li>
                        <Link to="/email-template-basic">
                          {this.props.t("Basic Action")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/email-template-alert">
                          {this.props.t("Alert Email")}{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/email-template-billing">
                          {this.props.t("Billing Email")}{" "}
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/favorites" className="">
                  <i className="bx bx-star" />
                  <span>{this.props.t("Favorites")}</span>
                </Link>
              </li>

            </ul>

          </div> */}
          <div class="sidebar-menu2">
            <div class="search-hd">
              <span class="search-hd-text">Teams</span>
              <span class="search-icon"><i className="bx bx-search" /></span>
            </div>
            <ul className="metismenu list-unstyled search-list" id="side-menu" aria-expanded="false">
              { path == '/Projectlist' || url == 'projectlist' ? (<>
                <li> <Link to="/Projectlist" className=""><span><i className="bx bx-data" /></span>All Teams</Link></li>
                {this.state.selectTeamNameOptions.map((item, index) =>
                  <li key={index}><Link to={"/projectlist/" + item.id}><span>{index + 1}</span>{item.tname}</Link></li>)}
              </>) :
                (<>
                <li> <Link to="/teams" className=""><span><i className="bx bx-data" /></span>All Teams</Link></li>
                { this.state.selectTeamNameOptions.map((item, index) =>
                  <li key={index}><Link to={"/teams/" + item.id}><span>{index + 1}</span>{item.tname}</Link></li>)
               }
                </>)
                  }



              {/* { this.state.selectTeamNameOptions.map((item, index) => <li key={index}><Link  to={"/teams/"+item.id}><span>{index+1}</span>{item.tname}</Link></li>)} */}
              {/* <li><Link to="/Projecttask" className=""><span>B</span>Team B</Link></li> */}
              {/* <li><Link to="/Projectlist" className=""><span>C</span>Team C</Link></li>
              <li><Link to="/Projectnew" className=""><span>C</span>Team D</Link></li> */}
              <li>
                <button
                  type="button"
                  onClick={this.tog_team}
                  className="btn btn-primary"
                  data-toggle="modal"
                  data-target="#myModal"
                >
                  <span class="addteam-form"><i class="bx bx-comment-detail"></i> Add Team</span>
                </button>

                <Modal
                  isOpen={this.state.modal_team}
                  toggle={this.tog_team}
                  centered={true}
                >
                  <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                      <span class="popup-head"><i class="bx bx-plus-circle"></i> Create team</span>
                    </h5>
                    <button
                      type="submit"
                      className="btn btn-primary right-align"
                      onClick={this.teamFormSubmit}
                    >
                      Done
                    </button>
                    <button
                      type="button"
                      onClick={() =>
                        this.setState({ modal_team: false })
                      }
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <FormGroup row>
                      <Col lg={12}>
                        <input
                          name="team_name"
                          type="text"
                          errorMessage="Invalid name"
                          validate={{
                            required: { value: true },
                          }}
                          className="form-control"
                          placeholder="Enter Team Name..."
                          value={this.state.team_name}
                          onChange={this.handleNameChange}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col lg={12} >
                        <Select
                          options={this.state.selectSingleOptions}
                          onChange={this.handleSingleChange}
                          classNamePrefix="select2-selection"
                          placeholder='Assign owner to this  team'
                        />

                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col lg={12} >
                        <Select
                          options={this.state.selectOptions}
                          isMulti
                          onChange={this.handleChange}
                          classNamePrefix="select2-selection"
                          placeholder='Assign member to this team'

                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col lg={12} >
                        <input
                          name="selectedFiles"
                          type="file"
                          errorMessage="Invalid To File"
                          className="form-control"
                          onChange={this.onFileChange}
                          placeholder='Attach Files'
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col sm={12}>
                        <Editor
                          placeholder="Schedule description"
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          toolbar={{
                            options: ['inline', 'history', "emoji"],
                            inline: { inDropdown: false },
                            history: { inDropdown: true },
                            emoji: { inDropdown: true }
                          }}
                          name='team_desc'
                          value={this.state.project_desc}
                          onChange={this.handleTeamDescChange}
                        />
                      </Col>
                    </FormGroup>

                  </div>
                  <div className="modal-footer ftr-none">

                  </div>
                </Modal>
              </li>
            </ul>
          </div>

          {/* <div class="sidebar-menu3">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li>
                <Link to="/settings" className="">
                  <i className="bx bx-cog" />
                  <span>{this.props.t("Settings")}</span>
                </Link>
              </li>
              <li>
                <Link to="/settings" className="">
                  <i className="bx bx-help-circle" />
                  <span>{this.props.t("Help")}</span>
                </Link>
              </li>
            </ul>
          </div> */}
        </SimpleBar>
      </React.Fragment>
    )
  }
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
}

export default withRouter(withTranslation()(SidebarContent))
