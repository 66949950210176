import React, { Component, useState, useEffect } from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, 
    Col,  Form, FormGroup, Label, Input, FormText, InputGroup } from 'reactstrap';


import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import Select from "react-select"
import axios_config from "../../../helpers/axios_config"
import { useParams, useLocation, Link, Redirect, Route } from 'react-router-dom';

import Loader from "react-loader-spinner";
export default class CreateNewEvent extends Component {
  
    
    constructor(props) {
        super(props)
       
        let event_create_in = false
        this.state = {
          event_project_name:'',
          event_name:'',
          event_desc:'',
          event_time:'',
          event_date:'',
          event_members_value:[],
          get_today_event:'',
          url_id:'',
          event_startDate:'',
          event_endDate:'',
          event_startTime:'',
          event_endTime:'',
          selectTaskMemberNameOptions:'',
          event_create_in,
          loading: false,
          id_default: 0
    
        }
        // this.startDateChange.bind(this)
        // this.startTimeChange.bind(this)
        // this.endDateChange.bind(this)
        // this.handleAcceptedFiles.bind(this)
        this.handleValueChange = this.handleValueChange.bind(this)
        // this.handleMemberChange = this.handleMemberChange.bind(this)
        this.handleValidEventSubmit = this.handleValidEventSubmit.bind(this)
        this.handleEventDescChange = this.handleEventDescChange.bind(this);
        this.handleEventProjectNameChange = this.handleEventProjectNameChange.bind(this)
        this.handleEventStartDateChange = this.handleEventStartDateChange.bind(this)
        this.handleEventTimeStartChange = this.handleEventTimeStartChange.bind(this)
        this.handleEventDateEndChange = this.handleEventDateEndChange.bind(this)
        this.handleEventTimeEndChange = this.handleEventTimeEndChange.bind(this)
        this.handleEventMemberChange = this.handleEventMemberChange.bind(this);

        
    
      }
    state = {
        modal: (this.props.modal) ? this.props.modal : false
    }
    handleEventMemberChange(event) {
      this.setState({ event_members_value: event })
  
    }

    handleEventStartDateChange = (event) => {
      this.setState({ event_startDate: event[0] })
  
    };
    handleEventTimeStartChange = (event) => {
      this.setState({ event_startTime: event[0] })
  
    };
    handleEventDateEndChange = (event) => {
      this.setState({ event_endDate: event[0] })
  
    };
    handleEventTimeEndChange = (event) => {
      this.setState({ event_endTime: event[0] })
  
    };
    
    handleEventDescChange = (event) => {
        this.setState({ event_desc: event.blocks[0].text })
    
      };

      async getEventMembersOptions() {
        const response = await axios_config.post('/all-users/', { usertype: 3 })
        const data = response.data.data
        const options = data.map(d => ({
          "value": d["user id"],
          "label": d["user firstname"]
        }))
        this.setState({ selectTaskMemberNameOptions: options })
      }

    handleValueChange(event){

        this.setState({[event.target.name]:event.target.value})
      }

    async getProjectOptions() {

    
        let para_id = this.props.para_id
        const res = await axios_config.post('/all-projects/', {teamid:para_id})
        const data = res.data.data
    
        const options = data? data.map(item => ({
          "value": item["project id"],
          "label": item["Project Name"]
        })):''

     this.setState({ selectProjectNameOptions: options })
  
    }
    componentDidUpdate(prevProps) {
      if (prevProps.para_id !== this.props.para_id) {
        this.getProjectOptions();
  
      }
    }      
    
      handleEventProjectNameChange(e) {
        this.setState({ event_project_name: e.value })
    
      }
      handleValidEventSubmit = (event) => {
        event.preventDefault();

        this.setState({
          loading: true
        })

        const { event_project_name, event_name, event_desc, event_members_value, event_startDate, event_endDate, event_startTime, event_endTime } = this.state;
        
        function convert(str) {
          var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
          return [date.getFullYear(), mnth, day,].join("-");
        }

        let start_date = convert(event_startDate)
        let end_date = convert(event_endDate)
        
        let start_time = event_startTime.toString()
        let split_start_time = start_time.split(' ')
        let get_start_time = split_start_time[4]
       // console.log(get_start_time)

        let end_time = event_endTime.toString()
        let split_end_time = end_time.split(' ')
        let get_end_time = split_end_time[4]
        //console.log(get_end_time)


        // console.log(getFormattedString(event_startTime))

    
        // let add_time = event_time+':'+'00'
        // console.log(add_time)
    
        // let date_change = event_date.split("-")
        // var event_up_date_format = date_change[2]+'-'+date_change[1]+'-'+date_change[0]
    
        var arr_members_value = [];
        this.state.event_members_value.map((postData) => {
          arr_members_value.push(postData.value)
        })
                
        console.log(event_project_name)
        console.log(event_name)
        console.log(event_desc)
        console.log(arr_members_value)

        // console.log(event_startDate)
        console.log(start_date)
        console.log(get_start_time)
        // console.log(event_endDate)
        console.log(end_date)
        console.log(get_end_time)
    
        axios_config.post("/event/", {
    
          projectid: event_project_name,
          event: event_name,
          eventdescription: event_desc,
          eventtime: get_start_time,
          eventendtime: get_end_time,
          eventdate: start_date,
          eventenddate: end_date,
          membersid: arr_members_value,
    
        }
        ).then(response => {
          console.log("resp create event", response);

          if (response.data.success == "true") {
            console.log("Add Event Successfully");
            alert("Add Event Successfully")
            this.setState({
              event_create_in: true,
              loading: false
    
            })
    
          } else {
            console.log("False");
          }
        })
          .catch(error => {
            console.log("login error", error);
          });
    
      }


      async getTodayEvent() {

        let auth_id = localStorage.getItem("authid")
        const res = await axios_config.post('todo-list/today/', { memberid: auth_id, })
        const today_event_data = res.data.data != null ? res.data?.data.eventdata: '';
    
        const options = today_event_data ? today_event_data.map(item => (
    
          {
            "event_name": item["eventname"],
            "event_time": item["event_time"],
            "event_date": item["event_date"],
          })) : "";
        this.setState({ get_today_event: options })
       }
    componentDidMount() {     
    
        
        this.getProjectOptions() ;
        this.getTodayEvent()
        this.getEventMembersOptions()
    }


    toggle = () => {
        const { modal } = this.state;
        this.setState({
            modal: !modal
        });
    };
 

    render(){
      if(this.state.loading){
        return<Loader
        style={{ position: "fixed", top: "50%", left: "60%", transform: "translate(-50%, -50%)"}}
        type="Circles"
        color="#00BFFF" 
        height={100}
        width={100}
        timeout={9000} //3 secs
      />
      }
              if(this.state.event_create_in){
          return <Redirect to ="/teams"> </Redirect>
        }
        const { modal } = this.state;


        
        return <div> 
        <div className="text-center add-eventbtn">
            <Button outline color="primary" block size="lg" block onClick={this.toggle}><span class="popup-form"><i class="bx bx-plus"></i>Add a new event</span></Button>       
        </div>    
        <Modal isOpen={modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Create new schedule{this.props.para}</ModalHeader>
          <ModalBody>
            <Form>
            <FormGroup row>    
                  <Col sm={12}>  
                        <Select
                            options={this.state.selectProjectNameOptions}
                            onChange={this.handleEventProjectNameChange}
                            classNamePrefix="select2-selection"
                            placeholder='Assign to a project'
                            validate={{
                              required: { value: true },
                            }}
                          />
                    </Col>
                </FormGroup>

                <FormGroup row>                
                    <Col sm={12}>
                        <input
                      name="event_name"
                      type="text"
                      errorMessage="Invalid name"
                      validate={{
                        required: { value: true },
                      }}
                      className="form-control"
                      placeholder="Enter Event Name..."
                      value={this.state.event_name}
                      onChange={this.handleValueChange}

                        />
                    </Col>
                </FormGroup>
                <br/>
                <FormGroup row>                
                    <Col sm={12}>
                        <Editor
                            placeholder="Schedule description"
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            toolbar={{
                                options: ['inline',  'history', "emoji"],
                                inline: { inDropdown: false },                            
                                history: { inDropdown: true },
                                emoji:{ inDropdown: true}
                            }}
                            name='task_desc'
                            value={this.state.task_desc}
                            onChange={this.handleEventDescChange}
                          />                    
                    </Col>
                </FormGroup>
    
                <br/>
                <FormGroup row>                
                    <Col sm={12}>
                    <Select
                            options={this.state.selectTaskMemberNameOptions}
                              onChange={this.handleEventMemberChange}
                              isMulti
                              classNamePrefix="select2-selection"
                              placeholder='Invite people to that schedule'
                            />
                    </Col>
                </FormGroup>
                <br/>
                <FormGroup row>                
                    <Col sm={3}>            
                        <InputGroup>
                        <Flatpickr
                                  className="form-control d-block"
                                  placeholder="Starting date"
                                  name="event_startDate"
                                  className="form-control d-block"
                                  value={this.state.event_startDate}
                                  onChange={this.handleEventStartDateChange}
                                  options={{
                                    altInput: true,
                                    altFormat: "F j, Y",
                                    dateFormat: "Y-m-d"
                                  }}

                                />
                        </InputGroup>                      
                    </Col>
                    <Col sm={3}>
                        <InputGroup>
                            <Flatpickr
                            className="form-control d-block"
                            placeholder="Starting time"
                            name="event_startTime"
                            value={this.state.event_startTime}
                            onChange={this.handleEventTimeStartChange}
                            options={{
                                enableTime: true,
                                noCalendar: true,
                                dateFormat: "H:i"
                            }}
                            />
                            <div className="input-group-append">
                            <span className="input-group-text">
                                <i className="mdi mdi-clock-outline" />
                            </span>
                            </div>
                        </InputGroup>                  
                    </Col>
                    <Col sm={3}>
                    <InputGroup>
                    <Flatpickr
                                  className="form-control d-block"
                                  placeholder="Ending  date"
                                  name="event_endDate"
                                  className="form-control d-block"
                                  value={this.state.event_endDate}
                                  onChange={this.handleEventDateEndChange}
                                  options={{
                                    altInput: true,
                                    altFormat: "F j, Y",
                                    dateFormat: "Y-m-d"
                                  }}

                                />
                        </InputGroup>                       
                    </Col>
                    <Col sm={3}>
                    <InputGroup>
                            <Flatpickr
                            className="form-control d-block"
                            placeholder="Ending time"
                            name="event_endTime"
                            className="form-control d-block"
                            value={this.state.event_endTime}
                            onChange={this.handleEventTimeEndChange}
                            options={{
                                enableTime: true,
                                noCalendar: true,
                                dateFormat: "H:i"
                            }}
                            />
                            <div className="input-group-append">
                            <span className="input-group-text">
                                <i className="mdi mdi-clock-outline" />
                            </span>
                            </div>
                        </InputGroup>                     
                    </Col>
                </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.handleValidEventSubmit}>Done</Button>{' '}        
          </ModalFooter>
        </Modal>
      </div>
    }
    
}
