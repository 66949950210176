import React, { Component } from "react"

import MetaTags from 'react-meta-tags';

import Select from "react-select"
import Loader from "react-loader-spinner";

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Progress,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Collapse,
  Container,
  FormGroup,
  Nav,
  NavItem,
  NavLink,
  Input,
  TabContent,
  TabPane,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap"

import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { Link, Redirect, withRouter} from "react-router-dom"

//Lightbox
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import ModalVideo from "react-modal-video"
import "react-modal-video/scss/modal-video.scss"

import { SketchPicker } from "react-color"
import ColorPicker from "@vtaits/react-color-picker"
import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
import Switch from "react-switch"
import makeAnimated from "react-select/animated"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

import axios_config from "../../helpers/axios_config"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import DashboardEventBlock from './event/DashboardEventBlock';


import classnames from "classnames"

const option_project_priority_group = [
  {
    options: [
      { label: "Low", value: 0 },
      { label: "Medium", value: 1 },
      { label: "High", value: 2 }
    ]
  },

]

const option_task_type = [
  {
    options: [
      { label: "Parent", value: 0 },
      { label: "Subtask", value: 1 },

    ]
  },

]

class Teams extends Component {
  constructor(props) {
    super(props)
    let create_project = false
    let create_task = false

    this.state = {

      modal_standard: false,
      modal_project: false,
      modal_task: false,
      activeTab: "1",
      activeTab1: "5",
      activeTab2: "9",
      activeTab3: "13",
      verticalActiveTab: "1",
      verticalActiveTabWithIcon: "1",
      customActiveTab: "1",
      customIconActiveTab: "1",
      activeTabJustify: "5",
      col1: true,
      col2: false,
      col3: false,
      col5: true,
      col6: true,
      col7: true,
      col8: true,
      col9: true,
      col10: false,
      col11: false,
      selectTaskOptions: [],
      selectProjectNameOptions: [],
      selectTodoPrtojectOptions: [],
      selectTodoTaskOptions: [],
      selectDoneTaskOptions: [],
      selectTodoEventOptions: [],
      //Add project Details 
      project_members_add: [],
      project_name: '',
      project_desc: '',
      project_task_priority: '',
      project_startDate: '',
      project_dueDate: '',
      project_team_name: "",
      project_team_owner: "",
      status: '',
      project_progress: '',
      create_project,

      // Create Task
      task_milestone_name: "",
      task_name: "",
      task_type: "",
      task_parent: "",
	    task_progress:'',
      task_desc: "",
      task_priority: "",
      task_members: [],
      task_duedate: "",
      task_startDate: "",
      selectedFiles: null,
      projects: [],
      image: null,
      create_task,

      //Single Team
      single_team: '',
      loading: false,

      selectTeamMilestoneOptions:''

    }
    this.tog_standard = this.tog_standard.bind(this)
    this.tog_project = this.tog_project.bind(this)
    this.tog_task = this.tog_task.bind(this)

    //Create Project Handling
    this.projectFormSubmit = this.projectFormSubmit.bind(this)
    this.handleProjectPriorityGroup = this.handleProjectPriorityGroup.bind(this)
    this.handleTeamNameChange = this.handleTeamNameChange.bind(this);
    this.handleTeamOwnerChange = this.handleTeamOwnerChange.bind(this);
    this.handleMemberChange = this.handleMemberChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleProjectDescChange = this.handleProjectDescChange.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleDueDateChange = this.handleDueDateChange.bind(this);

    //Create Task Handling
    this.handleTaskMilestoneNameChange = this.handleTaskMilestoneNameChange.bind(this)
    this.handleTaskMemberChange = this.handleTaskMemberChange.bind(this);
    this.onFileChange = this.onFileChange.bind(this)
    this.handleTeamDescChange = this.handleTeamDescChange.bind(this);
    this.handleTaskStartDateChange = this.handleTaskStartDateChange.bind(this);
    this.handleTaskDueDateChange = this.handleTaskDueDateChange.bind(this);
    this.handleTaskSelectGroup = this.handleTaskSelectGroup.bind(this)
    this.handleTaskNameChange = this.handleTaskNameChange.bind(this)
    this.taskFormSubmit = this.taskFormSubmit.bind(this)
    this.handleTaskTypeSelectGroup = this.handleTaskTypeSelectGroup.bind(this)


  }
  //Create Task Handling
  onFileChange = event => {

    this.setState({ selectedFiles: event.target.files[0] });

  };

  handleTeamDescChange = (event) => {
    this.setState({ task_desc: event.blocks[0].text })

  };

  handleTaskStartDateChange = (event) => {
    this.setState({ task_startDate: event[0] })

  };

  handleTaskDueDateChange = (event) => {
    this.setState({ task_duedate: event[0] })

  };

  handleTaskSelectGroup = priority_sel_event => {
    this.setState({ task_priority: priority_sel_event.value })
  }
  handleTaskNameChange(event) {

    this.setState({ [event.target.name]: event.target.value })
  }

  handleTaskTypeSelectGroup = task_type_event => {
    this.setState({ task_type: task_type_event.value })
  }

  async getMilestoneOptions() {
    const res = await axios_config.post('/all-milestone/')
    const data = res.data.data

    const options = data ? data.map(item => ({
      "value": item["milestone id"],
      "label": item["name"]
    })) : "";
    console.log("milestone_name_options", options);
    this.setState({ selectTeamMilestoneOptions: options })
  }

  handleTaskMilestoneNameChange(e) {
    this.setState({ task_milestone_name: e.value })

  }

  async getTaskMembersOptions() {
    
    const response = await axios_config.post('/all-users/', { usertype: 3 })
    const data = response.data.data
    const options = data.map(d => ({
      "value": d["user id"],
      "label": d["user firstname"]
    }))
    this.setState({ selectTaskMemberNameOptions: options })
  }

  handleTaskMemberChange(event) {
    this.setState({ task_members: event })

  }

  taskFormSubmit(event) {

    event.preventDefault();
    this.setState({
      loading: true,
      modal_task:false,
      task_milestone_name:"",
      task_name:"",
      task_type:"",
      task_parent:"",
      task_desc:"",
      task_progress:"",
      task_priority:"",
      task_members:"",
      task_startDate:"",
      task_duedate:"",
      selectedFiles:"",
    })

    const { task_milestone_name,  task_name, task_type, task_parent, task_desc,task_progress, task_priority, task_members, task_startDate,
      task_duedate, selectedFiles } = this.state;

    function convert(str) {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day,].join("-");
    }

    let start_date = convert(task_startDate)
    let due_date = convert(task_duedate)
    let update_metakey = selectedFiles.name
    let metavalue = selectedFiles.type

    var arr_members_value = [];
    this.state.task_members.map((postData) => {
      arr_members_value.push(postData.value)
    })

    console.log(task_milestone_name)
    console.log(task_name)
    console.log(task_type)
    console.log(task_parent)
	  console.log(task_progress)
    console.log(task_desc)
    console.log(task_priority)
    console.log(arr_members_value)
    console.log(start_date)
    console.log(due_date)
    console.log(update_metakey)
    console.log(metavalue)
    console.log(selectedFiles)
    // console.log(status)

    let bodyFormData = new FormData();

    bodyFormData.append("Milestoneid", task_milestone_name);
    bodyFormData.append("task", task_name);
    bodyFormData.append("tasktype", task_type);
    bodyFormData.append("taskparent", 0);
	  bodyFormData.append("progress", 0);
    bodyFormData.append("taskdescription", task_desc);
    bodyFormData.append("priority", task_priority);
    bodyFormData.append("membersid", arr_members_value);
    bodyFormData.append("metakey", update_metakey);
    bodyFormData.append("metavalue", selectedFiles);
    bodyFormData.append("start_date", start_date);
    bodyFormData.append("due_date", due_date);
    bodyFormData.append("status", 2);

    axios_config({
      method: "post",
      url: "/task/",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then(response => {
      console.log("resp create task", response);
      if (response.data.success == "true") {
        console.log("Add Task Successfully");
        alert("Added Task Successfully")
        this.getTaskNameOptions()
        this.setState({
          create_task: true,
          loading: false

        })

      } else {
		  var msg = response.data.msg
        alert(msg)
        this.getTaskNameOptions()
        this.setState({
          create_task: true,
          loading: false

        })
      }
    })
      .catch(error => {
        console.log("login error", error);
      });
  }

  //End Create Task Handling

  // Add Project Handlings
  handleProjectDescChange = (event) => {
    this.setState({ project_desc: event.blocks[0].text })

  };

  handleStartDateChange = (event) => {
    this.setState({ project_startDate: event[0] })

  };

  handleDueDateChange = (event) => {
    this.setState({ project_dueDate: event[0] })

  };

  handleProjectPriorityGroup = priority_sel_event => {
    this.setState({ project_task_priority: priority_sel_event.value })
  }

  handleNameChange(event) {

    this.setState({ [event.target.name]: event.target.value })

  }

  async getMemberOptions() {

    const response = await axios_config.post('/all-users/', { usertype: 3 })
    const data = response.data.data
    //  console.log("multi resp data", data);
    const options = data.map(item => ({
      "value": item["user id"],
      "label": item["user firstname"]
    }))
    console.log("multi resp options", options);
    this.setState({ selectOptions: options })
  }
  handleMemberChange(e) {
    this.setState({ project_members_add: e })

  }

  async getTeamOwnerOptions() {
    const response = await axios_config.post('/all-users/', { usertype: 3 })
    const data = response.data.data
    const options = data.map(d => ({
      "value": d["user id"],
      "label": d["user firstname"]
    }))
    console.log("team owner options", options);
    this.setState({ selectTeamOwnerOptions: options })
  }
  handleTeamOwnerChange(e) {
    this.setState({ project_team_owner: e.value })

  }


  async getTeamNameOptions() {
    const res = await axios_config.post('/all-teams/')
    const data = res.data.data
    // console.log (res)

    const options = data.map(item => ({
      "value": item["Team id"],
      "label": item["Team Name"]
    }))
    console.log("team name options", options);
    this.setState({ selectTeamNameOptions: options })
  }

  handleTeamNameChange(e) {
    this.setState({ project_team_name: e.value })

  }

  projectFormSubmit(event) {
    event.preventDefault();
    this.setState({
      loading: true,
      project_name:'',
      project_desc:'',
      project_startDate:'',
      project_dueDate:'',
      project_team_name:'',
      project_team_owner:'',
      project_members_add:'',
      project_task_priority:'',
      project_progress:'',
      status:'',
      modal_standard:false
    })
    let para_id = this.props.match.params.id
    console.log('submit project form')
    const { project_name, project_desc, project_startDate, project_dueDate, project_team_name, project_team_owner, project_members_add, project_task_priority, project_progress, status } = this.state;

    function convert(str) {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
    }
    let statuss = 1
    let start_date = convert(project_startDate)
    let due_date = convert(project_dueDate)
    var arr_members_value = [];
    this.state.project_members_add.map((postData) => {
      arr_members_value.push(postData.value)
    })

    axios_config.post("/project/", {

      ProjectName: project_name,
      Projectdescription: project_desc,
      startdate: start_date,
      duedate: due_date,
      Teamid: para_id,
      Teamowner: project_team_owner,
      MembersId: arr_members_value,
      priority: project_task_priority,
      progress: 0,
      status: 2,

    }
    ).then(response => {
      console.log("resp create project", response);
      if (response.data.success == "true") {
        console.log("Add project Successfully");
        alert("Add Project Successfully")
        this.getProject()
        this.setState({
          create_project: true,
          loading: false,
        })

      } else {
        var msg = response.data.msg
        alert(msg)
        console.log(msg);
        this.getProject()
        this.setState({
          create_project: true,
          loading: false,
        })
      }
    })
      .catch(error => {
        console.log("login error", error);
      });

  }

  // End Add Project Handlings

  toggleCustom(tab) {
    if (this.state.customActiveTab !== tab) {
      this.setState({
        customActiveTab: tab,
      })
    }
  }

  toggleIconCustom(tab) {
    if (this.state.customIconActiveTab !== tab) {
      this.setState({
        customIconActiveTab: tab,
      })
    }
  }
  tog_standard() {
    this.setState(prevState => ({
      modal_standard: !prevState.modal_standard,
    }))
    this.removeBodyCss()
  }
  tog_project() {
    this.setState(prevState => ({
      modal_project: !prevState.modal_project,
    }))
    this.removeBodyCss()
  }

  async getTaskNameOptions() {

    var para_id = this.props.match.params.id
    // console.log('task Paraa id', para_id)

    const result = await axios_config.post('all-task/', { teamid: para_id, })
    // console.log('all-task datas show', result)

    const task_data = result.data.data
    // console.log('all-task datassssss show', task_data)

    const options = task_data ? task_data.map(item => (

      {
        "task_team_name": item["Team name"],
        "task_project_data": item["Project data"],
        

      })) : "";

    // console.log("taskoptions", options);
    this.setState({ selectTaskOptions: options })


  }

  async getProject() {
    var para_id = this.props.match.params.id
    console.log(para_id)
    const res = await axios_config.post('all-projects/', { teamid: para_id, })
    // console.log('project', res)
    const project_data = res.data.data
    // console.log('show get projects', project_data)

    const options = project_data ? project_data.map(item => (

      {
        "project_id": item["project id"],
        "project_team_id": item["Team id"],
        "project_name": item["Project Name"],
        "project_desc": item["Project description"],
        "project_startdate": item["duedate"],
        "project_duedate": item["duedate"],
        "project_priority": item["priority"],
        "project_uname": item["Project Members"] ? item["Project Members"].map(function (el) { return el['member username']; }).join(", ") : "",
        "project_members": item["Project Members"],
        "project_status": item["status"],
        "project_progress": item["progress"],
      })) : "";
    // console.log("project data options", options);
    this.setState({ selectProjectNameOptions: options })
  }

  //Get Todo_List
  async getTodoList() {
    let auth_id = localStorage.getItem("authid")

    const res = await axios_config.post('todo-list/', { memberid: auth_id, })

    //Display Task
    const task_todo_data = res.data?.data != null ?  res.data?.data.taskdata : '';
    

    const todo_task_options = task_todo_data ? task_todo_data.map(item => ({
      "todo_task_name": item["taskname"],
      "todo_task_duedate": item["duedate"],
      "todo_task_status": item["status"],
      // "todo_project_teamname": item["teamname"],

    })) : "";

    this.setState({ selectTodoTaskOptions: todo_task_options })

    //Display Project
    const project_todo_data = res.data?.data != null ? res.data?.data.projectdata: '';

    const todo_project_options = project_todo_data ? project_todo_data.map(item => ({
      "todo_project_name": item["projectname"],
      "todo_project_duedate": item["duedate"],
      "todo_project_teamname": item["teamname"],

    })) : "";

    this.setState({ selectTodoPrtojectOptions: todo_project_options })

    //Display Event
    const event_todo_data = res.data.data != null ? res.data?.data.eventdata: '';

    const todo_event_options = event_todo_data ? event_todo_data.map(item => ({
      "todo_event_name": item["eventname"],
      "todo_event_time": item["event_time"],
      "todo_event_date": item["event_date"],

    })) : "";
    this.setState({ selectTodoEventOptions: todo_event_options })
  }

  //Get Single Team
  async getSingleTeam() {

    let para_id = this.props.match.params.id
      const response = await axios_config.post('/single-team/', { teamid: para_id })
      console.log('resssss', response)
      const data = response.data?.data != null ? response.data?.data['Team Name'] : '';
      this.setState({ single_team: data })
  }

  componentDidMount() {

    //Get All Task
    this.getTaskNameOptions()

    this.getProject()
    this.getTodoList()
    this.getTeamNameOptions()
    this.getTeamOwnerOptions()
    this.getMemberOptions()
    this.getMilestoneOptions()
    this.getTaskMembersOptions()

    //Get Single Team
    this.getSingleTeam()

  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.getProject();
      this.getTaskNameOptions()
      this.getSingleTeam()
    }

  }

  tog_task() {
    this.setState(prevState => ({
      modal_task: !prevState.modal_task,
    }))
    this.removeBodyCss()
  }
  removeBodyCss() {
    document.body.classList.add("no_padding")
  }



  render() {
    const { events, categories } = this.props
    const { isEdit, deleteModal } = this.state

    if (this.state.loading) {
      return <Loader
        style={{ position: "fixed", top: "50%", left: "60%", transform: "translate(-50%, -50%)" }}
        type="Circles"
        color="#00BFFF"
        height={100}
        width={100}
        timeout={9000} //3 secs
      />
    }

    const single_tname = this.state.single_team;
    // if (this.state.create_project) {
    //   return <Redirect to="/teams"> </Redirect>
    // }
    // if (this.state.create_task) {
    //   return <Redirect to="/teams"> </Redirect>
    // }
    var donecount = 1
    return (
      <React.Fragment>


        <div className="page-content">
          <MetaTags>
            <title>Astrac</title>
          </MetaTags>
          <div className="container-fluid">
            <Row className="rowone">

              <Col md={6}>
                <Card className="Project-card">
                  <CardBody>
                    <CardTitle className="h4">Project</CardTitle>


                    <div className="table-responsive h-100" data-simplebar  >
                      <Table className="table mb-0 ">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Asignees</th>
                            <th>Due Date</th>
                            <th>Priority</th>
                            <th>Progress</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.state.selectProjectNameOptions ? this.state.selectProjectNameOptions.map((item, indexx) =>
                              <tr key={indexx}>
                                <th scope="row">{indexx + 1}</th>
                                <td>{item.project_name}</td>
                                <td><div class="avatar-group">
                                  <div class="avatar-group-item">
                                    <a class="d-inline-block" href="/tasks-list"><div class="avatar-xs"><span class="avatar-title rounded-circle bg-success text-white font-size-16">

                                      {item.project_uname}

                                    </span></div></a>
                                  </div>
                                </div>
                                </td>
                                <td><span class="date-text"><i className="fas fa-calendar-alt"></i> {item.project_duedate}</span></td>
                                <td><span class={item.project_priority == 0 ? 'Low-text' : (item.project_priority == 1 ? "Medium-text" : "High-text")}><i class={item.project_priority == 0 ? 'dripicons-arrow-thin-down' : (item.project_priority == 1 ? "dripicons-arrow-thin-right" : "dripicons-arrow-thin-up")}></i>{item.project_priority == 0 ? 'Low' : (item.project_priority == 1 ? "Medium" : "High")}</span></td>
                                <td>  <div className="">
                                  <Progress color="primary" >
                                    <span class="percentage-txt">{item.project_progress}%</span>
                                  </Progress>
                                </div></td>
                                <td><span class={item.status == 0 ? "font-size-12 badge-soft-success badge badge-success badge-pill done-color" : (item.status == 1 ? "font-size-12 badge-soft-success badge badge-success badge-pill progress-color" : "font-size-12 badge-soft-success badge badge-success badge-pill todo-color")}>{item.status == 0 ? 'Done' : (item.status == 1 ? 'Progrress' : 'To do')}</span></td>
                              </tr>
                            ) : " No Record Found "}



                        </tbody>
                      </Table>
                      <div className="add-project">

                        <button
                          type="button"
                          onClick={this.tog_standard}
                          className="btn btn-primary"
                          data-toggle="modal"
                          data-target="#myModal"
                        >
                          <span class="popup-form"><i class="bx bx-plus"></i> Add a new Project</span>
                        </button>

                        <Modal
                          isOpen={this.state.modal_standard}
                          toggle={this.tog_standard}
                          centered={true}
                        >
                          <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myModalLabel">
                              <span class="popup-head"><i class="bx bx-plus"></i> Create a new Project</span>
                            </h5>
                            <button
                              type="submit"
                              className="btn btn-primary right-align"
                              onClick={this.projectFormSubmit}
                            >
                              Done

                            </button>
                            <button
                              type="button"
                              onClick={() =>
                                this.setState({ modal_standard: false })
                              }
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <Row>
                              <Col lg={9}>
                                <div className="mb-3">
                                  <input
                                    name="project_name"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Project Name..."
                                    value={this.state.project_name}
                                    onChange={this.handleNameChange}
                                  />
                                </div>
                              </Col>
                              <Col lg={3}>
                                <div className="mb-3">
                                  <button
                                    type="submit"
                                    className="btn btn-primary team-btn"
                                  >
                                    <span>A</span>{single_tname ? single_tname : 'No Team'}
                                  </button>
                                </div>
                              </Col>
                            </Row>
                            <FormGroup row>
                              <Col sm={12}>
                                <Editor
                                  placeholder="Schedule description"
                                  toolbarClassName="toolbarClassName"
                                  wrapperClassName="wrapperClassName"
                                  editorClassName="editorClassName"
                                  toolbar={{
                                    options: ['inline', 'history', "emoji"],
                                    inline: { inDropdown: false },
                                    history: { inDropdown: true },
                                    emoji: { inDropdown: true }
                                  }}
                                  name='project_desc'
                                  value={this.state.project_desc}
                                  onChange={this.handleProjectDescChange}
                                />
                              </Col>
                            </FormGroup>
                            <Row>
                              <Col lg={6}>
                                <div className="input-group date-pick">
                                  <Flatpickr
                                    name="project_startDate"
                                    className="form-control d-block"
                                    placeholder="Starting date"
                                    value={this.state.project_startDate}
                                    onChange={this.handleStartDateChange}
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d"
                                    }}

                                  />
                                  <span className="date-icon"><i class="bx bx-calendar"></i></span>
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="input-group date-pick">
                                  <Flatpickr
                                    name="project_dueDate"
                                    className="form-control d-block"
                                    placeholder="Due date"
                                    value={this.state.project_dueDate}
                                    onChange={this.handleDueDateChange}
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d"
                                    }}

                                  />
                                  <span className="date-icon"><i class="bx bx-calendar"></i></span>
                                </div>
                              </Col>
{/* 
                              <Col lg={4}>
                                <Select
                                  options={this.state.selectTeamNameOptions}
                                  onChange={this.handleTeamNameChange}
                                  classNamePrefix="select2-selection"
                                  validate={{
                                    required: { value: true },

                                  }}
                                  placeholder='Select Team'
                                />
                              </Col> */}
                            </Row>
                              <br/>
                            <Row>
                              <Col lg={4}>
                                <Select
                                  options={this.state.selectTeamOwnerOptions}
                                  onChange={this.handleTeamOwnerChange}
                                  classNamePrefix="select2-selection"
                                  validate={{
                                    required: { value: true },
                                  }}
                                  placeholder='Add Team Owner'
                                />
                              </Col>
                              <Col lg={4}>
                                <Select
                                  options={this.state.selectOptions}
                                  isMulti
                                  onChange={this.handleMemberChange}
                                  classNamePrefix="select2-selection"
                                  validate={{
                                    required: { value: true },
                                  }}
                                  placeholder='Add Members'
                                />
                              </Col>

                              <Col lg={4}>
                                <Select
                                  onChange={this.handleProjectPriorityGroup}
                                  options={option_project_priority_group}
                                  classNamePrefix="select2-selection"
                                  validate={{
                                    required: { value: true },

                                  }}
                                  placeholder='Select Priority'
                                />
                                <br/>
                              </Col>
                              {/* <Col lg={12}>
                                <div className="mb-3">
                                  <input
                                    name="project_progress"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Progress Value..."
                                    value={this.state.project_progress}
                                    onChange={this.handleNameChange}
                                  />
                                </div>
                              </Col> */}
                            </Row>
                          </div>
                          <div className="modal-footer ftr-none">

                          </div>
                        </Modal>

                      </div>

                    </div>
                  </CardBody>
                </Card>
              </Col>


              <Col md={6}>
                <Card className="mytask-card">
                  <CardBody>
                    <CardTitle className="h4">My Task</CardTitle>


                    <Nav tabs className="nav-tabs-custom nav-justified">
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.customActiveTab === "1",
                          })}
                          onClick={() => {
                            this.toggleCustom("1")
                          }}
                        >
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                          <span className="d-none d-sm-block">To Do</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.customActiveTab === "2",
                          })}
                          onClick={() => {
                            this.toggleCustom("2")
                          }}
                        >
                          <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                          <span className="d-none d-sm-block">Comments</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.customActiveTab === "3",
                          })}
                          onClick={() => {
                            this.toggleCustom("3")
                          }}
                        >
                          <span className="d-block d-sm-none"><i className="far fa-envelope"></i></span>
                          <span className="d-none d-sm-block">Done</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.customActiveTab === "4",
                          })}
                          onClick={() => {
                            this.toggleCustom("4")
                          }}
                        >
                          <span className="d-block d-sm-none"><i className="fas fa-cog"></i></span>
                          <span className="d-none d-sm-block">@mention</span>
                        </NavLink>
                      </NavItem>
                    </Nav>

                    <TabContent activeTab={this.state.customActiveTab} className="p-3 text-muted">
                      <TabPane tabId="1">
                        <Row>
                          <Col sm="12">
                            <CardText className="mb-0">
                              <div className="table-responsive">
                                <Table className="table mb-0">
                                  <thead>
                                    <tr>
                                      <th>#</th>
                                      <th>Tasks</th>
                                      <th>Status</th>
                                      <th>Due Date</th>
                                      <th>More</th>

                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.selectTodoTaskOptions ? this.state.selectTodoTaskOptions.map((item, taskindex) => {
                                      if (item.todo_task_status == 0) {
                                        return (
                                          <tr className="blue-color" key={taskindex}>
                                            <th scope="row">{taskindex + 1}</th>
                                            <td>{item.todo_task_name}</td>
                                            <td><span class="font-size-12 badge-soft-success badge badge-success badge-pill todo-color">To Do</span></td>
                                            <td><span class="date-text"><i className="fas fa-calendar-alt"></i>{item.todo_task_duedate}</span></td>

                                            <td className="dotmore">....</td>
                                          </tr>
                                        )
                                      }
                                    }

                                    ) : " No Record Found "}
                                  </tbody>
                                </Table>
                              </div>
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="2">
                        <Row>
                          <Col sm="12">
                            <CardText className="mb-0">
                              Comments
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="3">
                        <Row>
                          <Col sm="12">
                            <CardText className="mb-0">
                              <div className="table-responsive">
                                <Table className="table mb-0">
                                  <thead>
                                    <tr>
                                      <th>#</th>
                                      <th>Tasks</th>
                                      <th>Status</th>
                                      <th>Due Date</th>
                                      <th>More</th>

                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.selectTodoTaskOptions ? this.state.selectTodoTaskOptions.map((item, taskindexs) => {
                                      if (item.todo_task_status == 1) {
                                        return (
                                          <tr className="blue-color" key={taskindexs}>
                                            <th scope="row">{donecount++}</th>
                                            <td>{item.todo_task_name}</td>
                                            <td><span class="font-size-12 badge-soft-success badge badge-success badge-pill done-color">Done</span></td>
                                            <td><span class="date-text"><i className="fas fa-calendar-alt"></i>{item.todo_task_duedate}</span></td>

                                            <td className="dotmore">....</td>
                                          </tr>
                                        )
                                      }
                                    }

                                    ) : " No Record Found "}
                                  </tbody>
                                </Table>
                              </div>
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="4">
                        <Row>
                          <Col sm="12">
                            <CardText className="mb-0">
                              @mention
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                    <div className="add-task">
                      <div className="checkbox-txt">
                        <input type="checkbox" className="form-control popup-checkbox" /><span><input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="Type new task and press enter"
                        /></span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>

            </Row>

            <Row className="rowtwo">
              <Col md={6}>
                <Card className="teamtask-card">
                  <CardBody>
                    <CardTitle className="h4">Team Task</CardTitle>


                    <div className="table-responsive">
                      <Table className="table mb-0">
                        <thead>
                          <tr>
                            <th>Today (1)</th>
                            <th>Status</th>
                            <th>Asignees</th>
                            <th>Due Date</th>
                            <th>Priority</th>
                            <th>More</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.selectTaskOptions ? this.state.selectTaskOptions.map((item, indexx) =>
                          (<>
                            {
                              item.task_project_data ? item.task_project_data.map((data) => (
                                <>
                                  {data['Milestone data'] ? data['Milestone data'].map((item_mile) => (<>

                                    {item_mile['Taskdata'] ? item_mile['Taskdata'].map((item_task) => 
                                      
                                        
                                        <tr>
                                          <td className="sml-text">
                                            {item.task_team_name}| {data['Project Name']}| {item_mile['Mile Name']}| {item_task['Name']}
                                          </td>
                                          <td><span class={item_task.status == 0 ? 'font-size-12 badge-soft-success badge badge-success badge-pill progess-color' : "font-size-12 badge-soft-success badge badge-success badge-pill done-color"}>{item_task.status == 0 ? 'Progress' : "Done"}</span></td>
                                          <td>
                                            <div class="avatar-group">
                                              <div class="avatar-group-item">
                                                <a class="d-inline-block" href="/tasks-list">
                                                  <div class="avatar-xs">
                                                    <span class="avatar-title rounded-circle bg-success text-white font-size-16">
                                                      {item_task['Member Details'].map((item_member) => (<>{item_member['member name']}</>))}
                                                    </span>
                                                  </div>
                                                </a>
                                              </div>
                                            </div>
                                          </td>
                                          <td><span class="date-text"><i className="fas fa-calendar-alt"></i> {item_task['due date']}</span></td>
                                          <td><span class={item_task['priority'] == 0 ? 'Low-text' : (item_task['priority'] == 1 ? "Medium-text" : "High-text")}><i class={item_task['priority'] == 0 ? 'dripicons-arrow-thin-down' : (item_task['priority'] == 1 ? "dripicons-arrow-thin-right" : "dripicons-arrow-thin-up")}></i>{item_task['priority'] == 0 ? 'Low' : (item_task['priority'] == 1 ? "Medium" : "High")}</span></td>
                                        </tr>
                                      

                                    ) : 'No Record Founding'}


                                  </>)) : 'No Record Founder'}

                                </>)) : 'No Record Found'}

                          </>)) : " No Record Found"}
                          {/* <tr>
                            <td className="sml-text"><span>Team A Project Tittle A milestone 1</span>
                              Update the progress to the team</td>
                            <td><span class="font-size-12 badge-soft-success badge badge-success badge-pill progess-color">Progress</span></td>
                            <td>
                              <div class="avatar-group">
                                <div class="avatar-group-item">
                                  <a class="d-inline-block" href="/tasks-list"><div class="avatar-xs"><span class="avatar-title rounded-circle bg-success text-white font-size-16">S</span></div></a>
                                </div>
                                <div class="avatar-group-item">
                                  <a class="d-inline-block" href="/tasks-list"><div class="avatar-xs"><span class="avatar-title rounded-circle bg-secondary text-white font-size-16">M</span></div></a>
                                </div>
                                <div class="avatar-group-item plus-avatar-group">
                                  <a class="d-inline-block" href="/tasks-list"><div class="avatar-xs"><span class="avatar-title rounded-circle bg-secondary text-white font-size-16"><i class="bx bx-plus"></i></span></div></a>
                                </div>

                              </div></td>
                            <td><span class="date-text"><i className="fas fa-calendar-alt"></i>15 Oct 2019</span></td>
                            <td><span class="High-text"><i class="dripicons-arrow-thin-up"></i>High</span></td>

                            <td className="dotmore">...</td>
                          </tr> */}
                        </tbody>
                      </Table>
                      <div className="add-teamtask">

                        <button
                          type="button"
                          onClick={this.tog_task}
                          className="btn btn-primary"
                          data-toggle="modal"
                          data-target="#myModal"
                        >
                          <span class="popup-form"><i class="bx bx-plus"></i> Add a new Task</span>
                        </button>
                      </div>

                      <Modal
                        isOpen={this.state.modal_task}
                        toggle={this.tog_task}
                        centered={true}
                      >
                        <div className="modal-header">
                          <h5 className="modal-title mt-0" id="myModalLabel">
                            <span class="popup-head"><i class="bx bx-plus"></i> Create a new Task</span>
                          </h5>
                          <button
                            type="button"
                            onClick={() =>
                              this.setState({ modal_task: false })
                            }
                            className="close task-close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <Row>
                            <Col lg={12}>
                              <div className="mb-3">
                                <Select
                                  options={this.state.selectTeamMilestoneOptions}
                                  onChange={this.handleTaskMilestoneNameChange}
                                  classNamePrefix="select2-selection"
                                  placeholder=" Milestone Name..."
                                />
                              </div>
                            </Col>
                            <FormGroup row>
                              <Col lg={12}>
                                <div className="mb-3">
                                  <input
                                    name="task_name"
                                    type="text"
                                    errorMessage="Invalid name"
                                    validate={{
                                      required: { value: true },
                                    }}
                                    className="form-control"
                                    placeholder="Enter Task Name..."
                                    value={this.state.task_name}
                                    onChange={this.handleTaskNameChange}
                                  />
                                </div>
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Col lg={12}>
                                <div className="mb-3">
                                  <Select
                                    onChange={this.handleTaskTypeSelectGroup}
                                    options={option_task_type}
                                    classNamePrefix="select2-selection"
                                    placeholder="Select Tasktype..."
                                  />
                                </div>
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Col lg={12}>
                                <div className="mb-3">
                                  <input
                                    name="task_parent"
                                    type="text"
                                    errorMessage="Invalid name"
                                    validate={{
                                      required: { value: true },
                                    }}
                                    className="form-control"
                                    placeholder="Add Task Parent Name..."
                                    value={this.state.task_parent}
                                    onChange={this.handleTaskNameChange}
                                  />
                                </div>
                              </Col>
                            </FormGroup>

                          </Row>
                          <FormGroup row>
                            <Col sm={12}>
                              <Editor
                                placeholder="Write task description"
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                toolbar={{
                                  options: ['inline', 'history', "emoji"],
                                  inline: { inDropdown: false },
                                  history: { inDropdown: true },
                                  emoji: { inDropdown: true }
                                }}
                                name='task_desc'
                                value={this.state.task_desc}
                                onChange={this.handleTeamDescChange}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Col lg={12}>
                              <div className="mb-3">

                              </div>
                            </Col>
                          </FormGroup>

                          <Row className="row-mt">
                            <Col lg={6}>
                              <input
                                name="selectedFiles"
                                type="file"
                                errorMessage="Invalid To File"

                                className="form-control"
                                onChange={this.onFileChange}
                                placeholder='Attach Files'
                              />
                            </Col>
                            <Col lg={6}>
                              <Select
                                options={this.state.selectTaskMemberNameOptions}
                                onChange={this.handleTaskMemberChange}
                                isMulti
                                classNamePrefix="select2-selection"
                                placeholder='Assign people to this project'
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={4}>
                              <div className="input-group date-pick">
                                <Flatpickr
                                  className="form-control d-block"
                                  placeholder="Starting date"
                                  name="project_startDate"
                                  className="form-control d-block"
                                  value={this.state.task_startDate}
                                  onChange={this.handleTaskStartDateChange}
                                  options={{
                                    altInput: true,
                                    altFormat: "F j, Y",
                                    dateFormat: "Y-m-d"
                                  }}

                                />
                                <span className="date-icon"><i class="bx bx-calendar"></i></span>
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="input-group date-pick">
                                <Flatpickr
                                  name="task_duedate"
                                  className="form-control d-block"
                                  placeholder="Due date"
                                  value={this.state.task_duedate}
                                  onChange={this.handleTaskDueDateChange}
                                  options={{
                                    altInput: true,
                                    altFormat: "F j, Y",
                                    dateFormat: "Y-m-d"
                                  }}

                                />
                                <span className="date-icon"><i class="bx bx-calendar"></i></span>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <Select
                                onChange={this.handleTaskSelectGroup}
                                options={option_project_priority_group}
                                classNamePrefix="select2-selection"
                                placeholder='Select a priority'
                              />
                            </Col>
							 {/* <FormGroup row>
                            <Col lg={12}>
                              <input
                                    name="task_progress"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Progress Value..."
                                    value={this.state.task_progress}
                                    onChange={this.handleTaskNameChange}
                                  />
                            </Col>
                          </FormGroup> */}
                          </Row>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={this.taskFormSubmit}
                          >
                            Done
                          </button>
                        </div>
                      </Modal>


                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col md={6}>
                <Card className="myevent-card">
                  <CardBody>
                    <CardTitle className="h4">My Events</CardTitle>

                    <div className="add-event">
                      {/* <Route render={() => {
                        return <DashboardEventBlock />
                      }} /> */}
                      <DashboardEventBlock data={this.props.match.params.id} />
                    </div>

                  </CardBody>
                </Card>
              </Col>


            </Row>

          </div>
        </div>
      </React.Fragment>
    )
  }
}



export default withRouter(Teams);