import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux"
import { isEmpty } from "lodash"

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Container,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin, { Draggable } from "@fullcalendar/interaction"
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';

import BootstrapPlugin from "@fullcalendar/bootstrap"
//css
import "@fullcalendar/bootstrap/main.css"
import "./MyEventCalendarStyle.scss"
import moment from "moment";
import  CalendarLeft from './CalendarLeft';
// below export is for testing purpos 
import { events } from './events';

export default  class MyEventCalendar extends Component {
  constructor(props) {
    super(props)
    this.handleDateClick = this.handleDateClick.bind(this)
    this.calendarComponentRef = React.createRef()

    this.state = {
      calendarWeekends: true,
      modal: false,
      modalcategory: false,
      isDragBind: false,
      deleteModal: false,
      event: {},
    }

    this.toggle = this.toggle.bind(this)
    this.togglecategory = this.togglecategory.bind(this)
    this.handleValidEventSubmit = this.handleValidEventSubmit.bind(this)
    this.handleValidEventSubmitcategory = this.handleValidEventSubmitcategory.bind(this)

    // category
    this.onDrag = this.onDrag.bind(this)
    this.onDrop = this.onDrop.bind(this)
  }

  componentDidMount = () => {
   
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    
  }

  /**
   * Handling the modal state
   */
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  togglecategory() {
    this.setState(prevState => ({
      modalcategory: !prevState.modalcategory,
    }))
  }

  /**
   * Handling date click on calendar
   */
  handleDateClick = arg => {
    alert("as")
    this.setState({ selectedDay: arg })
    this.toggle()
  }

  /**
   * Handling click on event on calendar
   */
  handleEventClick = arg => {

    const event = arg.event
    this.setState({
      event: {
        id: event.id,
        title: event.title,
        title_category: event.title_category,
        start: event.start,
        className: event.classNames,
        category: event.classNames[0],
        event_category: event.classNames[0],
      },
      isEdit: true,
    })
    this.toggle()
  }

  /**
   * Handling submit event on event form
   */
  handleValidEventSubmit = (e, values) => {

    const { onAddNewEvent, onUpdateEvent } = this.props
    const { isEdit, event, selectedDay } = this.state
    if (isEdit) {
      const updateEvent = {
        id: event.id,
        title: values.title,
        classNames: values.category + " text-white",
        start: event.start,
      }
      // update event
      onUpdateEvent(updateEvent)
    } else {
      const newEvent = {
        id: Math.floor(Math.random() * 100),
        title: values["title"],
        start: selectedDay ? selectedDay.date : new Date(),
        className: values.category + " text-white",
      }
      // save new event
      onAddNewEvent(newEvent)
    }

    this.setState({ selectedDay: null })
    this.toggle()
  }

  handleValidEventSubmitcategory = (event, values) => {
    const { onAddNewEvent } = this.props

    const newEvent = {
      id: Math.floor(Math.random() * 100),
      title: values["title_category"],
      start: new Date(),
      className: values.event_category ? values.event_category + " text-white" : "bg-danger text-white",  
    }

    // save new event
    onAddNewEvent(newEvent)
    this.togglecategory()
  }

  /**
   * On delete event
   */
  handleDeleteEvent = () => {
    const { onDeleteEvent } = this.props
    const { event } = this.state
    

    onDeleteEvent(event)
    this.setState({ deleteModal: false })
    this.toggle()
  }

  /**
   * On category darg event
   */
  onDrag = (event) => {
    event.preventDefault()
  }

  /**
   * On calendar drop event
   */
  onDrop = event => {
    const { onAddNewEvent } = this.props
    const draggedEl = event.draggedEl
    const newEvent = {
      id: Math.floor(Math.random() * 100),
      title: draggedEl.innerText,
      start: event.date,
      className: draggedEl.className,
    }
    onAddNewEvent(newEvent)
  }

  dayHeaderWeekFormat = (info) =>{
    return <>
              <div className="text-uppercase">{ moment(info.date.marker).format("ddd") }</div>   
              <div className="week-day-head">{ moment(info.date.marker).format("DD") }</div>   
          </>;
  }
  
  render() {
    
    
    return (
      <React.Fragment>
          <div className="full-calendar"> 
            <div className="container-fluid">

              <Row className="project-task-head" >
                      <Col lg={12}>
                          <div className="project-head">
                                <h1> Projects - Project Tittle</h1>
                          </div>
                          <div className="new-prjt-btn">
                                <Button
                                  color="primary"
                                  className="font-16 btn-block btn btn-primary"
                                  onClick={this.handleUserClicks}
                                > Add New <i class="bx bx-plus"></i>
                               </Button>
                           </div>
                      </Col>
                    </Row>
              <Row className="calendar-row">


            <Col md="3">
                <CalendarLeft />
            </Col>
            <Col md="9">
            <FullCalendar    
              themeSystem="themeSystem"      
                ref={this.calendarComponentRef}
                initialView="timeGridWeek"
                allDaySlot={false}
                titleFormat={{              
                    month: 'long',                
                    day: 'numeric',
                    weekday: 'long',
                    year: 'numeric',
                  
                }}
                
                plugins={[    
                  // BootstrapPlugin,          
                  dayGridPlugin,
                  interactionPlugin,
                  timeGridPlugin,
                  listPlugin,
                  
                ]}
                headerToolbar={{
                  left: 'timeGridDay,timeGridWeek,dayGridMonth',
                  center: 'title',
                  right: 'today prev,next'
                }} 
                views={{
                  timeGrid:{
                    titleFormat: { year: 'numeric', month: 'long', day: 'numeric', },                
                  },
                  timeGridWeek:{
                    dayHeaderFormat: this.dayHeaderWeekFormat,
                    
                  }
                }}         
                eventTimeFormat={{
                  hour: 'numeric',
                  minute: '2-digit',
                  // omitZeroMinute: true,
                  // meridiem: 'narrow'
                }}    
                // slotDuration={"00:15:00"}
                handleWindowResize={true}
                themeSystem="bootstrap"
                events={events}
                editable={false}
                droppable={false}
                selectable={false}
                selectMirror={true}
                // select={this.handleDateClick}
                eventClick={this.handleEventClick}
                // drop={this.onDrop}
              />
            </Col>
          </Row>
          </div>
          </div>
          
        
      </React.Fragment>
    )
  }
}


