import React, { Component } from "react"
import { Link } from "react-router-dom"
import { InputGroup,  Row, Col, Progress, Button, Collapse} from "reactstrap";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import moment from 'moment'
import "./TimeLineComponentStyle.scss"
import ManageTaskSchedule from './ManageTaskSchedule';
import axios_config from "../../helpers/axios_config"

/*demmu data */
import { mileStone } from './events';


export default  class TimeLineComponent extends Component {
  timeLine = null
  constructor(props) {
    super(props) 
    this.state = {
      noOfDays: 17,
      startDay: moment(),
      PushOrPullDays: 2,
      events: ''
    }

  }

  onPrevClick = () =>{
    const {  startDay, PushOrPullDays } = this.state;
    this.setState({
      startDay: moment(startDay).add(-PushOrPullDays, 'days') 
    })
  }

  onNextClick = () =>{
    const {  startDay, PushOrPullDays } = this.state;
    this.setState({
      startDay: moment(startDay).add(PushOrPullDays, 'days') 
    })
  }
   async getAllMilestone(){

    let project_id = this.props.project_id

    const response = await axios_config.post('/all-miletask/', { project_id: project_id })
    const data = response.data.data
    console.log("time_line_mile ", data)
    if(data){

    const opt =  data.map(item => ({

      "name": item["name"],
      "start": new Date(item["date"]).toISOString(),
      "end": new Date(item["date"]).toISOString(),
      "color":'#78d5fb',
      "tasks":item['task_data'].map(task =>({
      "name":task['name'],
      "start":new Date(task['start_date']).toISOString(),
      "end":new Date(task['due date']).toISOString(),
      "description":task['description'],
      }))
    }))
    console.log("time_line_mile_opt ", opt)

    this.setState({ events: opt })
  }else{
    
  }

    

    
  }
  componentDidMount(){

    this.getAllMilestone();
  }

 
  render() {
   const { noOfDays, startDay, events } = this.state;

    return (
      <React.Fragment>
            <Row className="timeline-row1">
              <Col md="2">
                <h3>Timeline</h3>
                <ManageTaskSchedule projectid = {this.props.project_id}/>                         
                
              </Col>
              <Col md="10 timeline-bar1" >
                <InputGroup>
                  <Flatpickr
                      className="form-control d-block"
                      placeholder="dd M,yyyy"
                      options={{
                          altInput: true,
                          altFormat: "F j, Y",
                          dateFormat: "Y-m-d"
                      }}
                      value={new Date(this.state.startDay)}
                      onChange={(evt) =>{                                
                        this.setState({
                          startDay: moment(new Date(evt))
                        })
                      }}
                      />
                  </InputGroup>                
              </Col>
            </Row>
            <Row  className="timeline-row2">
              <Col md="2"></Col>
              <Col md="10 timeline-date">
                <div >
                  <span className="tl-col" style={{width: `${ 100/(noOfDays+2)}%`}}> <Button onClick={this.onPrevClick} color="primary">{"<"}</Button> </span>
                    {[...Array(noOfDays)].map((x, i) =>
                      <span key={i} className="tl-col" style={{width: `${ 100/(noOfDays+2)}%`}}>
                        { moment(startDay).add(i, 'days').format("D")}
                        <br/>
                        { moment(startDay).add(i, 'days').format("ddd") }
                      </span>
                    )}  
                  <span className="tl-col" style={{width: `${ 100/(noOfDays+2)}%`}}> <Button onClick={this.onNextClick} color="primary">{">"}</Button> </span>
                </div>
              </Col>
            </Row>              
              {
                  events && events.map((ms, i) => <Row key={i}  className="timeline-row3">
                      <Col md="2">
                        <ul className={`tl-milestore ${(ms.isOpen) ? `opened` : `closed`}`}>
                          <li className="tl-milestore-task">
                            <Link
                              to="#"
                              title={formatProgressTitle(ms)}
                              onClick={() => {
                                events[i].isOpen =  !events[i].isOpen;
                                this.setState({
                                  events: events
                                });                                
                              }}
                              style={{ cursor: "pointer" }}                              
                            >
                              { ms.name }
                            </Link>                                                        
                            {
                              ms.tasks && ms.tasks.length > 0 && <Collapse isOpen={ms.isOpen}>
                                <ul>
                                  {
                                    ms.tasks.map((tsk, ti) => <li key={ti} title={formatProgressTitle(tsk)}>{ tsk.name }</li>)
                                  }
                                </ul>
                              </Collapse>
                            }
                          </li>
                        </ul>
                      </Col>
                      <Col md="10 time-bar">
                          <TimeLineRows ms={ms} noOfDays={noOfDays} startDay={startDay}></TimeLineRows>
                      </Col>
                    </Row>
                  )
                }                                 
      </React.Fragment>
    )
  }
}

const formatProgressTitle = (data) =>{
  return `${data.name} ( ${moment(data.start).format('MMM DD')} - ${moment(data.end).format('MMM DD')})`
}

const TimeLineRow = (props) =>{  
  const { start, end, color, name} = props.data;
  let j=0, colspan=0;  
  let columArr = [];
  
  return <>
    {[...Array(props.noOfDays)].map((x, i) =>{          
      const isBetweenExists = moment(moment(props.startDay).add(i, 'days')).isBetween(moment(start), moment(end), 'days', '[]');
      
      if(isBetweenExists){
        ++colspan;
        columArr[j] = {colspan: colspan, color: color, name: name, start: start, end: end};
      }else{
        ++j;
        colspan = 0;
        columArr[j] = {colspan: colspan , color: color, name: name, start: start, end: end};                        
      }              
    } )}
    {
      columArr.map((val, i)=> {        
        const colspan =  (val.colspan > 0) ? val.colspan : 1;        
        return <span key={i} className="tl-col" style={{width: `${ (100/(props.noOfDays+2)) * colspan}%`}}>
          {
            val.colspan > 0 && <Progress title={formatProgressTitle(val)} style={{backgroundColor:  val.color}} className={`progress-lg prgs ${(val.color) ? `no-bg`: ``}`} value={100}></Progress>
          }
        </span> 
      })
    }
    {
      props.data.tasks && props.data.tasks.length > 0 && props.data.tasks.map((tsk, i) =>{
        return <Collapse isOpen={props.data.isOpen} key={i}>
            <div >
              <span className="tl-col" style={{width: `${ 100/(props.noOfDays+2)}%`}}>&nbsp;</span>
              <TimeLineRow {...props} data={tsk}></TimeLineRow>   
              <span className="tl-col" style={{width: `${ 100/(props.noOfDays+2)}%`}}>&nbsp;</span>
            </div>
          </Collapse>
            
      })
    }
      
  </>  
};

const TimeLineRows = (props) =>{
 
 const { start, end} = props.ms;  
  let colSpanCount = 0;
  
  const MileStoneRow = start && end && <div>
      <span className="tl-col" style={{width: `${ 100/(props.noOfDays+2)}%`}}>&nbsp;</span>
      <TimeLineRow {...props} data={props.ms}></TimeLineRow>    
      <span className="tl-col" style={{width: `${ 100/(props.noOfDays+2)}%`}}>&nbsp;</span>
    </div>    
  return <>{ MileStoneRow }</>;
}