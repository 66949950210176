import moment from 'moment';




export const events = [
    {
        title: 'Planning how to relocate the server to the new location and how to protect files',
        start: new Date('2021-08-06 01:30:45 ').toISOString(),
        end: new Date('2021-08-06 05:50:50 ').toISOString(),        
        description: 'Lecture'
    },
    {
        title: 'Santos',
        start: new Date('2021-08-07 05:30:45 ').toISOString(),
        end: new Date('2021-08-07 07:50:50 ').toISOString(),       
        description: 'Lecture'

    },
    {
        title: 'Vijay sir',
        start: new Date('2021-08-05 04:30:45 ').toISOString(),
        end: new Date('2021-08-05 06:50:50 ').toISOString(),       
        description: 'Lecture'

    },
    {
        title: 'manpreet mam',
        start: new Date('2021-08-04 08:30:45 ').toISOString(),
        end: new Date('2021-08-04 09:50:50 ').toISOString(),       
        description: 'Lecture'

    },
    {
        title: 'shikha mam',
        start: new Date('2021-08-03 14:30:45 ').toISOString(),
        end: new Date('2021-08-03 16:50:50 ').toISOString(),       
        description: 'Lecture'

    },
    {
        title: 'dimpy mam',
        start: new Date('2021-08-02 18:30:45 ').toISOString(),
        end: new Date('2021-08-02 20:50:50 ').toISOString(),       
        description: 'Lecture'

    },
    {
        title: 'hello',
        start: new Date('2021-08-26 18:30:45 ').toISOString(),
        end: new Date('2021-08-26 20:50:50 ').toISOString(),       
        description: 'Lecture'

    }
]