import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux"
import { isEmpty } from "lodash"
import { 
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Table,
  CardHeader,
  CardSubtitle,
  CardText,
  CardTitle,
  Collapse,

  Nav,
  NavLink,
  Form,
  FormGroup,
  FormText,
  Progress,
  TabContent,
  TabPane,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { Editor } from "react-draft-wysiwyg"
import { Link, withRouter, Redirect } from "react-router-dom"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin, { Draggable } from "@fullcalendar/interaction"
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import Select from "react-select"
import axios_config from "../../../helpers/axios_config"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"


import BootstrapPlugin from "@fullcalendar/bootstrap"
//css
import "@fullcalendar/bootstrap/main.css"
import "./MyEventCalendarStyle.scss"
import moment from "moment";
import CalendarLeft from './CalendarLeft';
// below export is for testing purpos 
 import { events } from './events';
import Loader from "react-loader-spinner";
import reduxSaga from "redux-saga";


export default class MyEventCalendar extends Component {
  constructor(props) {
    super(props)
    let cal_event_create_in = false
    this.handleDateClick = this.handleDateClick.bind(this)
    this.calendarComponentRef = React.createRef()
    this.state = {
      calendarWeekends: true,
      modal: false,
      modalcategory: false,
      isDragBind: false,
      deleteModal: false,
      event: {},

      //Modals
      cal_schedule_standard: false,

      //Calender Event Schedule
      cal_event_project_name: this.props.urll_id,
      cal_event_name: '',
      cal_event_desc: '',
      cal_event_time: '',
      cal_event_date: '',
      cal_event_members_value: [],
      cal_get_today_event: '',
      cal_event_startDate: '',
      cal_event_endDate: '',
      cal_event_startTime: '',
      cal_event_endTime: '',
      cal_selectTaskMemberNameOptions: '',
      cal_selectProjectNameOptions: '',
      cal_event_create_in,

      loading: false,
      my_events:''
    }

    this.toggle = this.toggle.bind(this)
    this.togglecategory = this.togglecategory.bind(this)
    this.handleValidEventSubmit = this.handleValidEventSubmit.bind(this)
    this.handleValidEventSubmitcategory = this.handleValidEventSubmitcategory.bind(this)

    // category
    this.onDrag = this.onDrag.bind(this)
    this.onDrop = this.onDrop.bind(this)

    //Calender Schedule
    this.cal_schedule_tog_standard = this.cal_schedule_tog_standard.bind(this)
    this.handleCalValueChange = this.handleCalValueChange.bind(this)
    this.handleValidCalEventSubmit = this.handleValidCalEventSubmit.bind(this)
    this.handleCalEventDescChange = this.handleCalEventDescChange.bind(this);
    this.handleCalEventProjectNameChange = this.handleCalEventProjectNameChange.bind(this)
    this.handleCalEventStartDateChange = this.handleCalEventStartDateChange.bind(this)
    this.handleCalEventTimeStartChange = this.handleCalEventTimeStartChange.bind(this)
    this.handleCalEventDateEndChange = this.handleCalEventDateEndChange.bind(this)
    this.handleCalEventTimeEndChange = this.handleCalEventTimeEndChange.bind(this)
    this.handleCalEventMemberChange = this.handleCalEventMemberChange.bind(this);
  }
  removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  //Calender Schedule Statrt
  cal_schedule_tog_standard() {
    this.setState(prevState => ({
      cal_schedule_standard: !prevState.cal_schedule_standard,
    }))
    this.removeBodyCss()
  }

  handleCalEventMemberChange(event) {
    this.setState({ cal_event_members_value: event })

  }

  handleCalEventStartDateChange = (event) => {
    this.setState({ cal_event_startDate: event[0] })

  };
  handleCalEventTimeStartChange = (event) => {
    this.setState({ cal_event_startTime: event[0] })

  };
  handleCalEventDateEndChange = (event) => {
    this.setState({ cal_event_endDate: event[0] })

  };
  handleCalEventTimeEndChange = (event) => {
    this.setState({ cal_event_endTime: event[0] })

  };

  handleCalEventDescChange = (event) => {
    this.setState({ cal_event_desc: event.blocks[0].text })

  };

  async getCalEventMembersOptions() {
    
    const response = await axios_config.post('/all-users/', { usertype: 3 })
    const data = response.data.data
    const options = data.map(d => ({
      "value": d["user id"],
      "label": d["user firstname"]
    }))
    console.log("team name options", options);
    this.setState({ cal_selectTaskMemberNameOptions: options })
  }

  handleCalValueChange(event) {

    this.setState({ [event.target.name]: event.target.value })
  }

  async getCalProjectOptions() {
        
    const res = await axios_config.post('/all_projects/')
    // console.log('res', res)
    const data = res.data.data
    // console.log('calender Project', data)

    const options = data ? data.map(item => ({
      "value": item["project id"],
      "label": item["Project Name"]
    })) : ''
    console.log("cal_team name options", options);

    this.setState({ cal_selectProjectNameOptions: options })

  }


  handleCalEventProjectNameChange(e) {
    this.setState({ cal_event_project_name: e.value })

  }


  handleValidCalEventSubmit = (event) => {

    const iid=this.props.urrll_id
    console.log('iid', iid)
    event.preventDefault();

    this.setState({
      cal_event_project_name: '',
      cal_event_name: '',
      cal_event_desc: '',
      cal_event_members_value: '',
      cal_event_startDate: '',
      cal_event_endDate: '',
      cal_event_startTime: '',
      cal_event_endTime: '',
      cal_schedule_standard:false,
      loading: true
    })

    const { cal_event_project_name, cal_event_name, cal_event_desc, cal_event_members_value, cal_event_startDate, cal_event_endDate, cal_event_startTime, cal_event_endTime } = this.state;

    function convert(str) {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day,].join("-");
    }

    let start_date = convert(cal_event_startDate)
    let end_date = convert(cal_event_endDate)

    let start_time = cal_event_startTime.toString()
    let split_start_time = start_time.split(' ')
    let get_start_time = split_start_time[4]
    // console.log(get_start_time)

    let end_time = cal_event_endTime.toString()
    let split_end_time = end_time.split(' ')
    let get_end_time = split_end_time[4]

    // console.log("mememememe")
    console.log(cal_event_members_value)


    // console.log(getFormattedString(event_startTime))


    // let add_time = event_time+':'+'00'
    // console.log(add_time)

    // let date_change = event_date.split("-")
    // var event_up_date_format = date_change[2]+'-'+date_change[1]+'-'+date_change[0]

    var cal_arr_members_value = [];
    this.state.cal_event_members_value?this.state.cal_event_members_value.map((postData) => {
      cal_arr_members_value.push(postData.value)
    }):null

    console.log(cal_event_project_name)
    console.log(cal_event_name)
    console.log(cal_event_desc)
    console.log(cal_arr_members_value)

    // console.log(event_startDate)
    console.log(start_date)
    console.log(get_start_time)
    // console.log(event_endDate)
    console.log(end_date)
    console.log(get_end_time)

    axios_config.post("/event/", {

      projectid: cal_event_project_name,
      event: cal_event_name,
      eventdescription: cal_event_desc,
      eventtime: get_start_time,
      eventendtime: get_end_time,
      eventdate: start_date,
      eventenddate: end_date,
      membersid: cal_arr_members_value,

    }
    ).then(response => {
      console.log("resp create event", response);

      if (response.data.success == "true") {
        console.log("Add Calender Schedule Successfully");
        this.allEventData();
        alert("Add Calender Schedule Successfully")
        this.setState({
          cal_event_create_in:true,
          loading:false

        })

      } else {
        let msg = response.data.msg
        console.log(msg)
        alert(msg)
        this.allEventData();
        this.setState({
          cal_event_create_in:true,
          loading:false

        })
      }
    })
      .catch(error => {
        console.log("cal event error", error);
      });

  }
  //Calender End Schedule
//All event Data
async allEventData(){

  const response = await axios_config.post('alltodo-list/')
  // console.log('hooks_cl', response)
  const data = response.data.data
  console.log(' event all data', data)

  const options = data ? data.map(item => ({
    "title": item["event_name"],
    "start": new Date(item["event_date"] + " "+ item["event_time"]).toISOString(),
    "end": new Date(item["event_end_date"]+ " "+ item["event_time"]).toISOString(),
    // "description":item['event_desc']
  })) : ''
  console.log(' event_all_option_data', options)


this.setState({my_events:options})
}
  componentDidMount = () => {
    this.getCalEventMembersOptions();
    this.getCalProjectOptions();
    this.allEventData();
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {

  }

  /**
   * Handling the modal state
   */
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  togglecategory() {
    this.setState(prevState => ({
      modalcategory: !prevState.modalcategory,
    }))
  }

  /**
   * Handling date click on calendar
   */
  handleDateClick = arg => {
    alert("as")
    this.setState({ selectedDay: arg })
    this.toggle()
  }

  /**
   * Handling click on event on calendar
   */
  handleEventClick = arg => {

    const event = arg.event
    this.setState({
      event: {
        id: event.id,
        title: event.title,
        title_category: event.title_category,
        start: event.start,
        className: event.classNames,
        category: event.classNames[0],
        event_category: event.classNames[0],
      },
      isEdit: true,
    })
    this.toggle()
  }

  /**
   * Handling submit event on event form
   */
  handleValidEventSubmit = (e, values) => {

    const { onAddNewEvent, onUpdateEvent } = this.props
    const { isEdit, event, selectedDay } = this.state
    if (isEdit) {
      const updateEvent = {
        id: event.id,
        title: values.title,
        classNames: values.category + " text-white",
        start: event.start,
      }
      // update event
      onUpdateEvent(updateEvent)
    } else {
      const newEvent = {
        id: Math.floor(Math.random() * 100),
        title: values["title"],
        start: selectedDay ? selectedDay.date : new Date(),
        className: values.category + " text-white",
      }
      // save new event
      onAddNewEvent(newEvent)
    }

    this.setState({ selectedDay: null })
    this.toggle()
  }

  handleValidEventSubmitcategory = (event, values) => {
    const { onAddNewEvent } = this.props

    const newEvent = {
      id: Math.floor(Math.random() * 100),
      title: values["title_category"],
      start: new Date(),
      className: values.event_category ? values.event_category + " text-white" : "bg-danger text-white",
    }

    // save new event
    onAddNewEvent(newEvent)
    this.togglecategory()
  }

  /**
   * On delete event
   */
  handleDeleteEvent = () => {
    const { onDeleteEvent } = this.props
    const { event } = this.state


    onDeleteEvent(event)
    this.setState({ deleteModal: false })
    this.toggle()
  }

  /**
   * On category darg event
   */
  onDrag = (event) => {
    event.preventDefault()
  }

  /**
   * On calendar drop event
   */
  onDrop = event => {
    const { onAddNewEvent } = this.props
    const draggedEl = event.draggedEl
    const newEvent = {
      id: Math.floor(Math.random() * 100),
      title: draggedEl.innerText,
      start: event.date,
      className: draggedEl.className,
    }
    onAddNewEvent(newEvent)
  }

  dayHeaderWeekFormat = (info) => {
    return <>
      <div className="text-uppercase">{moment(info.date.marker).format("ddd")}</div>
      <div className="week-day-head">{moment(info.date.marker).format("DD")}</div>
    </>;
  }

  render() {
    if (this.state.loading) {
      return <Loader
        style={{ position: "fixed", top: "50%", left: "60%", transform: "translate(-50%, -50%)" }}
        type="Circles"
        color="#00BFFF"
        height={100}
        width={100}
        timeout={9000} //3 secs
      />
    }
    // if (this.state.cal_event_create_in) {
    //   return <Redirect to="/teams"> </Redirect>
    // }
    return (
      <React.Fragment>
        <div className="full-calendar">
          <div className="container-fluid">

            <Row className="project-task-head" >
              <Col lg={12}>
                <div className="project-head">
                  <h1> {this.props.id_data}</h1>
                </div>
                <div className="new-prjt-btn">
                  <Button
                    color="primary"
                    className="font-16 btn-block btn btn-primary"
                    onClick={this.cal_schedule_tog_standard}
                  > Add New <i class="bx bx-plus"></i>
                  </Button>
                  <Modal
                    isOpen={this.state.cal_schedule_standard}
                    toggle={this.cal_schedule_tog_standard}
                    centered={true}
                  >
                    <div className="modal-header">
                      <h5 className="modal-title mt-0" id="myModalLabel">
                        <span class="popup-head"><i class="bx bx-plus"></i> Create a new Schedule </span>
                      </h5>
                      <button
                        type="submit"
                        className="btn btn-primary right-align"
                        onClick={this.handleValidCalEventSubmit}
                      >
                        Done

                      </button>
                      <button
                        type="button"
                        onClick={() =>
                          this.setState({ cal_schedule_standard: false })
                        }
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <Form>
                        <FormGroup row>
                          {/* <Col sm={12}>
                            {/* <Select
                              options={this.state.cal_selectProjectNameOptions}
                              onChange={this.handleCalEventProjectNameChange}
                              classNamePrefix="select2-selection"
                              placeholder='Assign to a project'
                              validate={{
                                required: { value: true },
                              }}
                            /> */}
                              {/* <input
                              name="project_name"
                              type="text"
                              errorMessage="Invalid name"
                              validate={{
                                required: { value: true },
                              }}
                              className="form-control"
                              // placeholder="Enter Event Name..."
                              value={this.props.id_data}
                              onChange={this.handleCalValueChange}

                            /> 
                          </Col> */}
                        </FormGroup>

                        <FormGroup row>
                          <Col sm={12}>
                            <input
                              name="cal_event_name"
                              type="text"
                              errorMessage="Invalid name"
                              validate={{
                                required: { value: true },
                              }}
                              className="form-control"
                              placeholder="Enter Event Name..."
                              value={this.state.cal_event_name}
                              onChange={this.handleCalValueChange}

                            />
                          </Col>
                        </FormGroup>
                        <br />
                        <FormGroup row>
                          <Col sm={12}>
                            <Editor
                              placeholder="Schedule description"
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              toolbar={{
                                options: ['inline', 'history', "emoji"],
                                inline: { inDropdown: false },
                                history: { inDropdown: true },
                                emoji: { inDropdown: true }
                              }}
                              name='cal_event_desc'
                              value={this.state.cal_event_desc}
                              onChange={this.handleCalEventDescChange}
                            />
                          </Col>
                        </FormGroup>

                        <br />
                        <FormGroup row>
                          <Col sm={12}>
                            <Select
                              options={this.state.cal_selectTaskMemberNameOptions}
                              onChange={this.handleCalEventMemberChange}
                              isMulti
                              classNamePrefix="select2-selection"
                              placeholder='Invite people to that schedule'
                            />
                          </Col>
                        </FormGroup>
                        <br />
                        <FormGroup row>
                          <Col sm={3}>
                            <InputGroup>
                              <Flatpickr
                                className="form-control d-block"
                                placeholder="Starting date"
                                name="cal_event_startDate"
                                className="form-control d-block"
                                value={this.state.cal_event_startDate}
                                onChange={this.handleCalEventStartDateChange}
                                options={{
                                  altInput: true,
                                  altFormat: "F j, Y",
                                  dateFormat: "Y-m-d"
                                }}

                              />
                            </InputGroup>
                          </Col>
                          <Col sm={3}>
                            <InputGroup>
                              <Flatpickr
                                className="form-control d-block"
                                placeholder="Starting time"
                                name="cal_event_startTime"
                                value={this.state.cal_event_startTime}
                                onChange={this.handleCalEventTimeStartChange}
                                options={{
                                  enableTime: true,
                                  noCalendar: true,
                                  dateFormat: "H:i"
                                }}
                              />
                              <div className="input-group-append">
                                <span className="input-group-text">
                                  <i className="mdi mdi-clock-outline" />
                                </span>
                              </div>
                            </InputGroup>
                          </Col>
                          <Col sm={3}>
                            <InputGroup>
                              <Flatpickr
                                className="form-control d-block"
                                placeholder="Ending date"
                                name="cal_event_endDate"
                                className="form-control d-block"
                                value={this.state.cal_event_endDate}
                                onChange={this.handleCalEventDateEndChange}
                                options={{
                                  altInput: true,
                                  altFormat: "F j, Y",
                                  dateFormat: "Y-m-d"
                                }}

                              />
                            </InputGroup>
                          </Col>
                          <Col sm={3}>
                            <InputGroup>
                              <Flatpickr
                                className="form-control d-block"
                                placeholder="Ending time"
                                name="cal_event_endTime"
                                className="form-control d-block"
                                value={this.state.cal_event_endTime}
                                onChange={this.handleCalEventTimeEndChange}
                                options={{
                                  enableTime: true,
                                  noCalendar: true,
                                  dateFormat: "H:i"
                                }}
                              />
                              <div className="input-group-append">
                                <span className="input-group-text">
                                  <i className="mdi mdi-clock-outline" />
                                </span>
                              </div>
                            </InputGroup>
                          </Col>
                        </FormGroup>
                      </Form>

                    </div>
                    <div className="modal-footer ftr-none">

                    </div>
                  </Modal>
                </div>
              </Col>
            </Row>
            <Row className="calendar-row">


              <Col md="3">
                <CalendarLeft />
              </Col>
              <Col md="9">
                <FullCalendar
                  themeSystem="themeSystem"
                  ref={this.calendarComponentRef}
                  initialView="timeGridWeek"
                  allDaySlot={false}
                  titleFormat={{
                    month: 'long',
                    day: 'numeric',
                    weekday: 'long',
                    year: 'numeric',

                  }}

                  plugins={[
                    // BootstrapPlugin,          
                    dayGridPlugin,
                    interactionPlugin,
                    timeGridPlugin,
                    listPlugin,

                  ]}
                  headerToolbar={{
                    left: 'timeGridDay,timeGridWeek,dayGridMonth',
                    center: 'title',
                    right: 'today prev,next'
                  }}
                  views={{
                    timeGrid: {
                      titleFormat: { year: 'numeric', month: 'long', day: 'numeric', },
                    },
                    timeGridWeek: {
                      dayHeaderFormat: this.dayHeaderWeekFormat,

                    }
                  }}
                  eventTimeFormat={{
                    hour: 'numeric',
                    minute: '2-digit',
                    // omitZeroMinute: true,
                    // meridiem: 'narrow'
                  }}
                  // slotDuration={"00:15:00"}
                  handleWindowResize={true}
                  themeSystem="bootstrap"
                  // events={events}
                  events={this.state.my_events}
                  editable={false}
                  droppable={false}
                  selectable={false}
                  selectMirror={true}
                  // select={this.handleDateClick}
                  eventClick={this.handleEventClick}
                // drop={this.onDrop}
                />
              </Col>
            </Row>
          </div>
        </div>


      </React.Fragment>
    )
  }
}


