import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Card, CardBody } from "reactstrap"
import ReactApexChart from "react-apexcharts"

export default class Storage extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <React.Fragment>
        <Card className="filemanager-sidebar ms-lg-2">
          <CardBody>
            <div className="text-center">
              <h5 className="font-size-15 mb-4">Thread</h5>


            </div>

            <div className="mt-4">

            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}
