import React, { Component } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  UncontrolledAlert,
  UncontrolledDropdown,
} from "reactstrap"

export default class FileRightBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      file_collapse: true,
    }
    this.file = this.file.bind(this)
  }
  file() {
    this.setState({
      file_collapse: !this.state.file_collapse,
    })
  }
  render() {
    return (
      <React.Fragment>
        <Card className="filemanager-sidebar me-md-2">
          <CardBody>
            <div className="d-flex flex-column h-100">
              <div className="mb-4">
                <div className="mb-3 teamchat-left">
                <div className="allfiles-nav">
                      <Link className="allfile" to="#">
                         All Files<i className="mdi mdi-chevron-right"></i> 
                      </Link>
                </div>

                  <UncontrolledDropdown>
                    <DropdownToggle
                      className="btn btn-light dropdown-toggle w-100"
                      color="#eff2f7"
                      type="button"
                    >
                      <i class="bx bx-plus"></i> Add New
                    </DropdownToggle>
                    <DropdownMenu>
                         <Link className="dropdown-item" to="#">
                      <i class="mdi mdi-account-plus-outline"></i> Upload File
                      </Link>
                      <Link className="dropdown-item" to="#">
                      <i  className="bx bx-hash"></i> New Folder
                      </Link>
                      <Link className="dropdown-item" to="#">
                        <i className="mdi mdi-cog-outline"></i> New Text File
                      </Link>
                      <Link className="dropdown-item" to="#">
                        <i className="mdi mdi-cog-outline"></i> New Document
                      </Link>
                      <Link className="dropdown-item" to="#">
                        <i className="mdi mdi-cog-outline"></i> New Spreadsheet
                      </Link>
                       <Link className="dropdown-item" to="#">
                        <i className="mdi mdi-cog-outline"></i> New Pdf
                      </Link>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
                  <ul className="addleft-sub">
                    <li>
                      <Link href="#" className="active">All</Link>
                    </li>
                    <li>
                      <Link href="#">Asigned to me</Link>
                    </li>
                    <li>
                      <Link href="#">Shared</Link>
                    </li>
                    <li>
                      <Link href="#">My Files</Link>
                    </li>
                  </ul>
              </div>

            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}
