import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import moment from "moment"
import { size } from "lodash"
import {
  Table,
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Media,
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap"
import classnames from "classnames"
import { Editor } from "react-draft-wysiwyg"
//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
//Import Images
import images from "../../../assets/images"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import ModalVideo from "react-modal-video"
import "react-modal-video/scss/modal-video.scss"
import {
  addMessage,
  getChats,
  getContacts,
  getGroups,
  getMessages,
} from "../../../store/actions"

class Chat extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentRoomId: 1,
      currentUser: {
        name: "Henry Wells",
        isActive: true,
      },
      notification_Menu: false,
      search_Menu: false,
      settings_Menu: false,
      other_Menu: false,
      activeTab: "1",
      Chat_Box_Username: "Steven Franklin",
      Chat_Box_User_Status: "online",
      Chat_Box_User_isActive: false,
      curMessage: "",
    }
    this.state = {
      visible: false,
      modal_standard: false,
      modal_fullscreen: false,
      modal_large: false,
      modal_xlarge: false,
      modal_small: false,
      modal_center: false,
      modal_scroll: false,
      modal_backdrop: false,
      modal_task: false,
      activeTab: "1",
      activeTab1: "5",
      activeTab2: "9",
      activeTab3: "13",
      verticalActiveTab: "1",
      verticalActiveTabWithIcon: "1",
      customActiveTab: "1",
      customIconActiveTab: "1",
      activeTabJustify: "5",
      col1: true,
      col2: false,
      col3: false,
      col11: true,
      col22: false,
      col33: false,
      col4: false,
      col5: false,
      col6: false,
      all_team_projects: [],
      task_modal_task: false,
      milestone_standard: false,
      schedule_standard: false,


    }
    this.tog_center = this.tog_center.bind(this)
  }
  
  componentDidMount() {
    const { onGetChats, onGetGroups, onGetContacts, onGetMessages } = this.props
    const { currentRoomId } = this.state
    onGetChats()
    onGetGroups()
    onGetContacts()
    onGetMessages(currentRoomId)
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { messages } = this.props
    if (size(messages) !== size(prevProps.messages)) {
      this.scrollToBottom()
    }
  }

  toggleNotification = () => {
    this.setState(prevState => ({
      notification_Menu: !prevState.notification_Menu,
    }))
  }

  //Toggle Chat Box Menus
  toggleSearch = () => {
    this.setState(prevState => ({
      search_Menu: !prevState.search_Menu,
    }))
  }

  toggleSettings = () => {
    this.setState(prevState => ({
      settings_Menu: !prevState.settings_Menu,
    }))
  }

  toggleOther = () => {
    this.setState(prevState => ({
      other_Menu: !prevState.other_Menu,
    }))
  }

  toggleTab = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  //Use For Chat Box
  userChatOpen = (id, name, status, roomId) => {
    const { onGetMessages } = this.props
    this.setState({ Chat_Box_Username: name, currentRoomId: roomId })
    onGetMessages(roomId)
  }

  addMessage = (roomId, sender) => {
    const { onAddMessage } = this.props
    const message = {
      id: Math.floor(Math.random() * 100),
      roomId,
      sender,
      message: this.state.curMessage,
      createdAt: new Date(),
    }
    this.setState({ curMessage: "" })
    onAddMessage(message)
  }

  scrollToBottom = () => {
    if (this.messageBox) {
      this.messageBox.scrollTop = this.messageBox.scrollHeight + 1000
    }
  }

  onKeyPress = e => {
    const { key, value } = e
    const { currentRoomId, currentUser } = this.state
    if (key === "Enter") {
      this.setState({ curMessage: value })
      this.addMessage(currentRoomId, currentUser.name)
    }
  }
  tog_center() {
    this.setState(prevState => ({
      modal_center: !prevState.modal_center,
    }))
    this.removeBodyCss()
  }
  tog_standard() {
    this.setState(prevState => ({
      modal_standard: !prevState.modal_standard,
    }))
    this.removeBodyCss()
  }

  removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  tog_fullscreen() {
    this.setState(prevState => ({
      modal_fullscreen: !prevState.modal_fullscreen,
    }))
    this.removeBodyCss()
  }

  tog_backdrop() {
    this.setState(prevState => ({
      modal_backdrop: !prevState.modal_backdrop,
    }))
    this.removeBodyCss()
  }

  tog_large() {
    this.setState(prevState => ({
      modal_large: !prevState.modal_large,
    }))
    this.removeBodyCss()
  }

  tog_xlarge() {
    this.setState(prevState => ({
      modal_xlarge: !prevState.modal_xlarge,
    }))
    this.removeBodyCss()
  }

  tog_small() {
    this.setState(prevState => ({
      modal_small: !prevState.modal_small,
    }))
    this.removeBodyCss()
  }

  tog_center() {
    this.setState(prevState => ({
      modal_center: !prevState.modal_center,
    }))
    this.removeBodyCss()
  }

  tog_scroll() {
    this.setState(prevState => ({
      modal_scroll: !prevState.modal_scroll,
    }))
    this.removeBodyCss()
  }
  render() {
    const { chats, groups, contacts, messages } = this.props
    const { currentRoomId, currentUser } = this.state

    return (
      <React.Fragment>

                <div className="chat-page">



                   <div className="user-chat">
                    <Card>
                      <div className="border-bottom chat-head">
                        <Media>
                          <div className="chat-head-left">
                           <Media body>
                            <h5 className="font-size-15 mt-0 mb-1">
                             Developer Meeting
                            </h5>
                            <span className="consing-txt">Conversing about exsiting development projects</span>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={this.tog_center}
                            data-toggle="modal"
                            data-target=".bs-example-modal-center"
                          >
                            popup
                          </button>
                        <Modal className="teamvideo-popup"
                          isOpen={this.state.modal_center}
                          toggle={this.tog_center}
                          centered={true}
                        >
                       <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myModalLabel">
                              <span class="popup-head"> Create a new Video call</span>
                            </h5>

                            <button
                              type="button"
                              onClick={() =>
                                this.setState({ modal_standard: false })
                              }
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body ">
                            <div className="search-field ">
                            <input
                                    name="project_name"
                                    type="text"
                                    className="form-control"
                                    placeholder="Search a member"
                                    value={this.state.project_name}
                                    onChange={this.handleNameChange}
                                  />
                            </div>
                            <div className="selection ">
                                <p>1 Selected</p><span className="search-fiter "><i className="bx bxs-flask"></i> All Teams</span>
                            </div>
                            <div className="selectlist-out ">
                                <div className="selectlist ">
                                  <span className="selectimg "></span>
                                  <span className="selectname ">Brad Griffen</span>
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="exampleRadios"
                                    id="exampleRadios1"
                                    value="option1"
                                    defaultChecked
                                  />
                              </div>
                              <div className="selectlist ">
                                  <span className="selectimg "></span>
                                  <span className="selectname ">Jamle Wells</span>
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="exampleRadios"
                                    id="exampleRadios2"
                                    value="option2"
                                  />
                              </div>
                            </div>
                          <button
                              type="submit"
                              className="btn btn-primary"
                              onClick={this.projectFormSubmit}
                            >
                              Create video a call 

                            </button>
                          </div>
                        </Modal>
                          </Media>
                          </div>
                          <div className="chat-head-right">
                            <ul className="chat-head-ul">
                              <li>
                               <Link to="#" className="call-btn"><i class="bx bx-minus-front" ></i></Link>
                              </li>
                              <li>
                                 <Link to="#" className="video-btn"><i class="bx bx-video" ></i></Link>
                              </li>
                              <li>
                                 <Link to="#" className="account-btn"><i class="mdi mdi-account-outline" ></i></Link>
                              </li>
                              <li>
                                 <Link to="#" className="pin-btn"><i class="mdi mdi-pin" ></i></Link>
                              </li>
                            </ul>
                            <form className="app-search d-none d-lg-block">
                            <div className="position-relative">
                              <input type="text"className="form-control" placeholder="Search"/>
                              <span className="bx bx-search"></span>
                            </div>
                            <div className="app-search-icon">
                              <Link to="#" className="app-search-icon-btn">@</Link>
                            </div>
                          </form>
                          </div>
                        </Media>
                      </div>
                      
                      <div className="teamchat-sect">
                      <div className="chat-conver-outer">
                        <div className="chat-conversation p-3">
                          <ul className="list-unstyled mb-0">
                            <PerfectScrollbar
                              style={{ height: "486px" ,minHeight: "486px" }}
                              containerRef={ref => (this.messageBox = ref)}
                            >
                              <li>
                                <div className="chat-day-title">
                                  <span className="title">Today</span>
                                </div>
                              </li>
                              {messages &&
                                messages.map(message => (
                                  <li
                                    key={"test_k2" + message.id}
                                    className={
                                      message.sender === currentUser.name
                                        ? "right"
                                        : ""
                                    }
                                  >
                                    <div className="conversation-list">
                                      <UncontrolledDropdown>
                                        <DropdownToggle
                                          href="#"
                                          className="dropdown-toggle"
                                          tag="a"
                                        >
                                          <i className="bx bx-dots-vertical-rounded" />
                                        </DropdownToggle>
                                        <DropdownMenu direction="right">
                                          <DropdownItem href="#">
                                            Copy
                                          </DropdownItem>
                                          <DropdownItem href="#">
                                            Save
                                          </DropdownItem>
                                          <DropdownItem href="#">
                                            Forward
                                          </DropdownItem>
                                          <DropdownItem href="#">
                                            Delete
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                      <div className="ctext-wrap">
                                        <div className="conversation-name">
                                          {message.sender}
                                        </div>
                                        <p>{message.message}</p>
                                        <p className="chat-time mb-0">
                                          <i className="bx bx-time-five align-middle me-1" />{" "}
                                          {moment(message.createdAt).format(
                                            "hh:mm"
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </li>
                                ))}
                            </PerfectScrollbar>
                          </ul>
                        </div>
                        <div className="p-3 chat-input-section">
                         <div className=" chat-input-section-outer">
                          <Row className="teamchat-type">
                            <Col className="teamchat-typetxt">
                              <div className="position-relative">
                                <input
                                  type="text"
                                  value={this.state.curMessage}
                                  onKeyPress={this.onKeyPress}
                                  onChange={e => {
                                    this.setState({
                                      curMessage: e.target.value,
                                    })
                                  }}
                                  className="form-control chat-input"
                                  placeholder="Message #Developer Meeting"
                                />

                              </div>
                              
                            </Col>
                             <Col className="chat-bottom-icons">
                              <div className="chat-input-links">
                                  <ul className="list-inline mb-0">
                                    <li className="list-inline-item">
                                      <Link to="#">
                                        <i
                                          className="mdi mdi-format-text-rotation-none"
                                          id="Emojitooltip"
                                        ></i>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="Emojitooltip"
                                        >
                                          Emojis
                                        </UncontrolledTooltip>
                                      </Link>
                                    </li>{" "}
                                    <li className="list-inline-item">
                                      <Link to="#">
                                        <i
                                          className="mdi mdi-link-variant"
                                          id="Imagetooltip"
                                        ></i>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="Imagetooltip"
                                        >
                                          Images
                                        </UncontrolledTooltip>
                                      </Link>
                                    </li>{" "}
                                    <li className="list-inline-item">
                                      <Link to="#">
                                        <i
                                          className=" bx bx-link-alt"
                                          id="Filetooltip"
                                        ></i>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="Filetooltip"
                                        >
                                          Add Files
                                        </UncontrolledTooltip>
                                      </Link>
                                    </li>
                                     <li className="list-inline-item">
                                      <Link to="#">
                                        <i
                                          className="bx bxs-happy"
                                          id="Filetooltip"
                                        ></i>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="Filetooltip"
                                        >
                                          Add Files
                                        </UncontrolledTooltip>
                                      </Link>
                                    </li>{" "}

                                    
                                  </ul>
                                </div>
                            </Col>
                            <Col className="col-auto">
                              <Button
                                type="button"
                                color="primary"
                                onClick={() =>
                                  this.addMessage(
                                    currentRoomId,
                                    currentUser.name
                                  )
                                }
                                className="btn-rounded chat-send w-md"
                              >
                                <span className="d-none d-sm-inline-block me-2">
                                </span>{" "}
                                <i className="mdi mdi-send"></i>
                              </Button>
                            </Col>
                          </Row>
                            
                          
                          </div>
                        </div>
                      </div>
                      <div className="teamchat-right">
                        <div className="tmcht-hd">
                            <h2>Thread</h2>
                        </div>
                        <div className="tmcht-content">
                          <div className="tmcht-cnt-date">
                                <p>Fri, June 14, 2019</p>
                           </div>
                           <div className="tmcht-cnt-dtl">
                              <div className="cnt-hd">
                                Paloalabank Incldent Bot <span className="cnt-hd-grey">Bot</span><span className="time">4.02 Pm</span>
                              </div>
                              <div className="tmcht-cnt-block">
                               <p>Post-Mortem<a>#SEC067</a></p>
                               <p>Post-Mortem for issue sec067 attached</p>
                               <div className="cnt-blcktwoside">
                                  <span className="leftside">
                                    Owner
                                  </span>
                                  <span className="rightside">
                                    Resulution Time
                                  </span>
                               </div>
                               <div className="cnt-blcktwoside">
                                  <a className="leftside">
                                    Owner
                                  </a>
                                  <a className="rightside">
                                    Resulution Time
                                  </a>
                               </div>
                               <div className="cnt-box">
                                  <span className="leftside">
                                    <i className="mdi mdi-file-pdf-outline"></i>
                                  </span>
                                  <p className="rightside">
                                    issue sec067.pptx
                                    <span>PPTX MB</span>
                                  </p>
                               </div>
                              </div>
                           </div>
                        </div>
                        <div className="tmcht-bottom">
                           <div className="tmcht-bottom-name">
                                <p>Jamle wells</p>
                                <span>Great work <a>@Maltsanloss</a> complete Record Time</span>
                           </div>
                           <div className="tmcht-comticon">
                                <span className="comticon-block"> <i class="bx bxs-happy" ></i>1</span>
                                <span className="comticon-block"> <i class="bx bx-meh-blank" ></i>1</span>
                                <span className="comticon-block"> <i class="bx bx-cool" ></i>2</span>
                           </div>
                        </div>
                        <div className="p-3 chat-input-section">
                         <div className=" chat-input-section-outer">
                          <Row className="chat-left-bottomrow">
                            <Col className="chat-left-w">
                              <div className="position-relative">
                                <input
                                  type="text"
                                  value={this.state.curMessage}
                                  onKeyPress={this.onKeyPress}
                                  onChange={e => {
                                    this.setState({
                                      curMessage: e.target.value,
                                    })
                                  }}
                                  className="form-control chat-input"
                                  placeholder="Post a response"
                                />

                              </div>
                              
                            </Col >
                            <Col className="chat-bottom-icons">
                              <div className="chat-input-links">
                                  <ul className="list-inline mb-0">
                                    <li className="list-inline-item">
                                      <Link to="#">
                                        <i
                                          className="mdi mdi-format-text-rotation-none"
                                          id="Emojitooltip"
                                        ></i>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="Emojitooltip"
                                        >
                                          Emojis
                                        </UncontrolledTooltip>
                                      </Link>
                                    </li>{" "}
                                    <li className="list-inline-item">
                                      <Link to="#">
                                        <i
                                          className="mdi mdi-link-variant"
                                          id="Imagetooltip"
                                        ></i>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="Imagetooltip"
                                        >
                                          Images
                                        </UncontrolledTooltip>
                                      </Link>
                                    </li>{" "}
                                    <li className="list-inline-item">
                                      <Link to="#">
                                        <i
                                          className=" bx bx-link-alt"
                                          id="Filetooltip"
                                        ></i>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="Filetooltip"
                                        >
                                          Add Files
                                        </UncontrolledTooltip>
                                      </Link>
                                    </li>
                                     <li className="list-inline-item">
                                      <Link to="#">
                                        <i
                                          className="bx bxs-happy"
                                          id="Filetooltip"
                                        ></i>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="Filetooltip"
                                        >
                                          Add Files
                                        </UncontrolledTooltip>
                                      </Link>
                                    </li>{" "}

                                    
                                  </ul>
                                </div>
                            </Col>      
                          </Row>

                            <Row className="chat-bottom-row">
                            <Col className="col-auto">
                              <Button
                                type="button"
                                color="primary"
                                onClick={() =>
                                  this.addMessage(
                                    currentRoomId,
                                    currentUser.name
                                  )
                                }
                                className="btn-rounded chat-send w-md"
                              >
                                <span className="d-none d-sm-inline-block me-2">
                                </span>{" "}
                                <i className="mdi mdi-send"></i>
                              </Button>
                            </Col>     
                          </Row>
                          </div>
                        </div>
                      </div>
                      </div>
                    </Card>
                  </div>
                </div>
      </React.Fragment>
    )
  }
}

Chat.propTypes = {
  chats: PropTypes.array,
  groups: PropTypes.array,
  contacts: PropTypes.array,
  messages: PropTypes.array,
  onGetChats: PropTypes.func,
  onGetGroups: PropTypes.func,
  onGetContacts: PropTypes.func,
  onGetMessages: PropTypes.func,
  onAddMessage: PropTypes.func,
}

const mapStateToProps = ({ chat }) => ({
  chats: chat.chats,
  groups: chat.groups,
  contacts: chat.contacts,
  messages: chat.messages,
})

const mapDispatchToProps = dispatch => ({
  onGetChats: () => dispatch(getChats()),
  onGetGroups: () => dispatch(getGroups()),
  onGetContacts: () => dispatch(getContacts()),
  onGetMessages: roomId => dispatch(getMessages(roomId)),
  onAddMessage: roomId => dispatch(addMessage(roomId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Chat)
