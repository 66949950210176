import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import moment from "moment"
import { size } from "lodash"
import {
  Table,
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Media,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap"
import classnames from "classnames"
import { Editor } from "react-draft-wysiwyg"
//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"

//Import Images
import images from "../../../assets/images"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  addMessage,
  getChats,
  getContacts,
  getGroups,
  getMessages,
} from "../../../store/actions"

class Chat extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentRoomId: 1,
      currentUser: {
        name: "Henry Wells",
        isActive: true,
      },
      notification_Menu: false,
      search_Menu: false,
      settings_Menu: false,
      other_Menu: false,
      activeTab: "1",
      Chat_Box_Username: "Steven Franklin",
      Chat_Box_User_Status: "online",
      Chat_Box_User_isActive: false,
      curMessage: "",
    }
    
    this.messageBox = null
  
  }

  componentDidMount() {
    const { onGetChats, onGetGroups, onGetContacts, onGetMessages } = this.props
    const { currentRoomId } = this.state
    onGetChats()
    onGetGroups()
    onGetContacts()
    onGetMessages(currentRoomId)
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { messages } = this.props
    if (size(messages) !== size(prevProps.messages)) {
      this.scrollToBottom()
    }
  }

  toggleNotification = () => {
    this.setState(prevState => ({
      notification_Menu: !prevState.notification_Menu,
    }))
  }

  //Toggle Chat Box Menus
  toggleSearch = () => {
    this.setState(prevState => ({
      search_Menu: !prevState.search_Menu,
    }))
  }

  toggleSettings = () => {
    this.setState(prevState => ({
      settings_Menu: !prevState.settings_Menu,
    }))
  }

  toggleOther = () => {
    this.setState(prevState => ({
      other_Menu: !prevState.other_Menu,
    }))
  }

  
  tog_standard() {
    this.setState(prevState => ({
      modal_standard: !prevState.modal_standard,
    }))
    this.removeBodyCss()
  }

  removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  tog_fullscreen() {
    this.setState(prevState => ({
      modal_fullscreen: !prevState.modal_fullscreen,
    }))
    this.removeBodyCss()
  }

  tog_backdrop() {
    this.setState(prevState => ({
      modal_backdrop: !prevState.modal_backdrop,
    }))
    this.removeBodyCss()
  }

  tog_large() {
    this.setState(prevState => ({
      modal_large: !prevState.modal_large,
    }))
    this.removeBodyCss()
  }

  tog_xlarge() {
    this.setState(prevState => ({
      modal_xlarge: !prevState.modal_xlarge,
    }))
    this.removeBodyCss()
  }

  tog_small() {
    this.setState(prevState => ({
      modal_small: !prevState.modal_small,
    }))
    this.removeBodyCss()
  }

  tog_center() {
    this.setState(prevState => ({
      modal_center: !prevState.modal_center,
    }))
    this.removeBodyCss()
  }

  tog_scroll() {
    this.setState(prevState => ({
      modal_scroll: !prevState.modal_scroll,
    }))
    this.removeBodyCss()
  }

  show() {
    this.setState({ visible: true })
  }

  hide() {
    this.setState({ visible: false })
  }

  toggletab = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  //Use For Chat Box
  userChatOpen = (id, name, status, roomId) => {
    const { onGetMessages } = this.props
    this.setState({ Chat_Box_Username: name, currentRoomId: roomId })
    onGetMessages(roomId)
  }

  addMessage = (roomId, sender) => {
    const { onAddMessage } = this.props
    const message = {
      id: Math.floor(Math.random() * 100),
      roomId,
      sender,
      message: this.state.curMessage,
      createdAt: new Date(),
    }
    this.setState({ curMessage: "" })
    onAddMessage(message)
  }

  scrollToBottom = () => {
    if (this.messageBox) {
      this.messageBox.scrollTop = this.messageBox.scrollHeight + 1000
    }
  }

  onKeyPress = e => {
    const { key, value } = e
    const { currentRoomId, currentUser } = this.state
    if (key === "Enter") {
      this.setState({ curMessage: value })
      this.addMessage(currentRoomId, currentUser.name)
    }
  }

  render() {
    const { chats, groups, contacts, messages } = this.props
    const { currentRoomId, currentUser } = this.state

    return (
      <React.Fragment>

                <div className="compose-page">



                  <div className="compose-leftsidebar">
                       <div className="compose-leftsidebar-first">
                       <div className="compose-menu">
                         <div className="compose-link">
                          <Link to="#">Compose <i class="mdi-border-all-variant"></i></Link>
                         </div>
                          <ul>
                            <li><Link to="#"><i class="far fa-envelope-open"></i>Inbox</Link></li>
                            <li><Link to="#"><i class="bx bx-wallet"></i>Active</Link></li>
                            <li><Link to="#"><i class="mdi mdi-send"></i>Send</Link></li>
                            <li><Link to="#"><i class="far fa-envelope-open"></i>Drafts</Link></li>
                            <li><Link to="#"><i class="mdi-bookmark-multiple-outline"></i>Favorites</Link></li>
                            <li><Link to="#"><i class="bx bx-trash" ></i>Trash</Link></li>
                            <li><Link to="#"><i class="mdi-alert-circle"></i>Sparm</Link></li>
                          </ul>
                        </div>
                        <div className="compose-left-btm">
                        <Link to="#">Contacts</Link>
                        </div>

                         </div>

                  </div>




                  <div className="user-compose">
                    <Card>
                      <div className="border-bottom compose-head">
                        <Media>
                          <div className="compose-head-left">
                          </div>
                          <div className="compose-head-right">
                            <ul className="compose-head-ul">
                              <li>
                               <Link to="#"><span class="search-icon"><i class="bx bx-search"></i></span></Link>
                              </li>
                              <li>
                                 <Link to="#">Add Contact<i class="bx bx-plus "></i></Link>
                              </li>
                            </ul>
                          </div>
                        </Media>
                      </div>
                      

                      <div className="compose-conver-outer">
                        <div className="compose-conversation p-3">
                        <Nav tabs>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "1",
                          })}
                          onClick={() => {
                            this.toggletab("1")
                          }}
                        >
                          All
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "2",
                          })}
                          onClick={() => {
                            this.toggletab("2")
                          }}
                        >
                         Favorites
                        </NavLink>

                      </NavItem>



                    </Nav>
                    <TabContent activeTab={this.state.activeTab} className="p-3 text-muted">
                      <TabPane tabId="1">
                        <Row>
                          <Col lg={12}>
                          <Table className="table mb-0 compose-all">
                        <tbody>
                          <tr>
                            <td><Link className="letter-img blue" to="#">G</Link></td>
                            <td>George Lucas</td>
                            <td>george@gmail.com</td>
                            <td>+1-000-000-0000</td>
                            <td>CEO Company inc</td>
                          </tr>
                          <tr>
                          <td><Link className="letter-img green" to="#">F</Link></td>
                            <td>Scarlet Jonson</td>
                            <td>scarlet@gmail.com</td>
                            <td>+1-000-000-0000</td>
                            <td>CEO Company inc</td>
                          </tr>
                        </tbody>
                      </Table>
                          
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="2">

                        <Row>
                          <Col lg={12}>
                          <Table className="table mb-0 compose-all">
                        <tbody>
                          <tr>
                            <td><Link className="letter-img blue" to="#">K</Link></td>
                            <td>George Lucas 2</td>
                            <td>george@gmail.com</td>
                            <td>+1-000-000-222</td>
                            <td>CEO Company inc</td>
                          </tr>
                          <tr>
                          <td><Link className="letter-img green" to="#">H</Link></td>
                            <td>Scarlet Jonson 2</td>
                            <td>scarlet@gmail.com</td>
                            <td>+1-000-000-2222</td>
                            <td>CEO Company inc</td>
                          </tr>
                        </tbody>
                      </Table>

                          </Col>
                        </Row>

                      </TabPane>
                    </TabContent>

                        </div>
                        
                        </div>
                    </Card>
                  </div>
                </div>
      </React.Fragment>
    )
  }
}

Chat.propTypes = {
  chats: PropTypes.array,
  groups: PropTypes.array,
  contacts: PropTypes.array,
  messages: PropTypes.array,
  onGetChats: PropTypes.func,
  onGetGroups: PropTypes.func,
  onGetContacts: PropTypes.func,
  onGetMessages: PropTypes.func,
  onAddMessage: PropTypes.func,
}

const mapStateToProps = ({ chat }) => ({
  chats: chat.chats,
  groups: chat.groups,
  contacts: chat.contacts,
  messages: chat.messages,
})

const mapDispatchToProps = dispatch => ({
  onGetChats: () => dispatch(getChats()),
  onGetGroups: () => dispatch(getGroups()),
  onGetContacts: () => dispatch(getContacts()),
  onGetMessages: roomId => dispatch(getMessages(roomId)),
  onAddMessage: roomId => dispatch(addMessage(roomId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Chat)
