import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import CarouselPage from "./CarouselPage"

// import images
import logodark from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import { Col, Container, Row } from "reactstrap"

export default class EmailVerification2 extends Component {
  render() {
    return (
      <React.Fragment>
        <div>
        <MetaTags>
            <title>Email Verification 2 | Skote - React Admin & Dashboard Template</title>
          </MetaTags>
          <Container fluid className="p-0">
            <Row className="g-0">
              <CarouselPage />

              <Col cl={3}>
                <div className="auth-full-page-content p-md-5 p-4">
                  <div className="w-100">
                    <div className="d-flex flex-column h-100">
                      <div className="mb-4 mb-md-5">
                        <a href="/" className="d-block auth-logo">
                          <img
                            src={logodark}
                            alt=""
                            height="18"
                            className="auth-logo-dark"
                          />
                          <img
                            src={logolight}
                            alt=""
                            height="18"
                            className="auth-logo-light"
                          />
                        </a>
                      </div>
                      <div className="my-auto">
                        <div className="text-center">
                          <div className="avatar-md mx-auto">
                            <div className="avatar-title rounded-circle bg-light">
                              <i className="bx bxs-envelope h1 mb-0 text-primary"></i>
                            </div>
                          </div>
                          <div className="p-2 mt-4">
                            <h4>Verify your email</h4>
                            <p>
                              We have sent you verification email{" "}
                              <span className="font-weight-semibold">
                                example@abc.com
                              </span>
                              , Please check it
                            </p>
                            <div className="mt-4">
                              <a
                                href="/"
                                className="btn btn-success w-md"
                              >
                                Verify email
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-4 mt-md-5 text-center">
                        <p className="mb-0">
                          © {new Date().getFullYear()} Skote. Crafted with{" "}
                          <i className="mdi mdi-heart text-danger"></i> by
                          Themesbrand
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}