import React, { Component } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  UncontrolledAlert,
  UncontrolledDropdown,
} from "reactstrap"

export default class FileRightBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      file_collapse: true,
    }
    this.file = this.file.bind(this)
  }
  file() {
    this.setState({
      file_collapse: !this.state.file_collapse,
    })
  }
  render() {
    return (
      <React.Fragment>
        <Card className="filemanager-sidebar me-md-2">
          <CardBody>
            <div className="d-flex flex-column h-100">
              <div className="mb-4">
                <div className="mb-3 teamchat-left">
                  <UncontrolledDropdown>
                    <DropdownToggle
                      className="btn btn-light dropdown-toggle w-100"
                      color="#eff2f7"
                      type="button"
                    >
                      <span className="first-letter">A</span> Team A <i class="fa fa-fw fa-bars"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                      <div className="dropmenu-hd"> <span className="first-letter">A</span>Team A</div>
                      <Link className="dropdown-item" to="#">
                      <i class="mdi mdi-account-plus-outline"></i> Invite people to team
                      </Link>
                      <Link className="dropdown-item" to="#">
                      <i  className="bx bx-hash"></i> Create a channel
                      </Link>
                      <Link className="dropdown-item" to="#">
                        <i className="mdi mdi-cog-outline"></i> Setting & Administration
                      </Link>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
                <ul className="list-unstyled categories-list">
                  <li>
                    <div className="custom-accordion">
                      <Link
                        className="text-body fw-medium py-1 d-flex align-items-center"
                        onClick={this.file}
                        to="#"
                      >
                        {" "}
                        Public Channel{" "}
                        {/* <i className="mdi mdi-chevron-up accor-down-icon ms-auto"></i> */}
                        <i
                          className={
                            this.state.file_collapse
                              ? "mdi mdi-chevron-up accor-down-icon ms-auto"
                              : "mdi mdi-chevron-down accor-down-icon ms-auto"
                          }
                        />
                      </Link>
                      <Collapse isOpen={this.state.file_collapse}>
                        <div className="card border-0 shadow-none ps-2 mb-0">
                          <ul className="list-unstyled mb-0">
                            <li>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="me-auto"># Customers</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="me-auto"># Developer Meeting</span>{" "}
                               </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="me-auto"># Developer talk</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="me-auto"># Develops talk</span>{" "}
                                
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="me-auto"># Off-Topics</span>{" "}
                                
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="me-auto"># Recruiting</span>{" "}
                                
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="me-auto"># Release</span>{" "}
                                
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="me-auto"># UX Design</span>{" "}
                                
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </Collapse>
                    </div>
                  </li>
                  <li>
                    <div className="custom-accordion">
                      <Link
                        className="text-body fw-medium py-1 d-flex align-items-center"
                        onClick={this.file}
                        to="#"
                      >
                        {" "}
                        Private Channels{" "}
                        {/* <i className="mdi mdi-chevron-up accor-down-icon ms-auto"></i> */}
                        <i
                          className={
                            this.state.file_collapse
                              ? "mdi mdi-chevron-up accor-down-icon ms-auto"
                              : "mdi mdi-chevron-down accor-down-icon ms-auto"
                          }
                        />
                      </Link>
                      <Collapse isOpen={this.state.file_collapse}>
                        <div className="card border-0 shadow-none ps-2 mb-0">
                          <ul className="list-unstyled mb-0">
                            <li>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="me-auto"># Customers</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="me-auto"># Developer Meeting</span>{" "}
                                
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="me-auto"># Developer talk</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="me-auto"># Develops talk</span>{" "}
                                
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="me-auto"># Off-Topics</span>{" "}
                                
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="me-auto"># Recruiting</span>{" "}
                                
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="me-auto"># Release</span>{" "}
                                
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="me-auto"># UX Design</span>{" "}
                                
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </Collapse>
                    </div>
                  </li>
                  <li>
                    <div className="custom-accordion">
                      <Link
                        className="text-body fw-medium py-1 d-flex align-items-center"
                        onClick={this.file}
                        to="#"
                      >
                        {" "}
                        Direct Messages{" "}
                        {/* <i className="mdi mdi-chevron-up accor-down-icon ms-auto"></i> */}
                        <i
                          className={
                            this.state.file_collapse
                              ? "mdi mdi-chevron-up accor-down-icon ms-auto"
                              : "mdi mdi-chevron-down accor-down-icon ms-auto"
                          }
                        />
                      </Link>
                      <Collapse isOpen={this.state.file_collapse}>
                        <div className="card border-0 shadow-none ps-2 mb-0">
                          <ul className="list-unstyled mb-0">
                            <li>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="me-auto">James Wells</span>
                              </Link>
                            </li>

                          </ul>
                        </div>
                      </Collapse>
                    </div>
                  </li>
                  
                </ul>
              </div>

            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}
