import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import Select from "react-select"
import {
  Table,
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Nav,
  Button,
  NavItem,
  NavLink,
  Form,
  FormGroup,
  FormText,
  Row,
  Progress,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap"

import { Link, withRouter, Redirect } from "react-router-dom"
import { Editor } from "react-draft-wysiwyg"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import ModalVideo from "react-modal-video"
import "react-modal-video/scss/modal-video.scss"

// import ContactList from './ContactList/contacts-list';
// import ContactGrid from './ContactList/contacts-grid';
import MyEventCalendar from './MyEventCalendar/MyEventCalendar';
import classnames from "classnames"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import axios_config from "../../helpers/axios_config"

//
import PropTypes from "prop-types"
import { connect } from "react-redux"
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"

import images from "assets/images"

import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "react-loader-spinner";

const option_project_priority_group = [
  {
    options: [
      { label: "Low", value: 0 },
      { label: "Medium", value: 1 },
      { label: "High", value: 2 }
    ]
  },

]

const option_task_type = [
  {
    options: [
      { label: "Subtask", value: 1 },
      { label: "Parent", value: 2 },
    ]
  },

]
class Projectnew extends Component {
  constructor(props) {
    super(props)
    let create_task = false
    let event_create_in = false
    let milestone_create = false

    this.state = {
      visible: false,
      modal_standard: false,
      modal_fullscreen: false,
      modal_large: false,
      modal_xlarge: false,
      modal_small: false,
      modal_center: false,
      modal_scroll: false,
      modal_backdrop: false,
      modal_task: false,
      activeTab: "1",
      activeTab1: "5",
      activeTab2: "9",
      activeTab3: "13",
      verticalActiveTab: "1",
      verticalActiveTabWithIcon: "1",
      customActiveTab: "1",
      customIconActiveTab: "1",
      activeTabJustify: "5",
      col1: true,
      col2: false,
      col3: false,
      col11: true,
      col22: false,
      col33: false,
      col4: false,
      col5: false,
      col6: false,
      all_team_projects: [],
      task_modal_task: false,
      milestone_standard: false,
      schedule_standard: false,


      // Create Task
      task_milestone_name: "",
      task_name: "",
      task_type: "",
      task_parent: "",
      task_desc: "",
      task_priority: "",
      task_members: [],
      task_duedate: "",
      task_startDate: "",
      selectedFiles: '',
      projects: [],
      image: null,
      create_task,

      //Event Schedule
      event_project_name: '',
      event_name: '',
      event_desc: '',
      event_time: '',
      event_date: '',
      event_members_value: [],
      get_today_event: '',
      event_startDate: '',
      event_endDate: '',
      event_startTime: '',
      event_endTime: '',
      selectTaskMemberNameOptions: '',
      event_create_in,

      //Mielstone
      milestone_project_name: '',
      milestone_desc: '',
      milestone_amount: '',
      milestone_startDate: '',
      milestone_create,
      selectMileTeamNameOptions:'',


      //AllMileTask
      all_mile_task:'',

      loading: false,
    }
    this.toggle = this.toggle.bind(this)

    this.t_col1 = this.t_col1.bind(this)
    this.t_col2 = this.t_col2.bind(this)
    this.t_col3 = this.t_col3.bind(this)
    this.t_col4 = this.t_col4.bind(this)
    this.t_col5 = this.t_col5.bind(this)
    this.t_col6 = this.t_col6.bind(this)
    this.t_col11 = this.t_col11.bind(this)
    this.t_col22 = this.t_col22.bind(this)
    this.t_col33 = this.t_col33.bind(this)


    this.tog_standard = this.tog_standard.bind(this)
    this.tog_fullscreen = this.tog_fullscreen.bind(this)
    this.tog_xlarge = this.tog_xlarge.bind(this)
    this.tog_large = this.tog_large.bind(this)
    this.tog_small = this.tog_small.bind(this)
    this.tog_center = this.tog_center.bind(this)
    this.tog_scroll = this.tog_scroll.bind(this)
    this.tog_backdrop = this.tog_backdrop.bind(this)

    //Task
    this.task_tog_task = this.task_tog_task.bind(this)
    this.handleTaskMilestoneNameChange = this.handleTaskMilestoneNameChange.bind(this)
    this.handleTaskMemberChange = this.handleTaskMemberChange.bind(this);
    this.onFileChange = this.onFileChange.bind(this)
    this.handleTeamDescChange = this.handleTeamDescChange.bind(this);
    this.handleTaskStartDateChange = this.handleTaskStartDateChange.bind(this);
    this.handleTaskDueDateChange = this.handleTaskDueDateChange.bind(this);
    this.handleTaskSelectGroup = this.handleTaskSelectGroup.bind(this)
    this.handleTaskNameChange = this.handleTaskNameChange.bind(this)
    this.taskFormSubmit = this.taskFormSubmit.bind(this)
    this.handleTaskTypeSelectGroup = this.handleTaskTypeSelectGroup.bind(this)

    //Milestone
    this.mile_tog_standard = this.mile_tog_standard.bind(this)
    this.handleValidMilestoneSubmit = this.handleValidMilestoneSubmit.bind(this)
    this.handleMileValueChange = this.handleMileValueChange.bind(this)
    this.handleMilestoneNameChange = this.handleMilestoneNameChange.bind(this)
    this.handleMileStartDateChange = this.handleMileStartDateChange.bind(this)
    this.handleMileDescChange = this.handleMileDescChange.bind(this)



    //Schedule
    this.schedule_tog_standard = this.schedule_tog_standard.bind(this)
    this.handleValueChange = this.handleValueChange.bind(this)
    this.handleValidEventSubmit = this.handleValidEventSubmit.bind(this)
    this.handleEventDescChange = this.handleEventDescChange.bind(this);
    this.handleEventProjectNameChange = this.handleEventProjectNameChange.bind(this)
    this.handleEventStartDateChange = this.handleEventStartDateChange.bind(this)
    this.handleEventTimeStartChange = this.handleEventTimeStartChange.bind(this)
    this.handleEventDateEndChange = this.handleEventDateEndChange.bind(this)
    this.handleEventTimeEndChange = this.handleEventTimeEndChange.bind(this)
    this.handleEventMemberChange = this.handleEventMemberChange.bind(this);

  }
  //Milestone Start
  mile_tog_standard() {

    this.setState(prevState => ({
      milestone_standard: !prevState.milestone_standard,
    }))
    this.removeBodyCss()
  }
  handleMileStartDateChange = (event) => {
    this.setState({ milestone_startDate: event[0] })

  };
  handleMileDescChange(event) {
    this.setState({ milestone_desc: event.blocks[0].text })
  }
  handleMileValueChange(event) {
    console.log(event.target)
    this.setState({ [event.target.name]: event.target.value })

  }

  async getMileProjectOptions(id) {
   
    // console.log("url_project_id", url_project_id)
    let team_id = id
    const res = await axios_config.post('/all-projects/', JSON.stringify({ 'teamid': team_id }))

    console.log("miledata", res)
    const data = res.data.data
    console.log(data)

    const options = data ? data.map(item => ({
      "value": item["project id"],
      "label": item["Project Name"]
    })) : ''
    console.log("project_options", options)
    this.setState({ selectMileTeamNameOptions: options })

  }

  handleMilestoneNameChange(e) {
    this.setState({ milestone_project_name: e.value })

  }

  handleValidMilestoneSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true
    })
    const { milestone_project_name, milestone_desc, milestone_amount, milestone_startDate } = this.state;
    function convert(str) {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [mnth, day, date.getFullYear()].join("-");
    }
    let start_date = convert(milestone_startDate)
    console.log(milestone_project_name)
    console.log(milestone_desc)
    console.log(milestone_amount)
    console.log(milestone_startDate)
    console.log(start_date)

    axios_config.post('/milestone/', {

      projectid: milestone_project_name,
      Milestonedescription: milestone_desc,
      amount: milestone_amount,
      date: start_date

    }).then(response => {
      console.log("resp create Milestone", response);
      if (response.data.success == "true") {
        console.log("Add Milestone Successfully");
        alert("Add Milestone Successfully")
        location.reload();
        this.setState({
          milestone_create: true,
          loading: false

        })

      } else {
        alert("Wrong Details")
      }
    })
      .catch(error => {
        console.log("milestone error", error);
      });

  }
  //Milestone End

  //Schedule Statrt
  schedule_tog_standard() {
    this.setState(prevState => ({
      schedule_standard: !prevState.schedule_standard,
    }))
    this.removeBodyCss()
  }

  handleEventMemberChange(event) {
    this.setState({ event_members_value: event })

  }

  handleEventStartDateChange = (event) => {
    this.setState({ event_startDate: event[0] })

  };
  handleEventTimeStartChange = (event) => {
    this.setState({ event_startTime: event[0] })

  };
  handleEventDateEndChange = (event) => {
    this.setState({ event_endDate: event[0] })

  };
  handleEventTimeEndChange = (event) => {
    this.setState({ event_endTime: event[0] })

  };

  handleEventDescChange = (event) => {
    this.setState({ event_desc: event.blocks[0].text })

  };

  async getEventMembersOptions() {
    const response = await axios_config.post('/all-users/', { usertype: 3 })
    const data = response.data.data
    const options = data.map(d => ({
      "value": d["user id"],
      "label": d["user firstname"]
    }))
    console.log("team name options", options);
    this.setState({ selectTaskMemberNameOptions: options })
  }

  handleValueChange(event) {

    this.setState({ [event.target.name]: event.target.value })
  }

  async getProjectOptions(id) {

    let team_id = id
    const res = await axios_config.post('/all-projects/', { teamid: team_id })
    console.log('res', res)
    const data = res.data.data
    console.log('calender Project', data)

    const options = data ? data.map(item => ({
      "value": item["project id"],
      "label": item["Project Name"]
    })) : ''
    console.log("team name options", options);

    this.setState({ selectProjectNameOptions: options })

  }


  handleEventProjectNameChange(e) {
    this.setState({ event_project_name: e.value })

  }
  handleValidEventSubmit = (event) => {
    event.preventDefault();

    this.setState({
      loading: true
    })

    const { event_project_name, event_name, event_desc, event_members_value, event_startDate, event_endDate, event_startTime, event_endTime } = this.state;

    function convert(str) {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day,].join("-");
    }

    let start_date = convert(event_startDate)
    let end_date = convert(event_endDate)

    let start_time = event_startTime.toString()
    let split_start_time = start_time.split(' ')
    let get_start_time = split_start_time[4]
    // console.log(get_start_time)

    let end_time = event_endTime.toString()
    let split_end_time = end_time.split(' ')
    let get_end_time = split_end_time[4]

    console.log("mememememe")
    console.log(event_members_value)


    // console.log(getFormattedString(event_startTime))


    // let add_time = event_time+':'+'00'
    // console.log(add_time)

    // let date_change = event_date.split("-")
    // var event_up_date_format = date_change[2]+'-'+date_change[1]+'-'+date_change[0]

    var arr_members_value = [];
    this.state.event_members_value.map((postData) => {
      arr_members_value.push(postData.value)
    })

    console.log(event_project_name)
    console.log(event_name)
    console.log(event_desc)
    console.log(arr_members_value)

    // console.log(event_startDate)
    console.log(start_date)
    console.log(get_start_time)
    // console.log(event_endDate)
    console.log(end_date)
    console.log(get_end_time)

    axios_config.post("/event/", {

      projectid: event_project_name,
      event: event_name,
      eventdescription: event_desc,
      eventtime: get_start_time,
      eventendtime: get_end_time,
      eventdate: start_date,
      eventenddate: end_date,
      membersid: arr_members_value,

    }
    ).then(response => {
      console.log("resp create event", response);

      if (response.data.success == "true") {
        console.log("Add Schedule Successfully");
        alert("Add Schedule Successfully")
        this.setState({
          event_create_in: true,
          loading: false

        })

      } else {
        alert("Wrong details")
        location.reload();
      }
    })
      .catch(error => {
        console.log("login error", error);
      });

  }
  //End Schedule


  //Create Task Handling


  task_tog_task() {
    this.setState(prevState => ({
      task_modal_task: !prevState.task_modal_task,
    }))
    this.removeBodyCss()
  }


  onFileChange = event => {

    this.setState({ selectedFiles: event.target.files[0] });

  };

  handleTeamDescChange = (event) => {
    this.setState({ task_desc: event.blocks[0].text })

  };

  handleTaskStartDateChange = (event) => {
    this.setState({ task_startDate: event[0] })

  };

  handleTaskDueDateChange = (event) => {
    this.setState({ task_duedate: event[0] })

  };

  handleTaskSelectGroup = priority_sel_event => {
    this.setState({ task_priority: priority_sel_event.value })
  }
  handleTaskNameChange(event) {

    this.setState({ [event.target.name]: event.target.value })
  }

  handleTaskTypeSelectGroup = task_type_event => {
    this.setState({ task_type: task_type_event.value })
  }

  async getMilestoneOptions() {
    const res = await axios_config.post('/all-milestone/')
    const data = res.data.data
    console.log(data)

    const options = data ? data.map(item => ({
      "value": item["milestone id"],
      "label": item["description"]
    })) : "";
    console.log("milestone name options", options);
    this.setState({ selectTeamMilestoneOptions: options })
  }

  handleTaskMilestoneNameChange(e) {
    this.setState({ task_milestone_name: e.value })

  }

  async getTaskMembersOptions() {

    const response = await axios_config.post('/all-users/', { usertype: 3 })
    const data = response.data.data
    const options = data.map(d => ({
      "value": d["user id"],
      "label": d["user firstname"]
    }))
    console.log("team name options", options);
    this.setState({ selectTaskMemberNameOptions: options })
  }

  handleTaskMemberChange(event) {
    this.setState({ task_members: event })

  }

  taskFormSubmit(event) {

    event.preventDefault();
    this.setState({
      loading: true
    })

    const { task_milestone_name, task_name, task_type, task_parent, task_desc, task_priority, task_members, task_startDate,
      task_duedate, selectedFiles } = this.state;

    function convert(str) {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day,].join("-");
    }

    let start_date = convert(task_startDate)
    let due_date = convert(task_duedate)
    let update_metakey = selectedFiles.name
    let metavalue = selectedFiles.type

    var arr_members_value = [];
    this.state.task_members.map((postData) => {
      arr_members_value.push(postData.value)
    })

    console.log(task_milestone_name)
    console.log(task_name)
    console.log(task_type)
    console.log(task_parent)
    console.log(task_desc)
    console.log(task_priority)
    console.log(arr_members_value)
    console.log(start_date)
    console.log(due_date)
    console.log(update_metakey)
    console.log(metavalue)

    let bodyFormData = new FormData();

    bodyFormData.append("Milestoneid", task_milestone_name);
    bodyFormData.append("task", task_name);
    bodyFormData.append("tasktype", task_type);
    bodyFormData.append("taskparent", 2);
    bodyFormData.append("taskdescription", task_desc);
    bodyFormData.append("priority", task_priority);
    bodyFormData.append("membersid", arr_members_value);
    bodyFormData.append("metakey", update_metakey);
    bodyFormData.append("metavalue", selectedFiles);
    bodyFormData.append("start_date", start_date);
    bodyFormData.append("due_date", due_date);
    bodyFormData.append("status", 0);

    axios_config({
      method: "post",
      url: "/task/",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then(response => {
      console.log("resp create task", response);
      if (response.data.success == "true") {
        console.log("Add Task Successfully");
        alert("Added Task Successfully")
        this.setState({
          create_task: true,
          loading: false

        })

      } else {
        alert("Wrong details")
        location.reload();
      }
    })
      .catch(error => {
        console.log("login error", error);
      });
  }

  //End Create Task Handling



  t_col1() {
    this.setState({ col1: !this.state.col1 })
  }

  t_col2() {
    this.setState({ col2: !this.state.col2 })
  }

  t_col3() {
    this.setState({ col3: !this.state.col3 })
  }

  t_col11() {
    this.setState({ col11: !this.state.col11 })
  }

  t_col22() {
    this.setState({ col22: !this.state.col22 })
  }

  t_col33() {
    this.setState({ col33: !this.state.col33 })
  }
  t_col4() {
    this.setState({ col4: !this.state.col4 })
  }
  t_col5() {
    this.setState({ col5: !this.state.col5 })
  }
  t_col6() {
    this.setState({ col6: !this.state.col6 })
  }




  tog_standard() {
    this.setState(prevState => ({
      modal_standard: !prevState.modal_standard,
    }))
    this.removeBodyCss()
  }

  removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  tog_fullscreen() {
    this.setState(prevState => ({
      modal_fullscreen: !prevState.modal_fullscreen,
    }))
    this.removeBodyCss()
  }

  tog_backdrop() {
    this.setState(prevState => ({
      modal_backdrop: !prevState.modal_backdrop,
    }))
    this.removeBodyCss()
  }

  tog_large() {
    this.setState(prevState => ({
      modal_large: !prevState.modal_large,
    }))
    this.removeBodyCss()
  }

  tog_xlarge() {
    this.setState(prevState => ({
      modal_xlarge: !prevState.modal_xlarge,
    }))
    this.removeBodyCss()
  }

  tog_small() {
    this.setState(prevState => ({
      modal_small: !prevState.modal_small,
    }))
    this.removeBodyCss()
  }

  tog_center() {
    this.setState(prevState => ({
      modal_center: !prevState.modal_center,
    }))
    this.removeBodyCss()
  }

  tog_scroll() {
    this.setState(prevState => ({
      modal_scroll: !prevState.modal_scroll,
    }))
    this.removeBodyCss()
  }

  show() {
    this.setState({ visible: true })
  }

  hide() {
    this.setState({ visible: false })
  }



  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }
  //All Milestone Data 
    async getAllMilestone(){
      let url_project_id = this.props.match.params.id
      const response =  await axios_config.post('all-miletask/',{project_id:url_project_id})
      console.log('mile_task', response)
      const data = response.data.data
 
      console.log('mile_opt',data )
      this.setState({ all_mile_task: data })
    }
    //End Milestone Data

  async allTeamProjects() {

    const res = await axios_config.post('all-team_projects/')
    console.log('all_team_project_result', res)

    let get_all_result = res.data.data
    console.log('get_all_result', get_all_result)
    this.setState({ all_team_projects: get_all_result })

  }

  componentDidMount() {
    this.allTeamProjects()

    //Task
    this.getMilestoneOptions()
    this.getTaskMembersOptions()

    //Schedule
    this.getProjectOptions();
    this.getEventMembersOptions()

    //Milestone
    this.getMileProjectOptions();

    //AllMileTask
    this.getAllMilestone();


  }
  render() {
    var get_team_projects = this.state.all_team_projects

    if (this.state.loading) {
      return <Loader
        style={{ position: "fixed", top: "50%", left: "60%", transform: "translate(-50%, -50%)" }}
        type="Circles"
        color="#00BFFF"
        height={100}
        width={100}
        timeout={9000} //3 secs
      />
    }
    if (this.state.create_task || this.state.event_create_in) {
      return <Redirect to="/teams"> </Redirect>
    }
    if (this.milestone_create) {
      return <Redirect to="/teams"> </Redirect>
    }
    const mile_data = this.state.all_mile_task
    return (
      <React.Fragment>
        <div className="page-content">

          <Container fluid={true} className="projectpage project-newpage">

            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <Row className="project-task-head" >
                      <Col lg={12}>
                        <div className="project-head">
                          <h1> Projects - Project Tittle</h1>
                        </div>
                        <div className="add-new-btn">
                          <button
                            type="button"
                            // onClick={this.tog_standard}
                            onClick={() => {
                              this.mile_tog_standard();
                            }}
                            className="btn btn-primary add-prjt-btn"
                            data-toggle="modal"
                            data-target="#myModal"
                          >
                            Add New <i class="bx bx-plus"></i>
                            
                          </button>

                        </div>
                        <Modal
                                  isOpen={this.state.milestone_standard}
                                  toggle={this.mile_tog_standard}
                                  centered={true}
                                >
                                  <div className="modal-header">
                                    <h5 className="modal-title mt-0" id="myModalLabel">
                                      <span class="popup-head"><i class="bx bx-plus"></i> Create a new Milestone </span>
                                    </h5>
                                    <button
                                      type="submit"
                                      className="btn btn-primary right-align"
                                      onClick={this.handleValidMilestoneSubmit}
                                    >
                                      Done

                                    </button>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        this.setState({ milestone_standard: false })
                                      }
                                      className="close"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <div className="modal-body">

                                    <Form>
                                      <FormGroup row>
                                        <Col sm={12}>
                                        <Select
                                        options={this.state.selectMileTeamNameOptions}
                                        onChange={this.handleMilestoneNameChange}
                                        classNamePrefix="select2-selection"
                                        validate={{
                                          required: { value: true },
                                        }}
                                      />
                                        </Col>
                                      </FormGroup>
                                      <FormGroup row>
                                        <Col sm={12}>
                                          <Editor
                                            placeholder="Schedule description"
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            toolbar={{
                                              options: ['inline', 'history', "emoji"],
                                              inline: { inDropdown: false },
                                              history: { inDropdown: true },
                                              emoji: { inDropdown: true }
                                            }}
                                            name="milestone_desc"
                                            placeholder="Enter Milestone Description..."
                                            value={this.state.milestone_desc}
                                            onChange={this.handleMileDescChange}
                                          />
                                        </Col>
                                      </FormGroup>
  
                                      <FormGroup row>                
                                          <Col sm={12}>
                                              <input
                                            name="milestone_amount"
                                            type="text"
                                            errorMessage="Invalid name"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            className="form-control"
                                            placeholder="Enter Milestone Amount..."
                                            value={this.state.milestone_amount}
                                            onChange={this.handleMileValueChange}

                                              />
                                          </Col>
                                      </FormGroup>
                                      <br />
                                      <FormGroup row>
                                        <Col lg={12}>
                                          <div className="input-group date-pick">
                                            <Flatpickr
                                              className="form-control d-block"
                                              placeholder="Starting date"
                                              name="milestone_startDate"
                                              className="form-control d-block"
                                              value={this.state.task_startDate}
                                              onChange={this.handleMileStartDateChange}
                                              options={{
                                                altInput: true,
                                                altFormat: "F j, Y",
                                                dateFormat: "Y-m-d"
                                              }}

                                            />
                                            <span className="date-icon"><i class="bx bx-calendar"></i></span>
                                          </div>
                                        </Col>
                                      </FormGroup>
                                    </Form>

                                  </div>
                                  <div className="modal-footer ftr-none">

                                  </div>
                                </Modal>

                      </Col>
                    </Row>


                    <div className="project-head">
                      <h2><i class="dripicons-folder"></i> Project Management</h2>
                    </div>


                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "1",
                          })}
                          onClick={() => {
                            this.toggle("1")
                          }}
                        >
                          <i class="bx bx-location-plus"></i>List
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "3",
                          })}
                          onClick={() => {
                            this.toggle("3")
                          }}
                        >
                          <i class="dripicons-list"></i>Timeline
                        </NavLink>

                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "5",
                          })}
                          onClick={() => {
                            this.toggle("5")
                          }}
                        >
                          <i class="mdi mdi-calendar-month-outline"></i>Calendar
                        </NavLink>

                      </NavItem>

                    </Nav>

                    <ul className="project-task-rghtlink">
                      <li className="white-bg">8 members</li>
                      <li className="grey-bg"><i class="fas fa-calendar-alt"></i>Due date: 17 th May 2021</li>
                      <li className="grey-bg"><i class="dripicons-arrow-thin-up"></i>Priority: High </li>
                    </ul>

                    <TabContent activeTab={this.state.activeTab} className="p-3 text-muted">
                      <TabPane tabId="1">
                        <Row>
                          <Col lg={12}>



                            <div className="accordion" id="accordion">
                              <div className="accordion-item">
                              {mile_data?mile_data.map((item) => (<>
                                <h2 className="accordion-header" id="headingOne">
                                  <button className="accordion-button fw-medium" type="button" onClick={this.t_col1} style={{ cursor: "pointer" }}>
                                    {item['name']}

                                  </button>
                                </h2>
                                <div className="sub-popup-sect">
                                  <div className="sub-popup">
                                    <button
                                      type="button"
                                      onClick={this.tog_standard}
                                      className=" add-prjt-btn"
                                      data-toggle="modal"
                                      data-target="#myModal"
                                    >
                                      <i class="bx bx-plus"></i>Task
                                    </button>

                                  </div>
                                </div>

                                <Collapse isOpen={this.state.col1} className="accordion-collapse">
                                  <div className="accordion-body">
                                    <div className="text-muted">
                                      {/* <ContactList />  */}
                                      <div className="table-responsive h-100" data-simplebar  >
                                        <Table className="table mb-0 ">
                                          <thead>
                                            <tr>
                                              <th>Name</th>
                                              <th>Asignees</th>
                                              <th>Due Date</th>
                                              <th>Priority</th>
                                              <th>Progress</th>
                                              <th>Status</th>
                                              <th>Options</th>
                                            </tr>
                                          </thead>
                                          {item['task_data'] ? item['task_data'].map((data, index) => (
                                            <>
                                          <tbody>
                                            <tr class="done-tr">
                                              <td>{data['name']}</td>
                                              <td><div class="avatar-group">
                                                <div class="avatar-group-item">
                                                  <a class="d-inline-block" href="/tasks-list"><div class="avatar-xs"><span class="avatar-title rounded-circle bg-success text-white font-size-16">
                                                    {data['member_list'] ? data['member_list'].map((member, index) => (
                                                        member['task_member']
                                                    
                                                    )) : 'No Member Found'}</span></div></a>
                                                </div>
                                                {/* <div class="avatar-group-item plus-avatar-group">
                                                  <a class="d-inline-block" href="/tasks-list"><div class="avatar-xs"><span class="avatar-title rounded-circle bg-secondary text-white font-size-16"><i class="bx bx-plus"></i></span></div></a>
                                                </div> */}
  
                                              </div>
                                              </td>
                                              <td><span class="date-text"><i className="fas fa-calendar-alt"></i> {data['due date']}</span></td>
                                              <td><span class={data['priority'] == 0 ? 'Low-text' : (data['priority'] == 1 ? "Medium-text" : "High-text")}><i class={data['priority'] == 0 ? 'dripicons-arrow-thin-down' : (data['priority'] == 1 ? "dripicons-arrow-thin-right" : "dripicons-arrow-thin-up")}></i>{data['priority'] == 0 ? 'Low' : (data['priority'] == 1 ? "Medium" : "High")}</span></td>
                                              <td>  <div className="progress-bar">
                                                <Progress color="primary" value={50}>
                                                  <span class="percentage-txt">{data['progress']}%</span>
                                                </Progress>
                                              </div></td>
                                              <td><span class={data['status'] == 0 ? 'badge-soft-success badge badge-success badge-pill done-color' :(data['status']==1?'badge-soft-success badge badge-success badge-pill progress-color':'badge-soft-success badge badge-success badge-pill todo-color')}>{data['status'] == 0 ? 'Done' : (data['status'] == 1 ? "Progress" : "ToDo")}</span></td>
                                                <td>
                                                <div class="d-flex gap-3 options"><a class="text-success" href="/Projecttask"><i class="bx bx-check" id="edittooltip"></i></a><a class="text-danger" href="/Projecttask"><i class="bx bx-time-five" id="deletetooltip"></i></a><a class="account-add" href="/Projecttask"><i class="mdi mdi-account-plus-outline" id="deletetooltip"></i></a><a class="account-delete" href="/Projecttask"><i class="bx bx-trash" id="deletetooltip"></i></a></div>
                                              </td>
                                            </tr>
                                          </tbody>
                                          </>)) : 'No Task Found'}
                                        </Table>
                                        
                                      </div>
                                    </div>
                                  </div>
                                </Collapse>
                                </>)) : 'No Record Found'}
                              </div>
                              
                            </div>
                          </Col>
                        </Row>
                      </TabPane>





                      <TabPane tabId="2">

                        <Row>
                          <Col lg={12}>



                            <div className="accordion" id="accordion">
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                  <button className="accordion-button fw-medium" type="button" onClick={this.t_col11} style={{ cursor: "pointer" }}>
                                    Project of Team A
                                  </button>
                                </h2>


                                <Collapse isOpen={this.state.col11} className="accordion-collapse">
                                  <div className="accordion-body">
                                    <div className="text-muted">
                                      {/* <ContactGrid /> */}
                                    </div>
                                  </div>
                                </Collapse>
                              </div>
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwo">
                                  <button className="accordion-button fw-medium collapsed" type="button" onClick={this.t_col22} style={{ cursor: "pointer" }}>
                                    Project of Team B
                                  </button>
                                </h2>

                                <Collapse isOpen={this.state.col22} className="accordion-collapse">
                                  <div className="accordion-body">
                                    <div className="text-muted">
                                      Team B
                                    </div>
                                  </div>
                                </Collapse>
                              </div>
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThree">
                                  <button className="accordion-button fw-medium collapsed" type="button" onClick={this.t_col33} style={{ cursor: "pointer" }}>
                                    Project of Team C
                                  </button>
                                </h2>
                                <Collapse isOpen={this.state.col33} className="accordion-collapse">
                                  <div className="accordion-body">
                                    <div className="text-muted">
                                      Team C
                                    </div>
                                  </div>
                                </Collapse>
                              </div>
                            </div>
                          </Col>
                        </Row>

                      </TabPane>
                      <TabPane tabId="3">

                        <Row>
                          <Col lg={12}>

                          </Col>
                        </Row>

                      </TabPane>
                      <TabPane tabId="4">

                        <Row>
                          <Col lg={12}>

                          </Col>
                        </Row>

                      </TabPane>
                      <TabPane tabId="5" className="calendar-tab-pane">

                        <Row>
                          <Col lg={12}>
                            <MyEventCalendar />
                          </Col>
                        </Row>

                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>


            </Row>


            <Row>
              <Card>
                <CardBody>
                  <Row>


                  </Row>
                </CardBody>
              </Card>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default Projectnew
