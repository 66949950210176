import React, { Component } from "react";
import { Button,  
    Col,  Form, FormGroup, Label, Input, FormText, InputGroup } from 'reactstrap';


import './CalendarLeft.scss';

const CalendarLeft = () => {
    return <div className="cal-left-block">
        <div>
            <h3 className="head">Calender</h3>

            <Form>
                <FormGroup row>                
                    <Col sm={12}>
                        <Input type="email" name="email"  placeholder="Search events, meetings" />
                    </Col>
                </FormGroup>
            </Form>
            <h4 className="sub-head mt-3">Today</h4>
            <div className="meet-sect">
                    <span><i class="mdi mdi-calendar-month-outline"></i> </span><p>
                    UX Meeting with Kathryn & rest of the team
                    <div className="text-success text-center">Morning - 10:30 AM</div>
                     </p>
                     </div>
                      <div className="meet-sect">
                     <span><i class="bx bx-video"></i> </span><p>
                    Video call with the Server maintenance team
                    <div className="text-success text-center">Morning - 11:30 AM</div>
                    </p>
                    </div>
                     <div className="meet-sect">
                     <span><i class="bx bx-bell bx-tada"></i> </span><p>
                    Online webinar schedule for client acquisition
                    <div className="text-success text-center">13:00 - 14:00</div>
                    </p>
            </div>
        </div>
    </div>
};

export default CalendarLeft;