import moment from 'moment';



export const mileStone = [
    {
        name: 'Milestone 1',
        start: moment('2021-09-07').subtract(4, "hours").toISOString(),
        end: moment('2021-09-09').add(4, "hours").toISOString(), 
        color:'#78d5fb',
        tasks: [
            {
                name: 'Task 1.1',
                start: moment().subtract(1, "hours").toISOString(),
                end: moment().add(1, "hours").toISOString(),        
                description: 'Lecture',                
            },
            {
                name: 'Task 1.2',
                start: moment().subtract(3, "hours").toISOString(),
                end: moment().add(4, "hours").toISOString(),        
                description: 'Lecture',                
            }
        ]
        
    },
    {
        name: 'Milestone 2',
        start:  moment().subtract(2, "days").toISOString(),
        end: moment().add(7, "days").toISOString(), 
        color:'#e887eb',
        tasks:[
            {
                name: 'Task 2.1',
                start: moment().subtract(5, "days").toISOString(),
                end: moment().subtract(2, "days").toISOString(),       
                description: 'Lecture',
                color:'#ad1f1f',
            },
            {
                name: 'Task 2.2',
                start: moment().subtract(2, "days").toISOString(),
                end: moment().add(7, "days").toISOString(),       
                description: 'Lecture',
                color:'#62c785',
            }
        ]
    }, 
   
]