import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import moment from "moment"
import { size } from "lodash"
import {
  Table,
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Media,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap"
import classnames from "classnames"
import { Editor } from "react-draft-wysiwyg"
//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"

//Import Images
import images from "../../assets/images"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  addMessage,
  getChats,
  getContacts,
  getGroups,
  getMessages,
} from "../../store/actions"

class Chat extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentRoomId: 1,
      currentUser: {
        name: "Henry Wells",
        isActive: true,
      },
      notification_Menu: false,
      search_Menu: false,
      settings_Menu: false,
      other_Menu: false,
      activeTab: "1",
      Chat_Box_Username: "Steven Franklin",
      Chat_Box_User_Status: "online",
      Chat_Box_User_isActive: false,
      curMessage: "",
    }
    this.messageBox = null
  }

  componentDidMount() {
    const { onGetChats, onGetGroups, onGetContacts, onGetMessages } = this.props
    const { currentRoomId } = this.state
    onGetChats()
    onGetGroups()
    onGetContacts()
    onGetMessages(currentRoomId)
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { messages } = this.props
    if (size(messages) !== size(prevProps.messages)) {
      this.scrollToBottom()
    }
  }

  toggleNotification = () => {
    this.setState(prevState => ({
      notification_Menu: !prevState.notification_Menu,
    }))
  }

  //Toggle Chat Box Menus
  toggleSearch = () => {
    this.setState(prevState => ({
      search_Menu: !prevState.search_Menu,
    }))
  }

  toggleSettings = () => {
    this.setState(prevState => ({
      settings_Menu: !prevState.settings_Menu,
    }))
  }

  toggleOther = () => {
    this.setState(prevState => ({
      other_Menu: !prevState.other_Menu,
    }))
  }

  toggleTab = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  //Use For Chat Box
  userChatOpen = (id, name, status, roomId) => {
    const { onGetMessages } = this.props
    this.setState({ Chat_Box_Username: name, currentRoomId: roomId })
    onGetMessages(roomId)
  }

  addMessage = (roomId, sender) => {
    const { onAddMessage } = this.props
    const message = {
      id: Math.floor(Math.random() * 100),
      roomId,
      sender,
      message: this.state.curMessage,
      createdAt: new Date(),
    }
    this.setState({ curMessage: "" })
    onAddMessage(message)
  }

  scrollToBottom = () => {
    if (this.messageBox) {
      this.messageBox.scrollTop = this.messageBox.scrollHeight + 1000
    }
  }

  onKeyPress = e => {
    const { key, value } = e
    const { currentRoomId, currentUser } = this.state
    if (key === "Enter") {
      this.setState({ curMessage: value })
      this.addMessage(currentRoomId, currentUser.name)
    }
  }

  render() {
    const { chats, groups, contacts, messages } = this.props
    const { currentRoomId, currentUser } = this.state

    return (
      <React.Fragment>

                <div className="chat-page">



                  <div className="chat-leftsidebar">
                       <div className="chat-leftsidebar-first">
                       <div className="breadcrumb-sect">
                          <ul>
                            <li><Link to="#">Team A</Link></li>
                            <li><Link to="#">Project Tittle A</Link></li>
                            <li><Link to="#">Milestone 1</Link></li>
                            <li><Link to="#">Task 1</Link></li>
                          </ul>
                        </div>
                        <div className="create-sect">
                            <ul>
                              <li>
                                <span>Created</span>
                                 Mar 1 2.00 pm 
                              </li>
                              <li>
                                <span>Due date</span>
                                 <span className="blue-text">10/3/2021</span>
                              </li>
                              <li>
                                <Link to="#" className="creat-done" >Done</Link>
                              </li>
                            </ul>
                        </div>
                         <div className="chatleft-task">
                            <h2 className="chat-task-h2">Task 1</h2>
                            <div className="form-group editor-wrap">
                                <Editor
                                  placeholder="Schedule description"
                                  toolbarClassName="toolbarClassName"
                                  wrapperClassName="wrapperClassName"
                                  editorClassName="editorClassName"
                                  toolbar={{
                                    options: ['inline', 'history', "emoji"],
                                    inline: { inDropdown: false },
                                    history: { inDropdown: true },
                                    emoji: { inDropdown: true }
                                  }}
                                  name='project_desc'
                                  value={this.state.project_desc}
                                  onChange={this.handleProjectDescChange}

                                />
                                <ul class="list-inline mb-0 editor-ions"><li class="list-inline-item"><a href="/chat"><i class="mdi mdi-format-text-rotation-none" id="Emojitooltip"></i></a></li> <li class="list-inline-item"><a href="/chat"><i class="mdi mdi-link-variant" id="Imagetooltip"></i></a></li> <li class="list-inline-item"><a href="/chat"><i class=" bx bx-link-alt" id="Filetooltip"></i></a></li><li class="list-inline-item"><a href="/chat"><i class="bx bxs-happy" id="Filetooltip"></i></a></li> </ul>
                            </div>
                         </div>
                         <div className="chatleft-subtask">
                           <h2 className="chat-task-h2">Subtasks <span className="blue-bg">2</span></h2>
                           <div className="newsubtask">
                                <input type="checkbox"   />New subtask
                            </div>
                           <div className="table-responsive">
                                <Table className="table mb-0">
                                  <tbody>
                                   <tr>
                                      <td><input type="checkbox"   /></td>
                                      <td>Some subtask 1</td>
                                      <td><i class="bx bx-check"></i></td>
                                      <td><i class="bx bx-trash"></i></td>
                                    </tr>
                                    <tr>
                                      <td><input type="checkbox"   /></td>
                                      <td>Some subtask 2</td>
                                      <td><i class="bx bx-check"></i></td>
                                      <td><i class="bx bx-trash"></i></td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                         </div>
                         <div className="chatleft-attach">
                           <h2 className="chat-task-h2">Attachments</h2>
                           <div className="table-responsive">
                                <Table className="table mb-0">
                                  <tbody>
                                   <tr>
                                      <td><input type="checkbox"   /></td>
                                      <td >somefile.jpg</td>
                                      <td className="grey-txt"> 16th  May 2021</td>
                                      <td className="grey-txt"> 2MB</td>
                                      <td><i class="bx bx-trash"></i></td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                         </div>
                         </div>
                          <div className="chatleft-dropfiles">
                          <span><i class="mdi mdi-cloud-upload-outline"></i>Drop files here to attach or</span>  <Link to="#" className="browse-link" >browse</Link> 
                          </div>
                  </div>




                  <div className="user-chat">
                    <Card>
                      <div className="border-bottom chat-head">
                        <Media>
                          <div className="chat-head-left">
                          <div className="align-self-center me-3">
                            <img
                              src={images.avatar1}
                              className="avatar-xs rounded-circle"
                              alt=""
                            />
                          </div>
                          <Media body>
                            <h5 className="font-size-15 mt-0 mb-1">
                              {currentUser.name}
                            </h5>
                            <p className="text-muted mb-0">
                              <i className="mdi mdi-circle text-success align-middle me-1" />
                              Active
                            </p>
                          </Media>
                          </div>
                          <div className="chat-head-right">
                            <ul className="chat-head-ul">
                              <li>
                               <Link to="#"><i class="bx bx-check" ></i></Link>
                              </li>
                              <li>
                                 <Link to="#"><i class="bx bx-time-five" ></i></Link>
                              </li>
                              <li>
                                 <Link to="#"><i class="mdi mdi-account-plus-outline" ></i></Link>
                              </li>
                              <li>
                                 <Link to="#"><i class="bx bx-trash" ></i></Link>
                              </li>
                            </ul>
                          </div>
                        </Media>
                      </div>
                      

                      <div className="chat-conver-outer">
                        <div className="chat-conversation p-3">
                          <ul className="list-unstyled mb-0">
                            <PerfectScrollbar
                              style={{ height: "486px" ,minHeight: "486px" }}
                              containerRef={ref => (this.messageBox = ref)}
                            >
                              <li>
                              <div className="chat-day-title right">
                                  <span className="title">Hello! How are you?</span>
                                </div>
                                <div className="chat-day-title left">
                                <div className="chat-img">
                                  <img src={images.avatar1} className="avatar-xs rounded-circle" alt=""/>
                                </div>
                            <span className="title">Iam fine! what are you going to do this summer?</span>
                                </div>
                                <div className="chat-day-title right">
                                  <span className="title">Iam going to this amazing trip to Greece in June</span>
                                </div>
                                <div className="chat-day-title left">
                                <div className="chat-img">
                                  <img src={images.avatar1} className="avatar-xs rounded-circle" alt=""/>
                                </div>
                                  <span className="title">dekhte hai</span>
                                </div>
                                <div className="chat-day-title right">
                                  <span className="title">Comment</span>
                                </div>
                                <div className="chat-day-title left">
                                  <div className="chat-img">
                                  <img src={images.avatar1} className="avatar-xs rounded-circle" alt=""/>
                                </div>
                                  <span className="title">Test</span>
                                </div>
                              </li>
                              {messages &&
                                messages.map(message => (
                                  <li
                                    key={"test_k2" + message.id}
                                    className={
                                      message.sender === currentUser.name
                                        ? "right"
                                        : ""
                                    }
                                  >
                                    <div className="conversation-list">
                                      <UncontrolledDropdown>
                                        <DropdownToggle
                                          href="#"
                                          className="dropdown-toggle"
                                          tag="a"
                                        >
                                          <i className="bx bx-dots-vertical-rounded" />
                                        </DropdownToggle>
                                        <DropdownMenu direction="right">
                                          <DropdownItem href="#">
                                            Copy
                                          </DropdownItem>
                                          <DropdownItem href="#">
                                            Save
                                          </DropdownItem>
                                          <DropdownItem href="#">
                                            Forward
                                          </DropdownItem>
                                          <DropdownItem href="#">
                                            Delete
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                      <div className="ctext-wrap">
                                        <div className="conversation-name">
                                          {message.sender}
                                        </div>
                                        <p>{message.message}</p>
                                        <p className="chat-time mb-0">
                                          <i className="bx bx-time-five align-middle me-1" />{" "}
                                          {moment(message.createdAt).format(
                                            "hh:mm"
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </li>
                                ))}
                            </PerfectScrollbar>
                          </ul>
                        </div>
                        <div className="p-3 chat-input-section">
                         <div className=" chat-input-section-outer">
                          <Row>
                            <Col>
                              <div className="position-relative">
                                <input
                                  type="text"
                                  value={this.state.curMessage}
                                  onKeyPress={this.onKeyPress}
                                  onChange={e => {
                                    this.setState({
                                      curMessage: e.target.value,
                                    })
                                  }}
                                  className="form-control chat-input"
                                  placeholder="Enter Message..."
                                />

                              </div>
                              
                            </Col>
                            <Col className="col-auto">
                              <Button
                                type="button"
                                color="primary"
                                onClick={() =>
                                  this.addMessage(
                                    currentRoomId,
                                    currentUser.name
                                  )
                                }
                                className="btn-rounded chat-send w-md"
                              >
                                <span className="d-none d-sm-inline-block me-2">
                                </span>{" "}
                                <i className="mdi mdi-send"></i>
                              </Button>
                            </Col>
                          </Row>

                            <Row className="chat-bottom-row">
                             <Col className="chat-bottom-icons">
                              <div className="chat-input-links">
                                  <ul className="list-inline mb-0">
                                    <li className="list-inline-item">
                                      <Link to="#">
                                        <i
                                          className="mdi mdi-format-text-rotation-none"
                                          id="Emojitooltip"
                                        ></i>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="Emojitooltip"
                                        >
                                          Emojis
                                        </UncontrolledTooltip>
                                      </Link>
                                    </li>{" "}
                                    <li className="list-inline-item">
                                      <Link to="#">
                                        <i
                                          className="mdi mdi-link-variant"
                                          id="Imagetooltip"
                                        ></i>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="Imagetooltip"
                                        >
                                          Images
                                        </UncontrolledTooltip>
                                      </Link>
                                    </li>{" "}
                                    <li className="list-inline-item">
                                      <Link to="#">
                                        <i
                                          className=" bx bx-link-alt"
                                          id="Filetooltip"
                                        ></i>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="Filetooltip"
                                        >
                                          Add Files
                                        </UncontrolledTooltip>
                                      </Link>
                                    </li>
                                     <li className="list-inline-item">
                                      <Link to="#">
                                        <i
                                          className="bx bxs-happy"
                                          id="Filetooltip"
                                        ></i>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="Filetooltip"
                                        >
                                          Add Files
                                        </UncontrolledTooltip>
                                      </Link>
                                    </li>{" "}

                                    
                                  </ul>
                                </div>
                            </Col>
                          </Row>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
      </React.Fragment>
    )
  }
}

Chat.propTypes = {
  chats: PropTypes.array,
  groups: PropTypes.array,
  contacts: PropTypes.array,
  messages: PropTypes.array,
  onGetChats: PropTypes.func,
  onGetGroups: PropTypes.func,
  onGetContacts: PropTypes.func,
  onGetMessages: PropTypes.func,
  onAddMessage: PropTypes.func,
}

const mapStateToProps = ({ chat }) => ({
  chats: chat.chats,
  groups: chat.groups,
  contacts: chat.contacts,
  messages: chat.messages,
})

const mapDispatchToProps = dispatch => ({
  onGetChats: () => dispatch(getChats()),
  onGetGroups: () => dispatch(getGroups()),
  onGetContacts: () => dispatch(getContacts()),
  onGetMessages: roomId => dispatch(getMessages(roomId)),
  onAddMessage: roomId => dispatch(addMessage(roomId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Chat)
