import React, { Component, useState, useEffect } from "react";
import {
    Button,
    Col, Form, FormGroup, Label, Input, FormText, InputGroup
} from 'reactstrap';


import './CalendarLeft.scss';
import Select from "react-select"
import axios_config from "../../../helpers/axios_config"

const CalendarLeft = () => {


    const [current_event, setCurrentTime] = useState([]);

  

    useEffect(async () => {
        let mem_id = localStorage.getItem("authid")
        const today = Date.now();
        let get_today = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(today);
        let date_split = get_today.split('/')
        let current_date = date_split[2] + "-" + date_split[0] + "-" + date_split[1]
        console.log("get_today", current_date)

        const response = await axios_config.post('todo-list/current_date/', { memberid: mem_id, current_date: current_date })
         console.log('hooks_cl', response)
        const data = response.data.data?.eventdata
        // console.log('hooks_cl calender Project', data)

        const options = data ? data.map(item => ({
            "today_event_name": item["eventname"],
            "today_event_date": item["event_date"],
            "today_event_time": item["event_time"],
            "today_event_end_date": item["event_end_date"],
            "today_event_end_time": item["event_end_time"],
        })) : ''
        console.log("hooks_cl today event data", options);
        setCurrentTime(options) 

    }, []);




    return <div className="cal-left-block">
        <div>
            <h3 className="head">Calender</h3>

            <Form>
                <FormGroup row>
                    <Col sm={12}>
                        <Input type="email" name="email" placeholder="Search events, meetings" />
                    </Col>
                </FormGroup>
            </Form>
            <h4 className="sub-head mt-3">Today</h4>
            {current_event ? current_event.map(item => (
            <div className="meet-sect">
                <span><i class="mdi mdi-calendar-month-outline"></i> </span><p>
                    {item.today_event_name}
                    <div className="text-success text-center"> {item.today_event_time} - {item.today_event_end_time}</div>
                </p>
            </div>
         )) : 'No Events'}
            {/* <div className="meet-sect">
                <span><i class="bx bx-video"></i> </span><p>
                    Video call with the Server maintenance team
                    <div className="text-success text-center">Morning - 11:30 AM</div>
                </p>
            </div>
            <div className="meet-sect">
                <span><i class="bx bx-bell bx-tada"></i> </span><p>
                    Online webinar schedule for client acquisition
                    <div className="text-success text-center">13:00 - 14:00</div>
                </p>
            </div> */}
        </div>
    </div>
};

export default CalendarLeft;