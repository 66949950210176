import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import Select from "react-select"
// import Picker from 'emoji-picker-react';
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
import InputEmoji from 'react-input-emoji'


import {
  Table,
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Nav,
  Button,
  NavItem,
  NavLink,
  Form,
  FormGroup,
  FormText,
  Row,
  Media,
  Progress,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Label,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap"
//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"

//Import Images
import { size } from "lodash"

import { Link, withRouter, Redirect } from "react-router-dom"
import { Editor } from "react-draft-wysiwyg"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import ModalVideo from "react-modal-video"
import "react-modal-video/scss/modal-video.scss"

// import ContactList from './ContactList/contacts-list';
// import ContactGrid from './ContactList/contacts-grid';
import MyEventCalendar from './MyEventCalendar/MyEventCalendar';
import TimeLineComponent from '../TimeLineComponent/TimeLineComponent';
import Chat from './Chat/Chat';
import Compose from './Compose/Compose';
// import Timelines from './Timelines/Timelines';
import classnames from "classnames"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import axios_config from "../../helpers/axios_config"

//
import PropTypes from "prop-types"
import { connect } from "react-redux"
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"

import images from "assets/images"

import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "react-loader-spinner";

const option_project_priority_group = [
  {
    options: [
      { label: "Low", value: 0 },
      { label: "Medium", value: 1 },
      { label: "High", value: 2 }
    ]
  },

]

const option_task_type = [
  {
    options: [
      { label: "Parent", value: 0 },
      { label: "Subtask", value: 1 },

    ]
  },

]
const option_status = [{
  options: [
    { label: "Done", value: 0 },
    { label: "Progress", value: 1 },
    { label: "ToDo", value: 2 },

  ]
},

]
class Projecttask extends Component {
  constructor(props) {

    super(props)
    let create_task = false
    let event_create_in = false
    let milestone_create = false

    this.state = {
      visible: false,
      modal_standard: false,
      modal_fullscreen: false,
      modal_large: false,
      modal_xlarge: false,
      modal_small: false,
      modal_center: false,
      modal_center1: false,
      modal_scroll: false,
      modal_backdrop: false,
      modal_task: false,
      activeTab: "1",
      activeTab1: "5",
      activeTab2: "9",
      activeTab3: "13",
      verticalActiveTab: "1",
      verticalActiveTabWithIcon: "1",
      customActiveTab: "1",
      customIconActiveTab: "1",
      activeTabJustify: "5",
      col1: true,
      col2: false,
      col3: false,
      col11: true,
      col22: false,
      col33: false,
      col4: false,
      col5: false,
      col6: false,
      all_team_projects: [],
      task_modal_task: false,
      milestone_standard: false,
      schedule_standard: false,


      // Create Task
      task_milestone_name: "",
      task_name: "",
      task_type: "",
      task_parent: "",
      task_progress: '',
      task_desc: "",
      task_priority: "",
      task_members: [],
      task_duedate: "",
      task_startDate: "",
      selectedFiles: '',
      projects: [],
      image: null,
      create_task,

      //Event Schedule
      event_project_name: '',
      event_name: '',
      event_desc: '',
      event_time: '',
      event_date: '',
      event_members_value: [],
      get_today_event: '',
      event_startDate: '',
      event_endDate: '',
      event_startTime: '',
      event_endTime: '',
      selectTaskMemberNameOptions: '',
      event_create_in,

      //Mielstone
      milestone_project_name: '',
      mile_name: '',
      milestone_desc: '',
      milestone_amount: '',
      milestone_startDate: '',
      selectMileTeamNameOptions: '',
      milestone_create,
      pass_task_id: '',
      all_mile_task: '',
      get_proj: [],


      loading: false,

      //Single Task
      model_single_task: false,
      single_team_data: [],
      getMonth: '',
      getTime: '',
      get_meta: [],
      meta_date: '',
      comment_msg: '',
      task_id: '',
      get_parent_data: '',
      create_subtask: '',
      meta_size: '',
      task_detail: [],
      selectTaskOptions: [],
      uploadFiles: null,
      user_id: '',
      comment_data: [],
      sub_task_data: [],
      user_select_opt: [],
      single_task_start_date:'',

      //Emoji
      setChosenEmoji: '',
      emoji_text: '',

      //Status Update
      modal_status: false,
      status_opt: [],

      //User Modal
      modal_user: false,
      user_opt: '',

      //Task Update Desc
      update_task_desc: '',

      //Deadline TaSK
      modal_task_deadline: false,
      task_startdate_update:'',
      task_duedate_update:'',

    }
    this.toggle = this.toggle.bind(this)

    this.t_col1 = this.t_col1.bind(this)
    this.t_col2 = this.t_col2.bind(this)
    this.t_col3 = this.t_col3.bind(this)
    this.t_col4 = this.t_col4.bind(this)
    this.t_col5 = this.t_col5.bind(this)
    this.t_col6 = this.t_col6.bind(this)
    this.t_col11 = this.t_col11.bind(this)
    this.t_col22 = this.t_col22.bind(this)
    this.t_col33 = this.t_col33.bind(this)


    this.tog_standard = this.tog_standard.bind(this)
    this.tog_fullscreen = this.tog_fullscreen.bind(this)
    this.tog_xlarge = this.tog_xlarge.bind(this)
    this.tog_large = this.tog_large.bind(this)
    this.tog_small = this.tog_small.bind(this)
    this.tog_center = this.tog_center.bind(this)
    this.tog_center1 = this.tog_center1.bind(this)
    this.tog_scroll = this.tog_scroll.bind(this)
    this.tog_backdrop = this.tog_backdrop.bind(this)

    //Task
    this.task_tog_task = this.task_tog_task.bind(this)
    this.handleTaskMilestoneNameChange = this.handleTaskMilestoneNameChange.bind(this)
    this.handleTaskMemberChange = this.handleTaskMemberChange.bind(this);
    this.onFileChange = this.onFileChange.bind(this)
    this.handleTeamDescChange = this.handleTeamDescChange.bind(this);
    this.handleTaskStartDateChange = this.handleTaskStartDateChange.bind(this);
    this.handleTaskDueDateChange = this.handleTaskDueDateChange.bind(this);
    this.handleTaskSelectGroup = this.handleTaskSelectGroup.bind(this)
    this.handleTaskNameChange = this.handleTaskNameChange.bind(this)
    this.taskFormSubmit = this.taskFormSubmit.bind(this)
    this.handleTaskTypeSelectGroup = this.handleTaskTypeSelectGroup.bind(this)

    //Milestone
    this.mile_tog_standard = this.mile_tog_standard.bind(this)
    this.handleMileNameChange = this.handleMileNameChange.bind(this)
    this.handleValidMilestoneSubmit = this.handleValidMilestoneSubmit.bind(this)
    this.handleMileValueChange = this.handleMileValueChange.bind(this)
    this.handleMilestoneNameChange = this.handleMilestoneNameChange.bind(this)
    this.handleMileStartDateChange = this.handleMileStartDateChange.bind(this)
    this.handleMileDescChange = this.handleMileDescChange.bind(this)



    //Schedule
    this.schedule_tog_standard = this.schedule_tog_standard.bind(this)
    this.handleValueChange = this.handleValueChange.bind(this)
    this.handleValidEventSubmit = this.handleValidEventSubmit.bind(this)
    this.handleEventDescChange = this.handleEventDescChange.bind(this);
    this.handleEventProjectNameChange = this.handleEventProjectNameChange.bind(this)
    this.handleEventStartDateChange = this.handleEventStartDateChange.bind(this)
    this.handleEventTimeStartChange = this.handleEventTimeStartChange.bind(this)
    this.handleEventDateEndChange = this.handleEventDateEndChange.bind(this)
    this.handleEventTimeEndChange = this.handleEventTimeEndChange.bind(this)
    this.handleEventMemberChange = this.handleEventMemberChange.bind(this);

    //Single Task Modal
    this.single_task_model = this.single_task_model.bind(this)
    this.commentSubmit = this.commentSubmit.bind(this)

    //Status Handle
    this.tog_status = this.tog_status.bind(this)
    this.handleStateOptionsChange = this.handleStateOptionsChange.bind(this)
    this.onKeyStatus = this.onKeyStatus.bind(this)

    //User Handle
    this.tog_user = this.tog_user.bind(this)
    this.submitUser = this.submitUser.bind(this)
    this.handleUserOptionsChange = this.handleUserOptionsChange.bind(this)

    //Handle Update Task Desc
    this.handleTaskUpdateDescChange = this.handleTaskUpdateDescChange.bind(this)

    //Handle Task Deadline
    this.tog_task_deadline = this.tog_task_deadline.bind(this)


  }

  //Start Deadline Update

  updateDeadline=(event)=>{

    event.preventDefault();
    this.setState({
      modal_task_deadline:false,
      task_startdate_update:'',
      task_duedate_update:'',
    })
    const {task_startdate_update, task_duedate_update, pass_task_id} = this.state

    function convert(str) {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day,].join("-");
    }

    let start_date = convert(task_startdate_update)
    let due_date = convert(task_duedate_update)

    console.log('start',start_date)
    console.log('end',due_date)

    axios_config.put('taskdeadlinedateupdate/', {

      "taskid": pass_task_id,
      "start_date": start_date,
      "due_date": due_date,
    }).then(response => {

      if (response.data.success == "true") {
        let msg = response.data.msg
        alert(msg)
        this.getSingleTask(pass_task_id);

      } else {
        let msg = response.data.msg
        alert(msg)
        this.getSingleTask(pass_task_id);

      }
    }).catch(error => {
      console.log("Task Deadline Update error", error);
    });

  }
  //End Deadline Update

  //Start Task Deadline
  tog_task_deadline() {
    this.setState(prevState => ({
      modal_task_deadline: !prevState.modal_task_deadline,
    }))
    this.removeBodyCss()
  }

  //End TAsk Deadline

  //Description Update

  handleTaskUpdateDescChange = (event) => {

    this.setState({ update_task_desc: event.blocks[0].text })

  };
  submitDescription = (event) => {

    event.preventDefault();
    this.setState({
      update_task_desc:''
    })

    const { update_task_desc, pass_task_id } = this.state

    axios_config.put('taskdescriptionupdate/', {

      "taskid": pass_task_id,
      "description": update_task_desc,
    }).then(response => {

      if (response.data.success == "true") {
        let msg = response.data.msg
        alert(msg)
        this.getSingleTask(pass_task_id);

      } else {
        let msg = response.data.msg
        alert(msg)
        this.getSingleTask(pass_task_id);

      }
    }).catch(error => {
      console.log("Task Description Update error", error);
    });

  }

  //End Description Update

  //User Update

  handleUserOptionsChange = (event) => {
    console.log('user', event)
    this.setState({ user_opt: event })

  }

  tog_user() {
    this.setState(prevState => ({
      modal_user: !prevState.modal_user,
    }))
    this.removeBodyCss()
  }

  submitUser(event) {

    event.preventDefault();

    this.setState({
      user_opt: '',
      modal_user: false

    })
    const { user_opt, pass_task_id } = this.state

    var arr_members_value = [];

    user_opt.map((postData) => {
      arr_members_value.push(postData.value)
    })

    axios_config.put('taskmemberupdate/', {

      "taskid": pass_task_id,
      "MembersId": arr_members_value,
      "flag": 'add'
    }).then(response => {

      if (response.data.success == "true") {
        console.log('done');

        let msg = response.data.msg
        alert(msg)
        console.log("Error");
        this.getSingleTask(pass_task_id);

      } else {
        let msg = response.data.msg
        alert(msg)
        this.getSingleTask(pass_task_id);

      }
    }).catch(error => {
      console.log("comment error", error);
    });

  }



  //End User Update

  //Status Update

  handleStateOptionsChange = (status_event) => {
    this.setState({ status_opt: status_event })

  }


  tog_status() {
    this.setState(prevState => ({
      modal_status: !prevState.modal_status,
    }))
    this.removeBodyCss()
  }

  onKeyStatus(event) {

    event.preventDefault();
    this.setState({
      status_opt: '',
      modal_status: false
    })
    const { status_opt, pass_task_id } = this.state

    console.log(status_opt)
    var status_opt_arr = [status_opt]
    var arr_members_value = [];

    status_opt_arr.map((postData) => {
      arr_members_value.push(postData.value)
    })




    let bodyFormData = new FormData();

    bodyFormData.append("taskid", pass_task_id);
    bodyFormData.append("status", arr_members_value);

    axios_config({
      method: "post",
      url: "/single_status_update/",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then(response => {


      if (response.data.success == "true") {

        this.getSingleTask(pass_task_id);
        console.log("Status Update Successfully");
        alert("Status Update Successfully")

      } else {
        console.log("Something Went Wrong");
        alert("Status Update Successfully")
        this.getSingleTask(pass_task_id);

      }
    }).catch(error => {
      console.log("Status Changed error", error);
    });




  }
  //End Status Update

  addEmoji = (e) => {
    console.log('e', e)
    let emoji = e.native;
    let emoji_name = e.name;
    this.setState({ emoji_text: e.name })
    this.setState({
      comment_msg: this.state.comment_msg + emoji
    });

    //   let sym = e.unified.split('-')
    // let codesArray = []
    // sym.forEach(el => codesArray.push('0x' + el))
    // let emoji = String.fromCodePoint(...codesArray)
    // this.setState({
    //   comment_msg: this.state.comment_msg + emoji+
    // })

  };

  onEmojiiClick = (event, emojiObject) => {
    // setChosenEmoji(emojiObject);
    if (emojiObject) {
      this.setState({ setChosenEmoji: emojiObject.emoji })


    }
  };

  //Start All Delete Task
  deleteAllTask() {
    this.setState({
      model_single_task: false
    })
    const { pass_task_id } = this.state
    //  var subt_id = [pass_task_id]
    if (confirm("Are You Sure, you want to delete this task")) {

      axios_config({
        method: "delete",
        url: "/delete-task/",
        data: { taskid: pass_task_id },
      }).then(response => {
        console.log('res', response)
        if (response.data.success == "true") {
          console.log("Delete Task Successfully");
          alert("Delete Task Successfully")
          this.getAllMilestone();
        } else {
          let msg = response.data.msg
          console.log(msg)
          alert(msg)
        }
      }).catch(error => {
        console.log("Delete Task error", error);
      });
    }

    // console.warn('Hitt All Delete Task', task_id)
  }
  //End All Delete Task

  //Start Delete Task
  deleteTask(t_id) {
    console.log('task_id', t_id)
    var subt_id = [t_id]
    if (confirm("Are You Sure, you want to delete this task")) {

      axios_config({
        method: "delete",
        url: "/delete-task/",
        data: { taskid: t_id },
      }).then(response => {
        console.log('res', response)
        if (response.data.success == "true") {
          console.log("Delete Task Successfully");
          alert("Delete Task Successfully")
          this.getAllMilestone();
        } else {
          let msg = response.data.msg
          console.log(msg)
          alert(msg)
        }
      }).catch(error => {
        console.log("Delete Task error", error);
      });
    }
  }
  //End Delete Task

  //Delete Subtask
  deleteSubtask(sub_task_id) {
    const { pass_task_id } = this.state
    var sub_id = []
    sub_id.push(sub_task_id)
    if (confirm("Are You Sure, you want to delete this subtask")) {

      axios_config({
        method: "delete",
        url: "/tasks-delete/",
        data: { tasksid: sub_id },
      }).then(response => {
        console.log('res', response)
        if (response.data.success == "true") {
          console.log("Delete Subtask Successfully");
          alert("Delete Subtask Successfully")
          this.getSingleTask(pass_task_id);
        } else {
          let msg = response.data.msg
          console.log(msg)
          alert(msg)
          // this.getSingleTask(pass_task_id);
        }
      }).catch(error => {
        console.log("Delete Subtask error", error);
      });
    }
  }

  //End Delete Subtask

  //Delete Task Detail
  deleteTaskdetail(task_detail_id) {
    const { pass_task_id } = this.state
    var detail_id = []
    if (confirm("Are You Sure, you want to delete this file")) {

      axios_config({
        method: "delete",
        url: "/delete-taskdetail/",
        data: { taskdetail_id: task_detail_id },
      }).then(response => {
        console.log('res', response)
        if (response.data.success == "true") {
          console.log("Delete Task Detail Successfully");
          alert("Delete Task Detail Successfully")
          this.getSingleTask(pass_task_id)
        } else {
          let msg = response.data.msg
          console.log(msg)
          alert(msg)
        }
      }).catch(error => {
        console.log("Delete Task Detail error", error);
      });
    }
  }

  //End Delete Task Detail

  FileUpload = (event, task_id) => {


    // event.target.name = null;

    this.setState({ uploadFiles: '', value: event.target.value, })
    console.log('ss', event, task_id)
    console.log('rr', event.target.files)
    const { uploadFiles } = this.state

    var metakey = uploadFiles?.name

    if (!uploadFiles) {
      alert('Please Upload File')
      this.setState({ uploadFiles: '' })
    } else {

      let bodyFormData = new FormData();

      bodyFormData.append("taskid", task_id);
      bodyFormData.append("metakey", metakey);
      bodyFormData.append("metavalue", uploadFiles);

      axios_config({
        method: "post",
        url: "/taskdetails/",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then(response => {


        if (response.data.success == "true") {

          this.getSingleTask(task_id);
          console.log("Add Task Successfully");
          alert("Added Task Successfully")

        } else {
          console.log("File Upload  Successfully");
          alert("File Upload  Successfully")
          this.getSingleTask(task_id);

        }
      }).catch(error => {
        console.log("Upload error", error);
      });

    }
  }

  //Start Single TAsk Modal
  single_task_model() {
    this.setState(prevState => ({
      model_single_task: !prevState.model_single_task,
    }))
    this.removeBodyCss()
  }


  // Start Create Subtask
  onKeyDown(event) {

    if (event.key === "Enter") {

      const { create_subtask, task_id } = this.state
      this.setState({ create_subtask: '' })
      if (!create_subtask) {
        alert('Please Enter Subtask')
      } else {
        let bodyFormData = new FormData();

        bodyFormData.append("Milestoneid", '');
        bodyFormData.append("task", create_subtask);
        bodyFormData.append("tasktype", 1);
        bodyFormData.append("taskparent", task_id);
        bodyFormData.append("taskdescription", '');
        bodyFormData.append("progress", 0);
        bodyFormData.append("priority", 0);
        bodyFormData.append("membersid", '');
        bodyFormData.append("metakey", '');
        bodyFormData.append("metavalue", '');
        bodyFormData.append("start_date", '');
        bodyFormData.append("due_date", '');
        bodyFormData.append("status", 2);

        axios_config({
          method: "post",
          url: "/task/",
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        }).then(response => {
          if (response.data.success == "true") {

            this.getSingleTask(task_id);
            console.log("Add Task Successfully");
            alert("Added Task Successfully")

          } else {
            let msg = response.data.msg
            console.log(msg)
            alert(msg)
            this.getSingleTask(task_id);
          }
        })
          .catch(error => {
            console.log("Subtask error", error);
          });
      }
    }
  }

  // End Create Subtask

  //File Download
  fileDownload = (file) => {
    if (file) {
      axios_config({
        method: "get",
        url: "/media/mypictures/" + file,
        responseType: 'arraybuffer',
      }).then((response) => {
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/octet-stream" })
        );
        const today = Date.now();
        let get_today = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(today);
        let date_split = get_today.split('/')
        let current_date = date_split[2] + "-" + date_split[0] + "-" + date_split[1] + '_' + Math.floor(Math.random() * (30 - 20)) + 20
        link.download = current_date + '_' + file; //or any other extension

        document.body.appendChild(link);

        link.click();
        setTimeout(function () {
          window.URL.revokeObjectURL(link);
        }, 200);
      })
        .catch((error) => { });

    } else {
      console.log('empty')
    }
  }
  //End File Download

  //Comment Submit

  // By Enter Comment Submit
  onKeyDownComment(event) {
    if (event.key === "Enter") {
      var task_user_id = localStorage.getItem("authid")
      this.setState({ comment_msg: '' })
      const { comment_msg, emoji_text, task_id } = this.state
      console.log('KEYDOWN', comment_msg, emoji_text)
      if (!comment_msg) {
        alert('Please Enter Comment')
      } else {

        axios_config.post('comment/', {

          "userid": task_user_id,
          "comment": comment_msg + emoji_text,
          "task_id": task_id
        }).then(response => {

          if (response.data.success == "true") {
            console.log('done');

            let msg = response.data.msg
            console.log("Add  Comment Successfully");

          } else {
            let msg = response.data.msg
            // alert(msg)
            this.getSingleTask(task_id);

          }
        }).catch(error => {
          console.log("comment error", error);
        });
      }
    }

  }
  commentSubmit() {

    const { comment_msg, task_id } = this.state
    console.log('cmdg', comment_msg)
    this.setState({
      comment_msg: '',

    })
    var task_user_id = localStorage.getItem("authid")


    axios_config.post('comment/', {

      "userid": task_user_id,
      "comment": comment_msg,
      "task_id": task_id
    }).then(response => {

      if (response.data.success == "true") {
        console.log('done');

        let msg = response.data.msg
        console.log("Add  Comment Successfully");

      } else {
        let msg = response.data.msg
        // alert(msg)
        this.getSingleTask(task_id);

      }
    }).catch(error => {
      console.log("comment error", error);
    });

  }
  //End Comment Submit

  async getSingleTask(id) {

    this.setState({ pass_task_id: id })
    //Size Format
    function bytesToSize(bytes) {
      var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes == 0) return '0 Byte';
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }

    //Date Format
    function dateFormat(str) {
      var months = {
        '01': 'January', '02': 'February', '03': 'March', '04': 'April', '05': 'May',
        '06': 'June', '07': 'July', '08': 'August', '09': 'September', '10': 'October',
        '11': 'November', '12': 'December',
      }
      var date = new Date(str),
        day = ("0" + (date.getDate()+ 1)).slice(-2);

      var mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        monthNumber = months[mnth];

      return [day, monthNumber, date.getFullYear(),].join("-");
    }

    //Time Function
    function tConvert(time) {
      // Check correct time format and split into components
      time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) { // If time format correct
        time = time.slice(1);  // Remove full string match value
        time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join(''); // return adjusted time or original string
    }

    var team_id = id
    this.setState({ task_id: team_id })
    const response = await axios_config.post('single-task/', { taskid: team_id })

    const data = response.data.data != null ? response.data.data : 'Not Intialized';
    console.log('data', data)

    //Get Task Status
    var def_task = option_status
    var task_status = data.status
    
    var status_arr = []
    var filter_status = def_task.map(op => {

      op.options.map(status => {
        if (status.value !== task_status) {

          status_arr.push(status)
        }
        this.setState({ status_opt: status_arr })
      })

    })
    // this.setState({user_select_opt:members})


    //Get Users
    var task_members = data.members

    const user_response = await axios_config.post('/all-users/', { usertype: 3 })
    const user_data = user_response.data.data
    const user_options = user_data.map(d => ({

      "value": d["user id"],
      "label": d["user firstname"]

    }))
    var members = []

    if (task_members) {
      var exmp = ''
      var filter_user = user_options ? user_options.filter(adduser => {

        task_members.filter(tm => {

          if (adduser.value == tm.member_id) {
            exmp = adduser
          } else {
          }

        })
        if (exmp.value !== adduser.value) {
          members.push({ value: adduser.value, label: adduser.label })
          this.setState({ user_select_opt: members })

        }

      }) : ''

    }


    //Task Detail
    var task_detail_data = data.task_detail

    //Comment Data
    var comment_data = data.comment

    //Subtask Data
    var subtask_data = data.subtask

    var user_id = localStorage.getItem("authid")
    this.setState({ user_id: user_id })

    //SubTasks
    if (subtask_data) {

      var sub_detail = subtask_data ? subtask_data.map(item => ({

        'sub_id': item['task_id'],
        'sub_name': item['name'],

      })) : 'No Files'
      this.setState({ sub_task_data: sub_detail })
    }
    //Task Details
    if (task_detail_data) {
      var task_detail = task_detail_data ? task_detail_data.map(item => ({

        'id': item['id'],
        'metakey': item['metakey'],
        'date': dateFormat(item['date']),
        'size': bytesToSize(item['size'])
      })) : 'No Files'
      this.setState({ task_detail: task_detail })
    }

    //Comments
    if (comment_data) {
      var comment_filter = comment_data ? comment_data.map(comment => ({
        'comment_id': comment['comment_id'],
        'comment_name': comment['comment_name'],
        'comment_user_id': comment['comment_user_id'],
        'comment_date': dateFormat(comment['comment_date']),
        'comment_time': tConvert(comment['comment_time'].split(':')[0] + ':' + comment['comment_time'].split(':')[1]),

      })) : 'No Comments'


      this.setState({ comment_data: comment_filter })

//Task Start & End Date

      // var single_starting_date = data.start_date
      // var single_ending_date = data.due_date

      // var start_date_format = dateFormat(single_starting_date)
      // var end_date_format = dateFormat(single_ending_date)

      // console.log('start default',single_starting_date)
      // console.log('srt re',start_date_format)

      // console.log('end default',single_ending_date)
      // console.log('end re',end_date_format)

      // console.log('dddd',dateFormat("2021-09-05"))

      this.setState({ single_task_start_date: dateFormat(data.start_date) })
      this.setState({ single_task_due_date: dateFormat(data.due_date) })
    }
    var created_at = data.created_at
    if (created_at) {

      var split_date = data.created_at.split(' ')
      var res = split_date[0]
      var mydate = new Date(res);
      var split = mydate.toDateString().split(' ');
      var month = split[1]
      var date = split[2]
      var year = split[3]

      var final = date + 'th' + ' ' + month + ' ' + year

      this.setState({ getMonth: final })
      var get_time = split_date[1].split('.')
      var time_split = get_time[0].split(':')
      time_split = time_split[0] + ':' + time_split[1]

      var time_res = tConvert(time_split);
      this.setState({ getTime: time_res })

    }

    this.setState({ single_team_data: data })


  }

  //End Single Task Model
  toggleNotification = () => {
    this.setState(prevState => ({
      notification_Menu: !prevState.notification_Menu,
    }))
  }

  //Toggle Chat Box Menus
  toggleSearch = () => {
    this.setState(prevState => ({
      search_Menu: !prevState.search_Menu,
    }))
  }

  toggleSettings = () => {
    this.setState(prevState => ({
      settings_Menu: !prevState.settings_Menu,
    }))
  }

  toggleOther = () => {
    this.setState(prevState => ({
      other_Menu: !prevState.other_Menu,
    }))
  }

  toggleTab = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  //Use For Chat Box
  userChatOpen = (id, name, status, roomId) => {
    const { onGetMessages } = this.props
    this.setState({ Chat_Box_Username: name, currentRoomId: roomId })
    onGetMessages(roomId)
  }

  addMessage = (roomId, sender) => {
    const { onAddMessage } = this.props
    const message = {
      id: Math.floor(Math.random() * 100),
      roomId,
      sender,
      message: this.state.curMessage,
      createdAt: new Date(),
    }
    this.setState({ curMessage: "" })
    onAddMessage(message)
  }

  scrollToBottom = () => {
    if (this.messageBox) {
      this.messageBox.scrollTop = this.messageBox.scrollHeight + 1000
    }
  }

  onKeyPress = e => {
    const { key, value } = e
    const { currentRoomId, currentUser } = this.state
    if (key === "Enter") {
      this.setState({ curMessage: value })
      this.addMessage(currentRoomId, currentUser.name)
    }
  }


  //Milestone Start
  mile_tog_standard() {

    this.setState(prevState => ({
      milestone_standard: !prevState.milestone_standard,
    }))
    this.removeBodyCss()
  }
  handleMileStartDateChange = (event) => {
    this.setState({ milestone_startDate: event[0] })

  };
  handleMileNameChange(event) {
    this.setState({ [event.target.name]: event.target.value })
  }
  handleMileDescChange(event) {
    this.setState({ milestone_desc: event.blocks[0].text })
  }
  handleMileValueChange(event) {
    console.log(event.target)
    this.setState({ [event.target.name]: event.target.value })

  }

  async getMileProjectOptions() {

    // let team_id = id
    const res = await axios_config.post('/all_projects/')

    const data = res.data.data

    const options = data ? data.map(item => ({
      "value": item["project id"],
      "label": item["Project Name"]
    })) : ''
    this.setState({ selectMileTeamNameOptions: options })

  }

  handleMilestoneNameChange(e) {
    this.setState({ milestone_project_name: e.value })

  }

  handleValidMilestoneSubmit = (event) => {
    event.preventDefault();
    this.setState({
      milestone_project_name: '',
      mile_name: '',
      milestone_desc: '',
      milestone_amount: '',
      milestone_startDate: '',
      milestone_standard: false,
      loading: true
    })
    const { milestone_project_name, mile_name, milestone_desc, milestone_amount, milestone_startDate } = this.state;
    function convert(str) {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [mnth, day, date.getFullYear()].join("-");
    }
    let start_date = convert(milestone_startDate)
    console.log(milestone_project_name)
    console.log(mile_name)
    console.log(milestone_desc)
    console.log(milestone_amount)
    console.log(milestone_startDate)
    console.log(start_date)

    axios_config.post('/milestone/', {

      projectid: milestone_project_name,
      name: mile_name,
      Milestonedescription: milestone_desc,
      amount: milestone_amount,
      date: start_date

    }).then(response => {
      console.log("resp create Milestone", response);
      if (response.data.success == "true") {
        this.getAllMilestone();
        console.log("Add Milestone Successfully");
        alert("Add Milestone Successfully")
        this.setState({
          milestone_create: true,
          loading: false
        })

      } else {
        let msg = response.data.msg
        console.log(msg)
        alert(msg)
        this.setState({
          milestone_create: true,
          loading: false
        })
        this.getAllMilestone();

      }
    })
      .catch(error => {
        console.log("milestone error", error);
      });

  }
  //Milestone End

  //Schedule Statrt
  schedule_tog_standard() {
    this.setState(prevState => ({
      schedule_standard: !prevState.schedule_standard,
    }))
    this.removeBodyCss()
  }

  handleEventMemberChange(event) {
    this.setState({ event_members_value: event })

  }

  handleEventStartDateChange = (event) => {
    this.setState({ event_startDate: event[0] })

  };
  handleEventTimeStartChange = (event) => {
    this.setState({ event_startTime: event[0] })

  };
  handleEventDateEndChange = (event) => {
    this.setState({ event_endDate: event[0] })

  };
  handleEventTimeEndChange = (event) => {
    this.setState({ event_endTime: event[0] })

  };

  handleEventDescChange = (event) => {
    this.setState({ event_desc: event.blocks[0].text })

  };

  async getEventMembersOptions() {
    const response = await axios_config.post('/all-users/', { usertype: 3 })
    const data = response.data.data
    const options = data.map(d => ({
      "value": d["user id"],
      "label": d["user firstname"]
    }))
    this.setState({ selectTaskMemberNameOptions: options })
  }

  handleValueChange(event) {

    this.setState({ [event.target.name]: event.target.value })
  }

  async getProjectOptions() {
    // let team_id = id
    const res = await axios_config.post('/all_projects/')
    console.log('res', res)
    const data = res.data.data

    const options = data ? data.map(item => ({
      "value": item["project id"],
      "label": item["Project Name"]
    })) : ''

    this.setState({ selectProjectNameOptions: options })

  }


  handleEventProjectNameChange(e) {
    this.setState({ event_project_name: e.value })

  }
  handleValidEventSubmit = (event) => {
    event.preventDefault();

    this.setState({
      event_project_name: '',
      event_name: '',
      event_desc: '',
      event_members_value: '',
      event_startDate: '',
      event_endDate: '',
      event_startTime: '',
      event_endTime: '',
      schedule_standard: false,
      loading: true
    })

    const { event_project_name, event_name, event_desc, event_members_value, event_startDate, event_endDate, event_startTime, event_endTime } = this.state;

    function convert(str) {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day,].join("-");
    }

    let start_date = convert(event_startDate)
    let end_date = convert(event_endDate)

    let start_time = event_startTime.toString()
    let split_start_time = start_time.split(' ')
    let get_start_time = split_start_time[4]
    // console.log(get_start_time)

    let end_time = event_endTime.toString()
    let split_end_time = end_time.split(' ')
    let get_end_time = split_end_time[4]

    console.log("mememememe")
    console.log(event_members_value)


    // console.log(getFormattedString(event_startTime))


    // let add_time = event_time+':'+'00'
    // console.log(add_time)

    // let date_change = event_date.split("-")
    // var event_up_date_format = date_change[2]+'-'+date_change[1]+'-'+date_change[0]

    var arr_members_value = [];
    this.state.event_members_value.map((postData) => {
      arr_members_value.push(postData.value)
    })

    console.log(event_project_name)
    console.log(event_name)
    console.log(event_desc)
    console.log(arr_members_value)

    // console.log(event_startDate)
    console.log(start_date)
    console.log(get_start_time)
    // console.log(event_endDate)
    console.log(end_date)
    console.log(get_end_time)

    axios_config.post("/event/", {

      projectid: event_project_name,
      event: event_name,
      eventdescription: event_desc,
      eventtime: get_start_time,
      eventendtime: get_end_time,
      eventdate: start_date,
      eventenddate: end_date,
      membersid: arr_members_value,

    }
    ).then(response => {
      console.log("resp create event", response);

      if (response.data.success == "true") {
        console.log("Add Schedule Successfully");
        alert("Add Schedule Successfully")
        this.getAllMilestone();
        this.setState({
          loading: false,
          event_create_in: true
        })

      } else {
        let msg = response.data.msg
        console.log(msg)
        alert(msg)
        this.getAllMilestone();
        this.setState({
          loading: false,
        })
      }
    })
      .catch(error => {
        console.log("login error", error);
      });

  }
  //End Schedule


  //Create Task Handling


  task_tog_task() {
    this.setState(prevState => ({
      task_modal_task: !prevState.task_modal_task,
    }))
    this.removeBodyCss()
  }


  onFileChange = event => {

    this.setState({ selectedFiles: event.target.files[0] });

  };

  handleTeamDescChange = (event) => {
    this.setState({ task_desc: event.blocks[0].text })

  };

  handleTaskStartDateChange = (event) => {
    this.setState({ task_startDate: event[0] })

  };

  handleTaskDueDateChange = (event) => {
    this.setState({ task_duedate: event[0] })

  };

  handleTaskSelectGroup = priority_sel_event => {
    this.setState({ task_priority: priority_sel_event.value })
  }
  handleTaskNameChange(event) {

    this.setState({ [event.target.name]: event.target.value })
  }

  handleTaskTypeSelectGroup = task_type_event => {
    this.setState({ task_type: task_type_event.value })
  }

  async getMilestoneOptions() {
    const res = await axios_config.post('/all-milestone/')
    const data = res.data.data

    const options = data ? data.map(item => ({
      "value": item["milestone id"],
      "label": item["name"]
    })) : "";
    this.setState({ selectTeamMilestoneOptions: options })
  }

  handleTaskMilestoneNameChange(e) {
    this.setState({ task_milestone_name: e.value })

  }

  async getTaskMembersOptions() {

    const response = await axios_config.post('/all-users/', { usertype: 3 })
    const data = response.data.data
    const options = data.map(d => ({
      "value": d["user id"],
      "label": d["user firstname"]
    }))
    this.setState({ selectTaskMemberNameOptions: options })
  }

  handleTaskMemberChange(event) {
    this.setState({ task_members: event })

  }
  async getParentOptions() {
    const res = await axios_config.post('/all-teams/')
    const data = res.data.data

    const options = data.map(item => ({
      "value": item["Team id"],
      "label": item["Team Name"]
    }))
    this.setState({ get_parent_data: options })
  }

  taskFormSubmit(event) {

    console.log('clicked on')
    event.preventDefault();
    this.setState({
      task_milestone_name: "",
      task_name: "",
      task_type: "",
      task_parent: "",
      task_desc: "",
      task_priority: "",
      task_members: [],
      task_duedate: "",
      task_startDate: "",
      selectedFiles: '',
      projects: [],
      image: null,
      task_modal_task: false,
      loading: true
    })


    const { task_milestone_name, task_name, task_type, task_parent, task_desc, task_progress, task_priority, task_members, task_startDate,
      task_duedate, selectedFiles } = this.state;

    function convert(str) {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day,].join("-");
    }

    let start_date = convert(task_startDate)
    let due_date = convert(task_duedate)
    let update_metakey = selectedFiles.name
    let metavalue = selectedFiles.type

    var arr_members_value = [];
    this.state.task_members.map((postData) => {
      arr_members_value.push(postData.value)
    })

    console.log(task_milestone_name)
    console.log(task_name)
    console.log(task_type)
    console.log(task_parent)
    console.log('metavalue')
    console.log(task_progress)
    console.log(task_desc)
    console.log(task_priority)
    console.log(arr_members_value)
    console.log(start_date)
    console.log(due_date)
    console.log(update_metakey)
    console.log(metavalue)
    console.log(selectedFiles)

    let bodyFormData = new FormData();

    bodyFormData.append("Milestoneid", task_milestone_name);
    bodyFormData.append("task", task_name);
    bodyFormData.append("tasktype", 0);
    bodyFormData.append("taskparent", 0);
    bodyFormData.append("progress", 0);
    bodyFormData.append("taskdescription", task_desc);
    bodyFormData.append("priority", task_priority);
    bodyFormData.append("membersid", arr_members_value);
    bodyFormData.append("metakey", update_metakey);
    bodyFormData.append("metavalue", selectedFiles);
    bodyFormData.append("start_date", start_date);
    bodyFormData.append("due_date", due_date);
    bodyFormData.append("status", 2);

    axios_config({
      method: "post",
      url: "/task/",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then(response => {
      console.log("resp create task", response);
      if (response.data.success == "true") {
        this.getAllMilestone();
        console.log("Add Task Successfully");
        alert("Added Task Successfully")
        this.setState({
          create_task: true,
          task_modal_task: false,
          loading: false,

        })

      } else {
        this.getAllMilestone();
        let msg = response.data.msg
        console.log(msg)
        alert(msg)
        this.setState({
          create_task: true,
          task_modal_task: false,
          loading: false,

        })


      }
    })
      .catch(error => {
        console.log("login error", error);
      });
  }

  //End Create Task Handling

  t_col1() {
    this.setState({ col1: !this.state.col1 })
  }

  t_col2() {
    this.setState({ col2: !this.state.col2 })
  }

  t_col3() {
    this.setState({ col3: !this.state.col3 })
  }

  t_col11() {
    this.setState({ col11: !this.state.col11 })
  }

  t_col22() {
    this.setState({ col22: !this.state.col22 })
  }

  t_col33() {
    this.setState({ col33: !this.state.col33 })
  }
  t_col4() {
    this.setState({ col4: !this.state.col4 })
  }
  t_col5() {
    this.setState({ col5: !this.state.col5 })
  }
  t_col6() {
    this.setState({ col6: !this.state.col6 })
  }




  tog_standard() {
    this.setState(prevState => ({
      modal_standard: !prevState.modal_standard,
    }))
    this.removeBodyCss()
  }

  removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  tog_fullscreen() {
    this.setState(prevState => ({
      modal_fullscreen: !prevState.modal_fullscreen,
    }))
    this.removeBodyCss()
  }

  tog_backdrop() {
    this.setState(prevState => ({
      modal_backdrop: !prevState.modal_backdrop,
    }))
    this.removeBodyCss()
  }

  tog_large() {
    this.setState(prevState => ({
      modal_large: !prevState.modal_large,
    }))
    this.removeBodyCss()
  }

  tog_xlarge() {
    this.setState(prevState => ({
      modal_xlarge: !prevState.modal_xlarge,
    }))
    this.removeBodyCss()
  }

  tog_small() {
    this.setState(prevState => ({
      modal_small: !prevState.modal_small,
    }))
    this.removeBodyCss()
  }

  tog_center() {
    this.setState(prevState => ({
      modal_center: !prevState.modal_center,
    }))
    this.removeBodyCss()
  }
  tog_center1() {
    this.setState(prevState => ({
      modal_center1: !prevState.modal_center1,
    }))
    this.removeBodyCss()
  }

  tog_scroll() {
    this.setState(prevState => ({
      modal_scroll: !prevState.modal_scroll,
    }))
    this.removeBodyCss()
  }

  show() {
    this.setState({ visible: true })
  }

  hide() {
    this.setState({ visible: false })
  }



  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }
  async allTeamProjects() {

    const res = await axios_config.post('all-team_projects/')

    let get_all_result = res.data.data
    this.setState({ all_team_projects: get_all_result })

  }

  //All Milestone Data 

  //Project Filter
  async getProjectFilter() {

    let url_project_id = this.props.match.params.id

    const project_res = await axios_config.post('all_projects/')
    const pro_data = project_res.data.data

    const pro_obj = pro_data.filter((item) => {

      return url_project_id == item['project id'] ?? item;
    });

    console.log("pro_obj", pro_obj)
    this.setState({ get_proj: pro_obj })
  }
  async getAllMilestone() {

    let url_project_id = this.props.match.params.id


    const response = await axios_config.post('all-miletask/', { project_id: url_project_id })
    const data = response.data.data
    console.log("data_mile ", data)
    this.setState({ all_mile_task: data })
  }
  //End Milestone Data


  componentDidMount() {
    this.allTeamProjects();

    //Task
    this.getMilestoneOptions();
    this.getTaskMembersOptions();

    //Schedule
    this.getProjectOptions();
    this.getEventMembersOptions();

    //Milestone

    this.getMileProjectOptions();
    this.getAllMilestone();
    this.getProjectFilter();

    //Single task
    this.getSingleTask();

    //Sub-Task
    this.getParentOptions();

  }
  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { messages } = this.props
    if (size(messages) !== size(prevProps.messages)) {
      this.scrollToBottom()
    }

  }
  render() {
    var get_team_projects = this.state.all_team_projects
    var comments = this.state.comment_data
    var user_id = this.state.user_id
    const { chats, groups, contacts, messages } = this.props
    const { currentRoomId, currentUser } = this.state
    const { TaskData } = this.state.selectTaskOptions
    var user_sel_opt = this.state.user_select_opt
    var get_start_date = this.state.single_task_start_date
    var get_due_date = this.state.single_task_due_date

    if (this.state.loading) {
      return <Loader
        style={{ position: "fixed", top: "50%", left: "60%", transform: "translate(-50%, -50%)" }}
        type="Circles"
        color="#00BFFF"
        height={100}
        width={100}
        timeout={9000} //3 secs
      />
    }

    const mile_data = this.state.all_mile_task

    return (
      <React.Fragment>
        <div className="page-content">

          <Container fluid={true} className="projectpage project-taskpage">

            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <Row className="project-task-head" >
                      <Col lg={12}>
                        <div className="project-head">

                          <h1> Projects - Project Tittle</h1>

                        </div>
                        <div className="new-prjt-btn">
                          <Button
                            color="primary"
                            className="font-16 btn-block btn btn-primary"
                            onClick={this.handleUserClicks}
                          > Add New <i class="bx bx-plus"></i>
                          </Button>
                        </div>
                        <div className="new-prjt-btn">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={this.tog_center}
                            data-toggle="modal"
                            data-target=".bs-example-modal-center"
                          >
                            Chat
                          </button>
                        </div>
                        <Modal className="wide-modalsize"
                          isOpen={this.state.modal_center}
                          toggle={this.tog_center}
                          centered={true}
                        >

                          <div className="modal-body">
                            <Chat />
                          </div>
                        </Modal>
                        <div className="new-prjt-btn">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={this.tog_center1}
                            data-toggle="modal"
                            data-target=".bs-example-modal-center"
                          >
                            Compose
                          </button>
                        </div>
                        <Modal className="wide-modalsize"
                          isOpen={this.state.modal_center1}
                          toggle={this.tog_center1}
                          centered={true}
                        >

                          <div className="modal-body">
                            <Compose />
                          </div>
                        </Modal>
                      </Col>
                    </Row>


                    <div className="project-head">
                      <h2><i class="dripicons-folder"></i> Project Management</h2>
                    </div>


                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "1",
                          })}
                          onClick={() => {
                            this.toggle("1")
                          }}
                        >
                          <i class="bx bx-location-plus"></i>List
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        {/* <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "2",
                          })}
                          onClick={() => {
                            this.toggle("2")
                          }}
                        >
                          <i class="bx bx-grid-alt"></i>Board
                        </NavLink> */}

                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "3",
                          })}
                          onClick={() => {
                            this.toggle("3")
                          }}
                        >
                          <i class="dripicons-list"></i>Timeline
                        </NavLink>

                      </NavItem>
                      <NavItem>
                        {/* <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "4",
                          })}
                          onClick={() => {
                            this.toggle("4")
                          }}
                        >
                          <i class="mdi mdi-animation-outline"></i>Files
                        </NavLink> */}

                      </NavItem>

                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "5",
                          })}
                          onClick={() => {
                            this.toggle("5")
                          }}
                        >
                          <i class="mdi mdi-calendar-month-outline"></i>Calendar
                        </NavLink>

                      </NavItem>

                    </Nav>

                    <ul className="project-task-rghtlink">
                      <li className="white-bg">8 members</li>
                      <li className="grey-bg">Due date: 17 th May 2021</li>
                      <li className="grey-bg">Priority: High </li>
                    </ul>

                    <TabContent activeTab={this.state.activeTab} className="p-3 text-muted">
                      <TabPane tabId="1">
                        <Row>
                          <Col lg={12}>

                            <div className="accordion" id="accordion">
                              <div className="accordion-item">

                                {mile_data ? mile_data.map((item) => (<>
                                  <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button fw-medium" type="button" onClick={this.t_col1} style={{ cursor: "pointer" }}>
                                      {item['name']}

                                    </button>
                                  </h2>
                                  <div className="sub-popup-sect">
                                    <div className="sub-popup">
                                      <button
                                        type="button"
                                        onClick={this.task_tog_task}
                                        data-toggle="modal"
                                        data-target="#myModal"
                                        className=" add-prjt-btn"
                                      >
                                        <i class="bx bx-plus"></i>Task
                                      </button>

                                    </div>
                                  </div>
                                  <div className="sub-popup-sect">
                                    <div className="sub-popup">
                                      <button
                                        type="button"
                                        onClick={this.schedule_tog_standard}
                                        className=" add-prjt-btn"
                                        data-toggle="modal"
                                        data-target="#myModal"
                                      >
                                        <i class="bx bx-plus"></i>Schedule
                                      </button>

                                    </div>
                                  </div>
                                  <div className="sub-popup-sect">
                                    <div className="sub-popup">
                                      <button
                                        type="button"
                                        onClick={this.mile_tog_standard}
                                        className=" add-prjt-btn"
                                        data-toggle="modal"
                                        data-target="#myModal"
                                      >
                                        <i class="bx bx-plus"></i>Milestone
                                      </button>

                                    </div>
                                  </div>
                                  <Modal
                                    isOpen={this.state.model_single_task}
                                    toggle={this.single_task_model}
                                    centered={true}
                                  >
                                    <div className="modal-body">
                                      <div className="page-content">
                                        <Container fluid>
                                          <Row>
                                            <Col lg="12">
                                              <div className="d-lg-flex chat-page">
                                                <div className="chat-leftsidebar me-lg-4">
                                                  <div className="chat-leftsidebar-first">
                                                    <div className="breadcrumb-sect">
                                                      <ul>
                                                        <li><Link to="#">{this.state.single_team_data.team_name}</Link></li>
                                                        <li><Link to="#">{this.state.single_team_data.project_name}</Link></li>
                                                        <li><Link to="#">{this.state.single_team_data.milestone_name}</Link></li>
                                                        <li><Link to="#">{this.state.single_team_data.name}</Link></li>
                                                      </ul>
                                                    </div>
                                                    <div className="create-sect">
                                                      <ul>
                                                        <li>
                                                          <span >Created</span>
                                                          {/* Mar 1 2.00 pm */}
                                                          {/* {this.state.single_team_data.created_at} */}
                                                          <span className="blue-text">    {this.state.getMonth + ' ' + this.state.getTime} </span>
                                                        </li>
                                                        <li>
                                                          <span>Due date</span>
                                                          {/* <span className="blue-text">10/3/2021</span> */}
                                                          <span className="blue-text"> {this.state.single_team_data.due_date}</span>
                                                        </li>
                                                        <li>
                                                          {/* <Link to="#" className="creat-done" >Done</Link> */}
                                                          <Link to="#" className={this.state.single_team_data.status == 0 ? "creat-done" : (this.state.single_team_data.status == 1 ? "font-size-12 badge-soft-success badge badge-success badge-pill progress-color" : "font-size-12 badge-soft-success badge badge-success badge-pill todo-color")} onClick={this.tog_status} data-toggle='modal' data-target='#myModal'>{this.state.single_team_data.status == 0 ? 'Done' : (this.state.single_team_data.status == 1 ? 'Progrress' : 'To do')}</Link>
                                                          <Modal
                                                            isOpen={this.state.modal_status}
                                                            toggle={this.tog_status}
                                                            centered={true}
                                                          >
                                                            <Col lg={4}>
                                                              <Select
                                                                options={this.state.status_opt}
                                                                onChange={this.handleStateOptionsChange}
                                                                classNamePrefix="select2-selection"
                                                                validate={{
                                                                  required: { value: true },

                                                                }}
                                                                // onKeyPress={(e)=>{this.onKeyStatus(e)}}
                                                                placeholder='Select Task Status'
                                                              />
                                                              <button
                                                                type="button"
                                                                onClick={this.onKeyStatus}
                                                                className="btn btn-primary"
                                                              >Submit</button>
                                                            </Col>
                                                          </Modal>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                    <div className="chatleft-task">
                                                      <h2 className="chat-task-h2"> {this.state.single_team_data.name}</h2>
                                                      <div className="form-group editor-wrap">
                                                        <Editor
                                                          placeholder={this.state.single_team_data.description}
                                                          toolbarClassName="toolbarClassName"
                                                          wrapperClassName="wrapperClassName"
                                                          editorClassName="editorClassName"
                                                          toolbar={{
                                                            options: ['inline', 'history', "emoji"],
                                                            inline: { inDropdown: false },
                                                            history: { inDropdown: true },
                                                            emoji: { inDropdown: true }
                                                          }}
                                                          name='update_task_desc'
                                                          value={this.state.update_task_desc}
                                                          onChange={this.handleTaskUpdateDescChange}
                                                        />
                                                        <Col className="col-auto">
                                                          <Button
                                                            type="button"
                                                            color="primary"
                                                            onClick={this.submitDescription}
                                                            className="btn-rounded chat-send w-md"
                                                          >
                                                            <span className="d-none d-sm-inline-block me-2">
                                                            </span>{" "}
                                                            <i className="mdi mdi-send"></i>
                                                            Update
                                                          </Button>
                                                        </Col>
                                                        <ul class="list-inline mb-0 editor-ions"><li class="list-inline-item"><a href="/chat"><i class="mdi mdi-format-text-rotation-none" id="Emojitooltip"></i></a></li> <li class="list-inline-item"><a href="/chat"><i class="mdi mdi-link-variant" id="Imagetooltip"></i></a></li> <li class="list-inline-item"><a href="/chat"><i class=" bx bx-link-alt" id="Filetooltip"></i></a></li><li class="list-inline-item"><a href="/chat"><i class="bx bxs-happy" id="Filetooltip"></i></a></li> </ul>
                                                      </div>
                                                    </div>
                                                    <div className="chatleft-subtask">
                                                      <h2 className="chat-task-h2">Subtasks <span className="blue-bg">2</span></h2>
                                                      <div className="position-relative">
                                                        <input

                                                          name="create_subtask"
                                                          type="text"
                                                          className="form-control"
                                                          placeholder="Create Subtask"
                                                          value={this.state.create_subtask}
                                                          onChange={e => {
                                                            this.setState({
                                                              create_subtask: e.target.value,
                                                            })
                                                          }}
                                                          onKeyPress={(e) => {
                                                            this.onKeyDown(e)
                                                          }}
                                                        />
                                                      </div>
                                                      <div className="table-responsive">
                                                        <Table className="table mb-0">
                                                          <tbody>
                                                            {this.state.sub_task_data.length == 0 ? <td className="grey-txt"> No Subtasks </td> : this.state.sub_task_data.map((subtask) =>
                                                              <tr>
                                                                <td><input type="checkbox" /></td>
                                                                <td>{subtask.sub_name}</td>
                                                                <td><i class="bx bx-check"></i></td>
                                                                <td
                                                                  onClick={() => {
                                                                    this.deleteSubtask(subtask.sub_id)
                                                                  }}
                                                                ><i class="bx bx-trash"></i></td>
                                                              </tr>
                                                            ).reverse()}
                                                          </tbody>
                                                        </Table>
                                                      </div>
                                                    </div>
                                                    <div className="chatleft-attach">
                                                      <h2 className="chat-task-h2">Attachments</h2>
                                                      <div className="table-responsive">
                                                        <Table className="table mb-0">
                                                          <tbody>

                                                            {this.state.task_detail.length == 0 ? <td className="grey-txt"> No Files </td> : this.state.task_detail.map((file) =>

                                                              <>
                                                                <tr>
                                                                  <td><input type="checkbox" /></td>

                                                                  <td

                                                                    onClick={() => {
                                                                      this.fileDownload(file.metakey)
                                                                    }}
                                                                  >
                                                                    {file.metakey} </td>
                                                                  <td className="grey-txt"> {file.date} </td>
                                                                  <td className="grey-txt"> {file.size}</td>
                                                                  <td onClick={() => {
                                                                    this.deleteTaskdetail(file.id)
                                                                  }} ><i class="bx bx-trash"></i></td>
                                                                </tr>
                                                              </>).reverse()}
                                                          </tbody>
                                                        </Table>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="chatleft-dropfiles">
                                                    <div className="input-group">
                                                      <Input
                                                        name="uploadFiles"
                                                        type="file"
                                                        className="form-control"
                                                        id="inputGroupFile02"
                                                        onChange={e => {

                                                          this.setState({ uploadFiles: e.target.files[0] });
                                                        }}

                                                      />
                                                      <Button onClick={(event) => { this.FileUpload(event, this.state.single_team_data.task_id ? this.state.single_team_data.task_id : '') }}>
                                                        Upload
                                                      </Button>
                                                    </div>
                                                  </div>

                                                </div>
                                                <div className="w-100 user-chat">
                                                  <Card>
                                                    <div className="py-3 border-bottom chat-head">
                                                      <Media>
                                                        <div className="chat-head-left">
                                                          <div className="align-self-center me-3">
                                                            <img
                                                              src={images.avatar1}
                                                              className="avatar-xs rounded-circle"
                                                              alt=""
                                                            />
                                                          </div>
                                                          <Media body>
                                                            <h5 className="font-size-15 mt-0 mb-1">
                                                            </h5>
                                                            <p className="text-muted mb-0">
                                                              <i className="mdi mdi-circle text-success align-middle me-1" />
                                                              Active
                                                            </p>
                                                          </Media>
                                                        </div>
                                                        <div className="chat-head-right">
                                                          <ul className="chat-head-ul">
                                                            <li>
                                                              <Link to="#"><i class="bx bx-check" ></i></Link>
                                                            </li>
                                                            <li>
                                                              <Link to="#" onClick={this.tog_task_deadline} data-toggle="modal" data-target="#myModal"><i class="bx bx-time-five" ></i></Link>
                                                              <Modal
                                                                isOpen={this.state.modal_task_deadline}
                                                                toggle={this.tog_task_deadline}
                                                                centered={true}
                                                              >
                                                            <Col lg={4}>
                                                            <div className="input-group date-pick">
                                                              <Flatpickr
                                                                className="form-control d-block"
                                                                placeholder={get_start_date}
                                                                name="project_startDate"
                                                                className="form-control d-block"
                                                                value={this.state.task_startdate_update}
                                                                onChange={ (event)=>{
                                                                  this.setState({
                                                                    task_startdate_update: event[0],
                                                                  })
                                                                }}
                                                                  
                                                                options={{
                                                                  altInput: true,
                                                                  altFormat: "F j, Y",
                                                                  dateFormat: "Y-m-d"
                                                                }}

                                                              />
                                                              <span className="date-icon"><i class="bx bx-calendar"></i></span>
                                                            </div>
                                                            </Col>


                                                            <Col lg={4}>
                                                            <div className="input-group date-pick">
                                                              <Flatpickr
                                                                name="task_duedate"
                                                                className="form-control d-block"
                                                                placeholder={get_due_date}
                                                                value={this.state.task_duedate_update}
                                                                onChange={ (event)=>{
                                                                  this.setState({
                                                                    task_duedate_update: event[0],
                                                                  })
                                                                }}
                                                                  
                                                                options={{
                                                                  altInput: true,
                                                                  altFormat: "F j, Y",
                                                                  dateFormat: "Y-m-d"
                                                                }}

                                                              />
                                                              <span className="date-icon"><i class="bx bx-calendar"></i></span>
                                                            </div>
                                                            </Col>
                                                          <Button
                                                          type="button"
                                                          color="primary"
                                                          onClick={this.updateDeadline}
                                                          className="btn-rounded chat-send w-md"
                                                        >
                                                          <span className="d-none d-sm-inline-block me-2">
                                                          </span>{" "}
                                                          Update
                                                        </Button>
                                                              </Modal>
                                                            </li>
                                                            <li>
                                                              <Link to="#" onClick={this.tog_user} data-toggle="modal" data-target="#myModal"><i class="mdi mdi-account-plus-outline" ></i></Link>
                                                              <Modal
                                                                isOpen={this.state.modal_user}
                                                                toggle={this.tog_user}
                                                                centered={true}
                                                              >

                                                                <Col lg={4}>
                                                                  <Select
                                                                    options={user_sel_opt}
                                                                    onChange={this.handleUserOptionsChange}
                                                                    isMulti
                                                                    classNamePrefix="select2-selection"
                                                                    validate={{
                                                                      required: { value: true },

                                                                    }}
                                                                    placeholder='Add User'
                                                                  />
                                                                  <button
                                                                    type="button"
                                                                    onClick={this.submitUser}
                                                                    className="btn btn-primary"
                                                                    data-toggle="modal"
                                                                    data-target="#myModal"
                                                                  >Add</button>
                                                                </Col>
                                                              </Modal>
                                                            </li>
                                                            <li>
                                                              <Link to="#" onClick={(event) => { this.deleteAllTask() }}><i class="bx bx-trash" ></i></Link>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      </Media>
                                                    </div>

                                                    <div className="chat-conver-outer">
                                                      <div className="chat-conversation p-3">
                                                        <ul className="list-unstyled mb-0">
                                                          <PerfectScrollbar
                                                            style={{ height: "486px", minHeight: "486px" }}
                                                            containerRef={ref => (this.messageBox = ref)}
                                                          >

                                                            <li>
                                                              {comments.length == 0 ? <div className="chat-day-title right">
                                                                <span>No Comments</span>
                                                              </div> :
                                                                <>
                                                                  {comments ? comments.map((comment, index) =>
                                                                    <>

                                                                      {
                                                                        comment.comment_date ?
                                                                          comment.comment_date == comments[++index]?.comment_date ?
                                                                            "" :
                                                                            <>
                                                                              <div className="chat-day-title right">
                                                                                <span>{comment.comment_date}</span>
                                                                              </div>

                                                                              {comments.map((item) => <>

                                                                                {item.comment_date == comment.comment_date ?
                                                                                  <>
                                                                                    {item.comment_user_id == user_id ?
                                                                                      <div className="chat-day-title right">
                                                                                        <p className="data_div">
                                                                                          <span className="title">{item.comment_name}</span>
                                                                                          <span className="right-date">{item.comment_time}</span>
                                                                                        </p>
                                                                                      </div>
                                                                                      :
                                                                                      <div className="chat-day-title left">
                                                                                        <div className="chat-img">
                                                                                          <img src={images.avatar1} className="avatar-xs rounded-circle" alt="" />
                                                                                        </div>
                                                                                        <p className="data_div">
                                                                                          <span className="title">{item.comment_name} </span>
                                                                                          <span className="left-date">{item.comment_time}</span>
                                                                                        </p>
                                                                                      </div>
                                                                                    }
                                                                                  </>
                                                                                  : ''

                                                                                }

                                                                              </>)}

                                                                            </> : 'Noooo'

                                                                      }

                                                                    </>
                                                                  ) : 'ending'}

                                                                </>
                                                              }

                                                            </li>

                                                            {messages &&
                                                              messages.map(message => (
                                                                <li
                                                                  key={"test_k2" + message.id}
                                                                  className={
                                                                    message.sender === currentUser.name
                                                                      ? "right"
                                                                      : ""
                                                                  }
                                                                >
                                                                  <div className="conversation-list">
                                                                    <UncontrolledDropdown>
                                                                      <DropdownToggle
                                                                        href="#"
                                                                        className="dropdown-toggle"
                                                                        tag="a"
                                                                      >
                                                                        <i className="bx bx-dots-vertical-rounded" />
                                                                      </DropdownToggle>
                                                                      <DropdownMenu direction="right">
                                                                        <DropdownItem href="#">
                                                                          Copy
                                                                        </DropdownItem>
                                                                        <DropdownItem href="#">
                                                                          Save
                                                                        </DropdownItem>
                                                                        <DropdownItem href="#">
                                                                          Forward
                                                                        </DropdownItem>
                                                                        <DropdownItem href="#">
                                                                          Delete
                                                                        </DropdownItem>
                                                                      </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                    <div className="ctext-wrap">
                                                                      <div className="conversation-name">
                                                                        {message.sender}
                                                                      </div>
                                                                      <p>{message.message}</p>
                                                                      <p className="chat-time mb-0">
                                                                        <i className="bx bx-time-five align-middle me-1" />{" "}
                                                                        {moment(message.createdAt).format(
                                                                          "hh:mm"
                                                                        )}
                                                                      </p>
                                                                    </div>
                                                                  </div>
                                                                </li>
                                                              ))}
                                                          </PerfectScrollbar>
                                                        </ul>
                                                      </div>
                                                      <div className="p-3 chat-input-section">
                                                        <div className=" chat-input-section-outer">
                                                          <Row>
                                                            <Col>
                                                              <div className="position-relative">
                                                                <input
                                                                  type="text"
                                                                  name="comment_msg"
                                                                  value={this.state.comment_msg}
                                                                  // onKeyPress={this.onKeyPress}
                                                                  onChange={e => {
                                                                    this.setState({
                                                                      comment_msg: e.target.value,
                                                                    })
                                                                  }}

                                                                  onKeyPress={(e) => {
                                                                    this.onKeyDownComment(e)
                                                                  }}
                                                                  className="form-control chat-input"
                                                                  placeholder="Enter  Comment..."
                                                                />


                                                              </div>

                                                            </Col>
                                                            <Col className="col-auto">
                                                              <Button
                                                                type="button"
                                                                color="primary"
                                                                onClick={this.commentSubmit}
                                                                className="btn-rounded chat-send w-md"
                                                              >
                                                                <span className="d-none d-sm-inline-block me-2">
                                                                </span>{" "}
                                                                <i className="mdi mdi-send"></i>
                                                              </Button>
                                                            </Col>
                                                          </Row>

                                                          <Row className="chat-bottom-row">
                                                            <Col className="chat-bottom-icons">
                                                              <div className="chat-input-links">
                                                                <ul className="list-inline mb-0">
                                                                  <li className="list-inline-item">
                                                                    <Link to="#">
                                                                      <i
                                                                        className="mdi mdi-format-text-rotation-none"
                                                                        id="Emojitooltip"
                                                                      ></i>
                                                                      <UncontrolledTooltip
                                                                        placement="top"
                                                                        target="Emojitooltip"
                                                                      >
                                                                        Emojis
                                                                      </UncontrolledTooltip>
                                                                    </Link>
                                                                  </li>{" "}
                                                                  <li className="list-inline-item">
                                                                    <Link to="#">
                                                                      <i
                                                                        className="mdi mdi-link-variant"
                                                                        id="Imagetooltip"
                                                                      ></i>
                                                                      <UncontrolledTooltip
                                                                        placement="top"
                                                                        target="Imagetooltip"
                                                                      >
                                                                        Images
                                                                      </UncontrolledTooltip>
                                                                    </Link>
                                                                  </li>{" "}
                                                                  <li className="list-inline-item">
                                                                    <Link to="#">
                                                                      <i
                                                                        className=" bx bx-link-alt"
                                                                        id="Filetooltip"
                                                                      ></i>
                                                                      <UncontrolledTooltip
                                                                        placement="top"
                                                                        target="Filetooltip"
                                                                      >
                                                                        Add Files
                                                                      </UncontrolledTooltip>
                                                                    </Link>
                                                                  </li>
                                                                  <li className="list-inline-item">
                                                                    <Link to="#">
                                                                      <i
                                                                        className="bx bxs-happy"
                                                                        id="Filetooltip"
                                                                      >
                                                                        {/* <Picker onSelect={this.addEmoji}/> */}

                                                                      </i>
                                                                    </Link>
                                                                  </li>{" "}

                                                                </ul>
                                                              </div>
                                                            </Col>
                                                          </Row>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </Card>
                                                </div>
                                              </div>
                                            </Col>
                                          </Row>
                                        </Container>
                                      </div>
                                    </div>
                                  </Modal>
                                  <Collapse isOpen={this.state.col1} className="accordion-collapse">
                                    <div className="accordion-body">
                                      <div className="text-muted">
                                        {/* <ContactList />  */}
                                        <div className="table-responsive h-100" data-simplebar  >
                                          <Table className="table mb-0 ">
                                            <thead>
                                              <tr>
                                                <th>Name</th>
                                                <th>Asignees</th>
                                                <th>Due Date</th>
                                                <th>Priority</th>
                                                <th>Progress</th>
                                                <th>Status</th>
                                                <th>Options</th>
                                              </tr>
                                            </thead>
                                            {item['task_data'] ? item['task_data'].map((data, index) => (
                                              <>
                                                <tbody>
                                                  <tr class="done-tr">
                                                    {/* <td><Link to={"/chat/" + data['task id']}>{data['name']}</Link></td> */}
                                                    <td onClick={() => {
                                                      this.single_task_model();
                                                      this.getSingleTask(data['task id']);

                                                    }} data-toggle="modal"
                                                      data-target="#myModal">{data['name']} </td>
                                                    <td><div class="avatar-group">
                                                      <div class="avatar-group-item">
                                                        <a class="d-inline-block" href="/tasks-list"><div class="avatar-xs"><span class="avatar-title rounded-circle bg-success text-white font-size-16">
                                                          {data['member_list'] ? data['member_list'].map((member, index) => (
                                                            member['task_member']

                                                          )) : 'No Member Found'}</span></div></a>
                                                      </div>
                                                      {/* <div class="avatar-group-item plus-avatar-group">
                                                  <a class="d-inline-block" href="/tasks-list"><div class="avatar-xs"><span class="avatar-title rounded-circle bg-secondary text-white font-size-16"><i class="bx bx-plus"></i></span></div></a>
                                                </div> */}

                                                    </div>
                                                    </td>
                                                    <td><span class="date-text"><i className="fas fa-calendar-alt"></i> {data['due date']}</span></td>
                                                    <td><span class={data['priority'] == 0 ? 'Low-text' : (data['priority'] == 1 ? "Medium-text" : "High-text")}><i class={data['priority'] == 0 ? 'dripicons-arrow-thin-down' : (data['priority'] == 1 ? "dripicons-arrow-thin-right" : "dripicons-arrow-thin-up")}></i>{data['priority'] == 0 ? 'Low' : (data['priority'] == 1 ? "Medium" : "High")}</span></td>
                                                    <td>  <div className="progress-bar">
                                                      <Progress color="primary" value={50}>
                                                        <span class="percentage-txt">{data['progress']}%</span>
                                                      </Progress>
                                                    </div></td>
                                                    <td><span class={data['status'] == 0 ? 'badge-soft-success badge badge-success badge-pill done-color' : (data['status'] == 1 ? 'badge-soft-success badge badge-success badge-pill progress-color' : 'badge-soft-success badge badge-success badge-pill todo-color')}>{data['status'] == 0 ? 'Done' : (data['status'] == 1 ? "Progress" : "ToDo")}</span></td>
                                                    <td>
                                                      <div class="d-flex gap-3 options">
                                                        <a class="text-success" href="/Projecttask">  <i class="bx bx-check" id="edittooltip"></i></a>
                                                        <a class="text-danger" href="/Projecttask"><i class="bx bx-time-five" id="deletetooltip"></i></a>
                                                        <a class="account-add" href="/Projecttask"><i class="mdi mdi-account-plus-outline" id="deletetooltip"></i></a>
                                                        <a class="account-delete"><i class="bx bx-trash" id="deletetooltip" onClick={() => { this.deleteTask(data['task id']) }}></i></a></div>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </>)) : 'No Task Found'}
                                          </Table>

                                        </div>
                                      </div>
                                    </div>
                                  </Collapse>
                                </>)) : 'No Record Found'}
                                <Modal
                                  isOpen={this.state.schedule_standard}
                                  toggle={this.schedule_tog_standard}
                                  centered={true}
                                >
                                  <div className="modal-header">
                                    <h5 className="modal-title mt-0" id="myModalLabel">
                                      <span class="popup-head"><i class="bx bx-plus"></i> Create a new Schedule </span>
                                    </h5>
                                    <button
                                      type="submit"
                                      className="btn btn-primary right-align"
                                      onClick={this.handleValidEventSubmit}
                                    >
                                      Done

                                    </button>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        this.setState({ schedule_standard: false })
                                      }
                                      className="close"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <div className="modal-body">
                                    <Form>
                                      <FormGroup row>
                                        <Col sm={12}>
                                          <Select
                                            options={this.state.selectProjectNameOptions}
                                            onChange={this.handleEventProjectNameChange}
                                            classNamePrefix="select2-selection"
                                            placeholder='Assign to a project'
                                            validate={{
                                              required: { value: true },
                                            }}
                                          />
                                        </Col>
                                      </FormGroup>

                                      <FormGroup row>
                                        <Col sm={12}>
                                          <input
                                            name="event_name"
                                            type="text"
                                            errorMessage="Invalid name"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            className="form-control"
                                            placeholder="Enter Event Name..."
                                            value={this.state.event_name}
                                            onChange={this.handleValueChange}

                                          />
                                        </Col>
                                      </FormGroup>
                                      <br />
                                      <FormGroup row>
                                        <Col sm={12}>
                                          <Editor
                                            placeholder="Schedule description"
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            toolbar={{
                                              options: ['inline', 'history', "emoji"],
                                              inline: { inDropdown: false },
                                              history: { inDropdown: true },
                                              emoji: { inDropdown: true }
                                            }}
                                            name='event_desc'
                                            value={this.state.event_desc}
                                            onChange={this.handleEventDescChange}
                                          />
                                        </Col>
                                      </FormGroup>

                                      <br />
                                      <FormGroup row>
                                        <Col sm={12}>
                                          <Select
                                            options={this.state.selectTaskMemberNameOptions}
                                            onChange={this.handleEventMemberChange}
                                            isMulti
                                            classNamePrefix="select2-selection"
                                            placeholder='Invite people to that schedule'
                                          />
                                        </Col>
                                      </FormGroup>
                                      <br />
                                      <FormGroup row>
                                        <Col sm={3}>
                                          <InputGroup>
                                            <Flatpickr
                                              className="form-control d-block"
                                              placeholder="Starting date"
                                              name="event_startDate"
                                              className="form-control d-block"
                                              value={this.state.event_startDate}
                                              onChange={this.handleEventStartDateChange}
                                              options={{
                                                altInput: true,
                                                altFormat: "F j, Y",
                                                dateFormat: "Y-m-d"
                                              }}

                                            />
                                          </InputGroup>
                                        </Col>
                                        <Col sm={3}>
                                          <InputGroup>
                                            <Flatpickr
                                              className="form-control d-block"
                                              placeholder="Starting time"
                                              name="event_startTime"
                                              value={this.state.event_startTime}
                                              onChange={this.handleEventTimeStartChange}
                                              options={{
                                                enableTime: true,
                                                noCalendar: true,
                                                dateFormat: "H:i"
                                              }}
                                            />
                                            <div className="input-group-append">
                                              <span className="input-group-text">
                                                <i className="mdi mdi-clock-outline" />
                                              </span>
                                            </div>
                                          </InputGroup>
                                        </Col>
                                        <Col sm={3}>
                                          <InputGroup>
                                            <Flatpickr
                                              className="form-control d-block"
                                              placeholder="Ending date"
                                              name="event_endDate"
                                              className="form-control d-block"
                                              value={this.state.event_endDate}
                                              onChange={this.handleEventDateEndChange}
                                              options={{
                                                altInput: true,
                                                altFormat: "F j, Y",
                                                dateFormat: "Y-m-d"
                                              }}

                                            />
                                          </InputGroup>
                                        </Col>
                                        <Col sm={3}>
                                          <InputGroup>
                                            <Flatpickr
                                              className="form-control d-block"
                                              placeholder="Ending time"
                                              name="event_endTime"
                                              className="form-control d-block"
                                              value={this.state.event_endTime}
                                              onChange={this.handleEventTimeEndChange}
                                              options={{
                                                enableTime: true,
                                                noCalendar: true,
                                                dateFormat: "H:i"
                                              }}
                                            />
                                            <div className="input-group-append">
                                              <span className="input-group-text">
                                                <i className="mdi mdi-clock-outline" />
                                              </span>
                                            </div>
                                          </InputGroup>
                                        </Col>
                                      </FormGroup>
                                    </Form>

                                  </div>
                                  <div className="modal-footer ftr-none">

                                  </div>
                                </Modal>

                                <Modal
                                  isOpen={this.state.milestone_standard}
                                  toggle={this.mile_tog_standard}
                                  centered={true}
                                >
                                  <div className="modal-header">
                                    <h5 className="modal-title mt-0" id="myModalLabel">
                                      <span class="popup-head"><i class="bx bx-plus"></i> Create a new Milestone </span>
                                    </h5>
                                    <button
                                      type="submit"
                                      className="btn btn-primary right-align"
                                      onClick={this.handleValidMilestoneSubmit}
                                    >
                                      Done

                                    </button>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        this.setState({ milestone_standard: false })
                                      }
                                      className="close"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <div className="modal-body">

                                    <Form>
                                      <FormGroup row>
                                        <Col sm={12}>
                                          <Select
                                            options={this.state.selectMileTeamNameOptions}
                                            onChange={this.handleMilestoneNameChange}
                                            classNamePrefix="select2-selection"
                                            validate={{
                                              required: { value: true },
                                            }}
                                          />
                                        </Col>
                                      </FormGroup>
                                      <FormGroup row>
                                        <Col lg={12}>
                                          <div className="mb-3">
                                            <input
                                              name="mile_name"
                                              type="text"
                                              errorMessage="Invalid name"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              className="form-control"
                                              placeholder="Milestone Name"
                                              value={this.state.mile_name}
                                              onChange={this.handleMileNameChange}
                                            />
                                          </div>
                                        </Col>
                                      </FormGroup>
                                      <FormGroup row>
                                        <Col sm={12}>
                                          <Editor
                                            placeholder="Schedule description"
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            toolbar={{
                                              options: ['inline', 'history', "emoji"],
                                              inline: { inDropdown: false },
                                              history: { inDropdown: true },
                                              emoji: { inDropdown: true }
                                            }}
                                            name="milestone_desc"
                                            placeholder="Enter Milestone Description..."
                                            value={this.state.milestone_desc}
                                            onChange={this.handleMileDescChange}
                                          />
                                        </Col>
                                      </FormGroup>
                                      <FormGroup row>
                                        <Col sm={12}>
                                          <input
                                            name="milestone_amount"
                                            type="text"
                                            errorMessage="Invalid name"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            className="form-control"
                                            placeholder="Enter Milestone Amount..."
                                            value={this.state.milestone_amount}
                                            onChange={this.handleMileValueChange}
                                          />
                                        </Col>
                                      </FormGroup>
                                      <br />
                                      <FormGroup row>
                                        <Col lg={12}>
                                          <div className="input-group date-pick">
                                            <Flatpickr
                                              className="form-control d-block"
                                              placeholder="Starting date"
                                              name="milestone_startDate"
                                              className="form-control d-block"
                                              value={this.state.task_startDate}
                                              onChange={this.handleMileStartDateChange}
                                              options={{
                                                altInput: true,
                                                altFormat: "F j, Y",
                                                dateFormat: "Y-m-d"
                                              }}
                                            />
                                            <span className="date-icon"><i class="bx bx-calendar"></i></span>
                                          </div>
                                        </Col>
                                      </FormGroup>
                                    </Form>
                                  </div>
                                  <div className="modal-footer ftr-none">
                                  </div>
                                </Modal>
                                < Modal
                                  isOpen={this.state.task_modal_task}
                                  toggle={this.task_tog_task}
                                  centered={true}
                                >
                                  <div className="modal-header">
                                    <h5 className="modal-title mt-0" id="myModalLabel">
                                      <span class="popup-head"><i class="bx bx-plus"></i> Create a new Task</span>
                                    </h5>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        this.setState({ task_modal_task: false })
                                      }
                                      className="close task-close"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <div className="modal-body">
                                    <Row>
                                      <Col lg={12}>
                                        <div className="mb-3">
                                          <Select
                                            options={this.state.selectTeamMilestoneOptions}
                                            onChange={this.handleTaskMilestoneNameChange}
                                            classNamePrefix="select2-selection"
                                            placeholder=" Milestone Name..."
                                          />
                                        </div>
                                      </Col>
                                      <FormGroup row>
                                        <Col lg={12}>
                                          <div className="mb-3">
                                            <input
                                              name="task_name"
                                              type="text"
                                              errorMessage="Invalid name"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              className="form-control"
                                              placeholder="Enter Task Name..."
                                              value={this.state.task_name}
                                              onChange={this.handleTaskNameChange}
                                            />
                                          </div>
                                        </Col>
                                      </FormGroup>
                                      {/* <FormGroup row>
                                        <Col lg={12}>
                                          <div className="mb-3">
                                            <Select
                                              onChange={this.handleTaskTypeSelectGroup}
                                              options={option_task_type}
                                              classNamePrefix="select2-selection"
                                              placeholder="Select Tasktype..."
                                            />
                                          </div>
                                        </Col>
                                      </FormGroup>
                                      <FormGroup row>
                                        <Col lg={12}>
                                          <div className="mb-3">
                                            <input
                                              name="task_parent"
                                              type="text"
                                              errorMessage="Invalid name"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              className="form-control"
                                              placeholder="Add Task Parent Name..."
                                              value={this.state.task_parent}
                                              onChange={this.handleTaskNameChange}
                                            />
                                          </div>
                                        </Col>
                                      </FormGroup> */}

                                    </Row>
                                    <FormGroup row>
                                      <Col sm={12}>
                                        <Editor
                                          placeholder="Write task description"
                                          toolbarClassName="toolbarClassName"
                                          wrapperClassName="wrapperClassName"
                                          editorClassName="editorClassName"
                                          toolbar={{
                                            options: ['inline', 'history', "emoji"],
                                            inline: { inDropdown: false },
                                            history: { inDropdown: true },
                                            emoji: { inDropdown: true }
                                          }}
                                          name='task_desc'
                                          value={this.state.task_desc}
                                          onChange={this.handleTeamDescChange}
                                        />
                                      </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                      <Col lg={12}>
                                        <div className="mb-3">

                                        </div>
                                      </Col>
                                    </FormGroup>

                                    <Row className="row-mt">
                                      <Col lg={6}>
                                        <input
                                          name="selectedFiles"
                                          type="file"
                                          errorMessage="Invalid To File"

                                          className="form-control"
                                          onChange={this.onFileChange}
                                          placeholder='Attach Files'
                                        />
                                      </Col>
                                      <Col lg={6}>
                                        <Select
                                          options={this.state.selectTaskMemberNameOptions}
                                          onChange={this.handleTaskMemberChange}
                                          isMulti
                                          classNamePrefix="select2-selection"
                                          placeholder='Assign people to this task'
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg={4}>
                                        <div className="input-group date-pick">
                                          <Flatpickr
                                            className="form-control d-block"
                                            placeholder="Starting date"
                                            name="project_startDate"
                                            className="form-control d-block"
                                            value={this.state.task_startDate}
                                            onChange={this.handleTaskStartDateChange}
                                            options={{
                                              altInput: true,
                                              altFormat: "F j, Y",
                                              dateFormat: "Y-m-d"
                                            }}

                                          />
                                          <span className="date-icon"><i class="bx bx-calendar"></i></span>
                                        </div>
                                      </Col>
                                      <Col lg={4}>
                                        <div className="input-group date-pick">
                                          <Flatpickr
                                            name="task_duedate"
                                            className="form-control d-block"
                                            placeholder="Due date"
                                            value={this.state.task_duedate}
                                            onChange={this.handleTaskDueDateChange}
                                            options={{
                                              altInput: true,
                                              altFormat: "F j, Y",
                                              dateFormat: "Y-m-d"
                                            }}

                                          />
                                          <span className="date-icon"><i class="bx bx-calendar"></i></span>
                                        </div>
                                      </Col>

                                      <Col lg={4}>
                                        <Select
                                          onChange={this.handleTaskSelectGroup}
                                          options={option_project_priority_group}
                                          classNamePrefix="select2-selection"
                                          placeholder='Select a priority'
                                        />
                                      </Col>
                                      <FormGroup row>
                                        {/* <Col lg={12}>
                              <input
                                    name="task_progress"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Progress Value..."
                                    value={this.state.task_progress}
                                    onChange={this.handleTaskNameChange}
                                  />
                            </Col> */}
                                      </FormGroup>
                                    </Row>
                                  </div>
                                  <div className="modal-footer">
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      onClick={this.taskFormSubmit}
                                    >
                                      Done
                                    </button>
                                  </div>
                                </Modal >



                                {/* 
                                <Collapse isOpen={this.state.col1} className="accordion-collapse">
                                  <div className="accordion-body">
                                    <div className="text-muted">
                                      <ContactList />
                                    </div>
                                  </div>
                                </Collapse> */}

                              </div>

                              {/* <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwo">
                                  <button className="accordion-button fw-medium collapsed" type="button" onClick={this.t_col2} style={{ cursor: "pointer" }}>
                                    Project of Team B <span className="accordion-sub"><span> <i class="bx bx-plus"></i>Projects</span> <span> <i class="bx bx-plus"></i>Tasks</span><span> <i class="bx bx-plus"></i>Milestone</span></span>
                                  </button>
                                </h2>

                                <Collapse isOpen={this.state.col2} className="accordion-collapse">
                                  <div className="accordion-body">
                                    <div className="text-muted">
                                      <ContactList />
                                    </div>
                                  </div>
                                </Collapse>
                              </div> */}
                              {/* <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThree">
                                  <button className="accordion-button fw-medium collapsed" type="button" onClick={this.t_col3} style={{ cursor: "pointer" }}>
                                    Project of Team C <span className="accordion-sub"><span> <i class="bx bx-plus"></i>Projects</span> <span> <i class="bx bx-plus"></i>Tasks</span><span> <i class="bx bx-plus"></i>Milestone</span></span>
                                  </button>
                                </h2>
                                <Collapse isOpen={this.state.col3} className="accordion-collapse">
                                  <div className="accordion-body">
                                    <div className="text-muted">
                                      <ContactList />
                                    </div>
                                  </div>
                                </Collapse>
                              </div> */}
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="2">

                        <Row>
                          <Col lg={12}>


                            <div className="accordion" id="accordion">
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                  <button className="accordion-button fw-medium" type="button" onClick={this.t_col11} style={{ cursor: "pointer" }}>
                                    Project of Team A
                                  </button>
                                </h2>


                                <Collapse isOpen={this.state.col11} className="accordion-collapse">
                                  <div className="accordion-body">
                                    <div className="text-muted">
                                      {/* <ContactGrid /> */}
                                    </div>
                                  </div>
                                </Collapse>
                              </div>
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwo">
                                  <button className="accordion-button fw-medium collapsed" type="button" onClick={this.t_col22} style={{ cursor: "pointer" }}>
                                    Project of Team B
                                  </button>
                                </h2>

                                <Collapse isOpen={this.state.col22} className="accordion-collapse">
                                  <div className="accordion-body">
                                    <div className="text-muted">
                                      Team B
                                    </div>
                                  </div>
                                </Collapse>
                              </div>
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThree">
                                  <button className="accordion-button fw-medium collapsed" type="button" onClick={this.t_col33} style={{ cursor: "pointer" }}>
                                    Project of Team C
                                  </button>
                                </h2>
                                <Collapse isOpen={this.state.col33} className="accordion-collapse">
                                  <div className="accordion-body">
                                    <div className="text-muted">
                                      Team C
                                    </div>
                                  </div>
                                </Collapse>
                              </div>
                            </div>
                          </Col>
                        </Row>

                      </TabPane>
                      <TabPane tabId="3" className="timeline-sect" >
                        <Row>
                          <Col lg={12}>
                            <TimeLineComponent project_id={this.props.match.params.id}

                            />
                          </Col>
                        </Row>

                      </TabPane>
                      <TabPane tabId="4">

                        <Row>
                          <Col lg={12}>

                          </Col>
                        </Row>

                      </TabPane>
                      <TabPane tabId="5" className="calendar-tab-pane">

                        <Row>
                          <Col lg={12}>
                            <MyEventCalendar urll_id={this.props.match.params.id}
                              id_data={this.state.get_proj.map((item) => (

                                <h1> {item['Project Name']}</h1>
                              ))} />
                          </Col>
                        </Row>

                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>


            </Row>


            <Row>
              <Card>
                <CardBody>
                  <Row>


                  </Row>
                </CardBody>
              </Card>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
Chat.propTypes = {
  chats: PropTypes.array,
  groups: PropTypes.array,
  contacts: PropTypes.array,
  messages: PropTypes.array,
  onGetChats: PropTypes.func,
  onGetGroups: PropTypes.func,
  onGetContacts: PropTypes.func,
  onGetMessages: PropTypes.func,
  onAddMessage: PropTypes.func,
}

const mapStateToProps = ({ chat }) => ({
  chats: chat.chats,
  groups: chat.groups,
  contacts: chat.contacts,
  messages: chat.messages,
})

const mapDispatchToProps = dispatch => ({
  onGetChats: () => dispatch(getChats()),
  onGetGroups: () => dispatch(getGroups()),
  onGetContacts: () => dispatch(getContacts()),
  onGetMessages: roomId => dispatch(getMessages(roomId)),
  onAddMessage: roomId => dispatch(addMessage(roomId)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Projecttask)

