import React, { Component } from "react"
import PropTypes from "prop-types"
import Select from "react-select"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { Link, Redirect, withRouter } from "react-router-dom"
//Lightbox
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import ModalVideo from "react-modal-video"
import "react-modal-video/scss/modal-video.scss"

import { SketchPicker } from "react-color"
import ColorPicker from "@vtaits/react-color-picker"
import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
import Switch from "react-switch"
import makeAnimated from "react-select/animated"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardText,
    Progress,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Collapse,
    Container,
    FormGroup,
    Nav,
    NavItem,
    NavLink,
    Input,
    TabContent,
    TabPane,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Form
} from "reactstrap"
import axios_config from "../../helpers/axios_config"
const option_project_priority_group = [
    {
        options: [
            { label: "Low", value: 0 },
            { label: "Medium", value: 1 },
            { label: "High", value: 2 }
        ]
    },

]

const option_task_type = [
    {
        options: [
            { label: "Parent", value: 0 },
            { label: "Subtask", value: 1 },

        ]
    },

]

export default class ManageTaskSchedule extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // Create Task
            modal_task: false,
            task_milestone_name: "",
            task_name: "",
            task_type: "",
            task_parent: "",
            task_progress: '',
            task_desc: "",
            task_priority: "",
            task_members: [],
            task_duedate: "",
            task_startDate: "",
            selectedFiles: null,
            projects: [],
            image: null,
            selectTeamMilestoneOptions:'',

            //Schedule
            schedule_standard: false,
            event_project_name:this.props.projectid,
            event_name:'',
            event_desc:'',
            event_time:'',
            event_date:'',
            event_members_value:[],
            get_today_event:'',
            url_id:'',
            event_startDate:'',
            event_endDate:'',
            event_startTime:'',
            event_endTime:'',
            selectTaskMemberNameOptions:'',

            //Get Mile By ID
            mile_id: this.props.projectid,
        }

        //Task Handling
        this.tog_task = this.tog_task.bind(this)
        this.handleTaskMilestoneNameChange = this.handleTaskMilestoneNameChange.bind(this)
        this.handleTaskMemberChange = this.handleTaskMemberChange.bind(this);
        this.onFileChange = this.onFileChange.bind(this)
        this.handleTeamDescChange = this.handleTeamDescChange.bind(this);
        this.handleTaskStartDateChange = this.handleTaskStartDateChange.bind(this);
        this.handleTaskDueDateChange = this.handleTaskDueDateChange.bind(this);
        this.handleTaskSelectGroup = this.handleTaskSelectGroup.bind(this)
        this.handleTaskNameChange = this.handleTaskNameChange.bind(this)
        this.taskFormSubmit = this.taskFormSubmit.bind(this)
        this.handleTaskTypeSelectGroup = this.handleTaskTypeSelectGroup.bind(this)

        //Schedule Handling
        this.schedule_tog_standard = this.schedule_tog_standard.bind(this)
        this.handleValueChange = this.handleValueChange.bind(this)
        this.handleValidEventSubmit = this.handleValidEventSubmit.bind(this)
        this.handleEventDescChange = this.handleEventDescChange.bind(this);
        this.handleEventProjectNameChange = this.handleEventProjectNameChange.bind(this)
        this.handleEventStartDateChange = this.handleEventStartDateChange.bind(this)
        this.handleEventTimeStartChange = this.handleEventTimeStartChange.bind(this)
        this.handleEventDateEndChange = this.handleEventDateEndChange.bind(this)
        this.handleEventTimeEndChange = this.handleEventTimeEndChange.bind(this)
        this.handleEventMemberChange = this.handleEventMemberChange.bind(this);

    }
    //Start Task Model
    tog_task() {
        this.setState(prevState => ({
            modal_task: !prevState.modal_task,
        }))

    }
    onFileChange = event => {

        this.setState({ selectedFiles: event.target.files[0] });
    
      };
    
      handleTeamDescChange = (event) => {
        this.setState({ task_desc: event.blocks[0].text })
    
      };
    
      handleTaskStartDateChange = (event) => {
        this.setState({ task_startDate: event[0] })
    
      };
    
      handleTaskDueDateChange = (event) => {
        this.setState({ task_duedate: event[0] })
    
      };
    
      handleTaskSelectGroup = priority_sel_event => {
        this.setState({ task_priority: priority_sel_event.value })
      }
      handleTaskNameChange(event) {
    
        this.setState({ [event.target.name]: event.target.value })
      }
    
      handleTaskTypeSelectGroup = task_type_event => {
        this.setState({ task_type: task_type_event.value })
      }
      handleTaskMilestoneNameChange(e) {
        this.setState({ task_milestone_name: e.value })
    
      }
      handleTaskMemberChange(event) {
        this.setState({ task_members: event })
    
      }
    async getMilestoneOptions() {
      const{mile_id}= this.state
      let project_id = mile_id
      console.log("url_project_id ", mile_id)
        const res = await axios_config.post('/all-promile/',{project_id:project_id})
        
        const data = res.data.data

        const options = data ? data.map(item => ({
            "value": item["milestone_id"],
            "label": item["name"]
        })) : "No Option";
        console.log("milestone_name_options", options);
        this.setState({ selectTeamMilestoneOptions: options })
    }
    async getTaskMembersOptions() {
    
        const response = await axios_config.post('/all-users/', { usertype: 3 })
        const data = response.data.data
        const options = data.map(d => ({
          "value": d["user id"],
          "label": d["user firstname"]
        }))
        this.setState({ selectTaskMemberNameOptions: options })
      }
      taskFormSubmit(event) {

        event.preventDefault();
        this.setState({
          modal_task:false,
          task_milestone_name:"",
          task_name:"",
          task_type:"",
          task_parent:"",
          task_desc:"",
          task_progress:"",
          task_priority:"",
          task_members:"",
          task_startDate:"",
          task_duedate:"",
          selectedFiles:"",
        })
    
        const { task_milestone_name,  task_name, task_type, task_parent, task_desc,task_progress, task_priority, task_members, task_startDate,
          task_duedate, selectedFiles } = this.state;
    
        function convert(str) {
          var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
          return [date.getFullYear(), mnth, day,].join("-");
        }
    
        let start_date = convert(task_startDate)
        let due_date = convert(task_duedate)
        let update_metakey = selectedFiles?.name
        let metavalue = selectedFiles?.type
    
        var arr_members_value = [];
        this.state.task_members.map((postData) => {
          arr_members_value.push(postData.value)
        })
    
        console.log(task_milestone_name)
        console.log(task_name)
        console.log(0)
        console.log(0)
        console.log(0)
        console.log(task_desc)
        console.log(task_priority)
        console.log(arr_members_value)
        console.log(start_date)
        console.log(due_date)
        console.log(update_metakey)
        console.log(metavalue)
        console.log(selectedFiles)
        // console.log(status)
    
        let bodyFormData = new FormData();
    
        bodyFormData.append("Milestoneid", task_milestone_name);
        bodyFormData.append("task", task_name);
        bodyFormData.append("tasktype", 0);
        bodyFormData.append("taskparent", 0);
        bodyFormData.append("progress", 0);
        bodyFormData.append("taskdescription", task_desc);
        bodyFormData.append("priority", task_priority);
        bodyFormData.append("membersid", arr_members_value);
        bodyFormData.append("metakey", update_metakey);
        bodyFormData.append("metavalue", selectedFiles);
        bodyFormData.append("start_date", start_date);
        bodyFormData.append("due_date", due_date);
        bodyFormData.append("status", 2);
    
        axios_config({
          method: "post",
          url: "/task/",
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        }).then(response => {
          console.log("resp create task", response);
          if (response.data.success == "true") {
            console.log("Add Task Successfully");
            alert("Added Task Successfully")
            this.getAllMilestone()
            this.setState({
              create_task: true,
              loading: false
    
            })
    
          } else {
              var msg = response.data.msg
            alert(msg)
             this.getAllMilestone()
            this.setState({
              create_task: true,
              loading: false
    
            })
          }
        })
          .catch(error => {
            console.log("login error", error);
          });
          this.getAllMilestone()
      }
    //End Task Model

      //Schedule Statrt
  schedule_tog_standard() {
    this.setState(prevState => ({
      schedule_standard: !prevState.schedule_standard,
    }))
  }

  handleEventMemberChange(event) {
    this.setState({ event_members_value: event })

  }

  handleEventStartDateChange = (event) => {
    this.setState({ event_startDate: event[0] })

  };
  handleEventTimeStartChange = (event) => {
    this.setState({ event_startTime: event[0] })

  };
  handleEventDateEndChange = (event) => {
    this.setState({ event_endDate: event[0] })

  };
  handleEventTimeEndChange = (event) => {
    this.setState({ event_endTime: event[0] })

  };

  handleEventDescChange = (event) => {
    this.setState({ event_desc: event.blocks[0].text })

  };

  async getEventMembersOptions() {
    const response = await axios_config.post('/all-users/', { usertype: 3 })
    const data = response.data.data
    const options = data.map(d => ({
      "value": d["user id"],
      "label": d["user firstname"]
    }))
    this.setState({ selectTaskMemberNameOptions: options })
  }

  handleValueChange(event) {

    this.setState({ [event.target.name]: event.target.value })
  }

  async getProjectOptions() {
    // let team_id = id
    const res = await axios_config.post('/all_projects/')
    console.log('res', res)
    const data = res.data.data

    const options = data ? data.map(item => ({
      "value": item["project id"],
      "label": item["Project Name"]
    })) : ''

    this.setState({ selectProjectNameOptions: options })

  }


  handleEventProjectNameChange(e) {
    this.setState({ event_project_name: e.value })

  }
  handleValidEventSubmit = (event) => {
    event.preventDefault();

    this.setState({
      event_project_name: '',
      event_name: '',
      event_desc: '',
      event_members_value: '',
      event_startDate: '',
      event_endDate: '',
      event_startTime: '',
      event_endTime: '',
      schedule_standard: false,
      loading: true
    })

    const { event_project_name, event_name, event_desc, event_members_value, event_startDate, event_endDate, event_startTime, event_endTime } = this.state;

    function convert(str) {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day,].join("-");
    }

    let start_date = convert(event_startDate)
    let end_date = convert(event_endDate)

    let start_time = event_startTime.toString()
    let split_start_time = start_time.split(' ')
    let get_start_time = split_start_time[4]
    // console.log(get_start_time)

    let end_time = event_endTime.toString()
    let split_end_time = end_time.split(' ')
    let get_end_time = split_end_time[4]

    console.log("mememememe")
    console.log(event_members_value)


    // console.log(getFormattedString(event_startTime))


    // let add_time = event_time+':'+'00'
    // console.log(add_time)

    // let date_change = event_date.split("-")
    // var event_up_date_format = date_change[2]+'-'+date_change[1]+'-'+date_change[0]

    var arr_members_value = [];
    this.state.event_members_value.map((postData) => {
      arr_members_value.push(postData.value)
    })

    console.log(event_project_name)
    console.log(event_name)
    console.log(event_desc)
    console.log(arr_members_value)

    // console.log(event_startDate)
    console.log(start_date)
    console.log(get_start_time)
    // console.log(event_endDate)
    console.log(end_date)
    console.log(get_end_time)

    axios_config.post("/event/", {

      projectid: event_project_name,
      event: event_name,
      eventdescription: event_desc,
      eventtime: get_start_time,
      eventendtime: get_end_time,
      eventdate: start_date,
      eventenddate: end_date,
      membersid: arr_members_value,

    }
    ).then(response => {
      console.log("resp create event", response);

      if (response.data.success == "true") {
        console.log("Add Schedule Successfully");
        alert("Add Schedule Successfully")
        this.getAllMilestone();
        this.setState({
          loading: false,
          event_create_in: true
        })

      } else {
        let msg = response.data.msg
        console.log(msg)
        alert(msg)
        this.getAllMilestone();
        this.setState({
          loading: false,
        })
      }
    })
      .catch(error => {
        console.log("login error", error);
      });

  }
  async getAllMilestone() {

    const{mile_id}= this.state
    let project_id = mile_id

    const response = await axios_config.post('all-miletask/', { project_id: project_id })
    const data = response.data.data
    this.setState({ all_mile_task: data })
  }
  //End Schedule Handling
    componentDidMount() {

        //Task Realted
        this.getMilestoneOptions()
        this.getTaskMembersOptions()

        //Schedule Realted
        this.getProjectOptions();
        this.getEventMembersOptions();

        //AllMilestone
        this.getAllMilestone();   

    }


    render() {
        return <div className="shedule-list">
            <Button color="primary" onClick={this.tog_task} data-toggle="modal" data-target="#myModal"><span className="green-text">+</span> Task</Button>{' '}
            <Button color="primary" onClick={this.schedule_tog_standard} data-toggle="modal" data-target="#myModal"> <span className="blue-text">+</span> Schedule</Button>
            <Modal
                isOpen={this.state.modal_task}
                toggle={this.tog_task}
                centered={true}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        <span class="popup-head"><i class="bx bx-plus"></i> Create a new Task</span>
                    </h5>
                    <button
                        type="button"
                        onClick={() =>
                            this.setState({ modal_task: false })
                        }
                        className="close task-close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row>
                        <Col lg={12}>
                            <div className="mb-3">
                                <Select
                                    options={this.state.selectTeamMilestoneOptions}
                                    onChange={this.handleTaskMilestoneNameChange}
                                    classNamePrefix="select2-selection"
                                    placeholder=" Select Milestone"
                                />
                            </div>
                        </Col>
                        <FormGroup row>
                            <Col lg={12}>
                                <div className="mb-3">
                                    <input
                                        name="task_name"
                                        type="text"
                                        errorMessage="Invalid name"
                                        validate={{
                                            required: { value: true },
                                        }}
                                        className="form-control"
                                        placeholder="Enter Task Name..."
                                        value={this.state.task_name}
                                        onChange={this.handleTaskNameChange}
                                    />
                                </div>
                            </Col>
                        </FormGroup>
                        {/* <FormGroup row>
                            <Col lg={12}>
                                <div className="mb-3">
                                    <Select
                                        onChange={this.handleTaskTypeSelectGroup}
                                        options={option_task_type}
                                        classNamePrefix="select2-selection"
                                        placeholder="Select Tasktype..."
                                    />
                                </div>
                            </Col>
                        </FormGroup> */}
                        {/* <FormGroup row>
                            <Col lg={12}>
                                <div className="mb-3">
                                    <input
                                        name="task_parent"
                                        type="text"
                                        errorMessage="Invalid name"
                                        validate={{
                                            required: { value: true },
                                        }}
                                        className="form-control"
                                        placeholder="Add Task Parent Name..."
                                        value={this.state.task_parent}
                                        onChange={this.handleTaskNameChange}
                                    />
                                </div>
                            </Col>
                        </FormGroup> */}

                    </Row>
                    <FormGroup row>
                        <Col sm={12}>
                            <Editor
                                placeholder="Write task description"
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                toolbar={{
                                    options: ['inline', 'history', "emoji"],
                                    inline: { inDropdown: false },
                                    history: { inDropdown: true },
                                    emoji: { inDropdown: true }
                                }}
                                name='task_desc'
                                value={this.state.task_desc}
                                onChange={this.handleTeamDescChange}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col lg={12}>
                            <div className="mb-3">

                            </div>
                        </Col>
                    </FormGroup>

                    <Row className="row-mt">
                        <Col lg={6}>
                            <input
                                name="selectedFiles"
                                type="file"
                                errorMessage="Invalid To File"

                                className="form-control"
                                onChange={this.onFileChange}
                                placeholder='Attach Files'
                            />
                        </Col>
                        <Col lg={6}>
                            <Select
                                options={this.state.selectTaskMemberNameOptions}
                                onChange={this.handleTaskMemberChange}
                                isMulti
                                classNamePrefix="select2-selection"
                                placeholder='Assign people to this project'
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4}>
                            <div className="input-group date-pick">
                                <Flatpickr
                                    className="form-control d-block"
                                    placeholder="Starting date"
                                    name="project_startDate"
                                    className="form-control d-block"
                                    value={this.state.task_startDate}
                                    onChange={this.handleTaskStartDateChange}
                                    options={{
                                        altInput: true,
                                        altFormat: "F j, Y",
                                        dateFormat: "Y-m-d"
                                    }}

                                />
                                <span className="date-icon"><i class="bx bx-calendar"></i></span>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="input-group date-pick">
                                <Flatpickr
                                    name="task_duedate"
                                    className="form-control d-block"
                                    placeholder="Due date"
                                    value={this.state.task_duedate}
                                    onChange={this.handleTaskDueDateChange}
                                    options={{
                                        altInput: true,
                                        altFormat: "F j, Y",
                                        dateFormat: "Y-m-d"
                                    }}

                                />
                                <span className="date-icon"><i class="bx bx-calendar"></i></span>
                            </div>
                        </Col>

                        <Col lg={4}>
                            <Select
                                onChange={this.handleTaskSelectGroup}
                                options={option_project_priority_group}
                                classNamePrefix="select2-selection"
                                placeholder='Select a priority'
                            />
                        </Col>
                        {/* <FormGroup row>
                            <Col lg={12}>
                              <input
                                    name="task_progress"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Progress Value..."
                                    value={this.state.task_progress}
                                    onChange={this.handleTaskNameChange}
                                  />
                            </Col>
                          </FormGroup> */}
                    </Row>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={this.taskFormSubmit}
                    >
                        Done
                    </button>
                </div>
            </Modal>
            <Modal
                                  isOpen={this.state.schedule_standard}
                                  toggle={this.schedule_tog_standard}
                                  centered={true}
                                >
                                  <div className="modal-header">
                                    <h5 className="modal-title mt-0" id="myModalLabel">
                                      <span class="popup-head"><i class="bx bx-plus"></i> Create a new Schedule </span>
                                    </h5>
                                    <button
                                      type="submit"
                                      className="btn btn-primary right-align"
                                      onClick={this.handleValidEventSubmit}
                                    >
                                      Done

                                    </button>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        this.setState({ schedule_standard: false })
                                      }
                                      className="close"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <div className="modal-body">
                                    <Form>
                                      {/* <FormGroup row>
                                        <Col sm={12}>
                                          <Select
                                            options={this.state.selectProjectNameOptions}
                                            onChange={this.handleEventProjectNameChange}
                                            classNamePrefix="select2-selection"
                                            placeholder='Assign to a project'
                                            validate={{
                                              required: { value: true },
                                            }}
                                          />
                                        </Col>
                                      </FormGroup> */}

                                      <FormGroup row>
                                        <Col sm={12}>
                                          <input
                                            name="event_name"
                                            type="text"
                                            errorMessage="Invalid name"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            className="form-control"
                                            placeholder="Enter Event Name..."
                                            value={this.state.event_name}
                                            onChange={this.handleValueChange}

                                          />
                                        </Col>
                                      </FormGroup>
                                      <br />
                                      <FormGroup row>
                                        <Col sm={12}>
                                          <Editor
                                            placeholder="Schedule description"
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            toolbar={{
                                              options: ['inline', 'history', "emoji"],
                                              inline: { inDropdown: false },
                                              history: { inDropdown: true },
                                              emoji: { inDropdown: true }
                                            }}
                                            name='event_desc'
                                            value={this.state.event_desc}
                                            onChange={this.handleEventDescChange}
                                          />
                                        </Col>
                                      </FormGroup>

                                      <br />
                                      <FormGroup row>
                                        <Col sm={12}>
                                          <Select
                                            options={this.state.selectTaskMemberNameOptions}
                                            onChange={this.handleEventMemberChange}
                                            isMulti
                                            classNamePrefix="select2-selection"
                                            placeholder='Invite people to that schedule'
                                          />
                                        </Col>
                                      </FormGroup>
                                      <br />
                                      <FormGroup row>
                                        <Col sm={3}>
                                          <InputGroup>
                                            <Flatpickr
                                              className="form-control d-block"
                                              placeholder="Starting date"
                                              name="event_startDate"
                                              className="form-control d-block"
                                              value={this.state.event_startDate}
                                              onChange={this.handleEventStartDateChange}
                                              options={{
                                                altInput: true,
                                                altFormat: "F j, Y",
                                                dateFormat: "Y-m-d"
                                              }}

                                            />
                                          </InputGroup>
                                        </Col>
                                        <Col sm={3}>
                                          <InputGroup>
                                            <Flatpickr
                                              className="form-control d-block"
                                              placeholder="Starting time"
                                              name="event_startTime"
                                              value={this.state.event_startTime}
                                              onChange={this.handleEventTimeStartChange}
                                              options={{
                                                enableTime: true,
                                                noCalendar: true,
                                                dateFormat: "H:i"
                                              }}
                                            />
                                            <div className="input-group-append">
                                              <span className="input-group-text">
                                                <i className="mdi mdi-clock-outline" />
                                              </span>
                                            </div>
                                          </InputGroup>
                                        </Col>
                                        <Col sm={3}>
                                          <InputGroup>
                                            <Flatpickr
                                              className="form-control d-block"
                                              placeholder="Ending date"
                                              name="event_endDate"
                                              className="form-control d-block"
                                              value={this.state.event_endDate}
                                              onChange={this.handleEventDateEndChange}
                                              options={{
                                                altInput: true,
                                                altFormat: "F j, Y",
                                                dateFormat: "Y-m-d"
                                              }}

                                            />
                                          </InputGroup>
                                        </Col>
                                        <Col sm={3}>
                                          <InputGroup>
                                            <Flatpickr
                                              className="form-control d-block"
                                              placeholder="Ending time"
                                              name="event_endTime"
                                              className="form-control d-block"
                                              value={this.state.event_endTime}
                                              onChange={this.handleEventTimeEndChange}
                                              options={{
                                                enableTime: true,
                                                noCalendar: true,
                                                dateFormat: "H:i"
                                              }}
                                            />
                                            <div className="input-group-append">
                                              <span className="input-group-text">
                                                <i className="mdi mdi-clock-outline" />
                                              </span>
                                            </div>
                                          </InputGroup>
                                        </Col>
                                      </FormGroup>
                                    </Form>

                                  </div>
                                  <div className="modal-footer ftr-none">

                                  </div>
                                </Modal>
        </div>

    }
}