import moment from 'moment';


export const events = [
    {
        title: 'Meeting with client A',
        subTitle: 'Meeting with Client A on Zoom',     
        start: moment().toISOString(),
        end: moment().toISOString(),   
        // start: moment().format("MM/DD/YYYY"),
        // end: moment().format("MM/DD/YYYY")
    },
    
    
    {
        title: 'Meeting with client B',
        subTitle: 'Meeting with Client B on Zoom',        
        start: moment().add("2", 'days').toISOString(),
        end: moment().add("4", 'days').toISOString(),
    }
]